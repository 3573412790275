import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../screens/Login";


export default function LoginRoutes() {
  return (
    <Routes>
      <Route path="" element={ <Login/>}>
      </Route>
    </Routes>
  );
}
