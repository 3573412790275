import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import React, {useContext} from 'react'
import {deleteOpenHours, deleteBannerApi} from '../../apis/api'
import MyContext from '../../context/context'
import {DeleteOutline, EditOutlined, ArrowDropDown} from '@mui/icons-material'
import {ButtonGroup, Button, IconButton} from '@mui/material'
import useToast from '../../hooks/useToast'
import {Check, Close} from '@mui/icons-material'
import FilterBooleanMenu from './FilterBooleanMenu'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import classes from './Style.module.css'

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Studio Name',
    sortable: true,
  },
  {id: 'id', numeric: false, disablePadding: true, label: 'Studio ID'},
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City Name',
    sortable: true,
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: true,
    label: 'Studio manager',
    sortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email address',
  },
  {
    id: 'liveOnApp',
    numeric: false,
    disablePadding: true,
    label: 'Live on app',
    filterBoolean: true,
  },
  {
    id: 'hidden',
    numeric: false,
    disablePadding: true,
    label: 'Hidden',
    filterBoolean: true,
  },
  {
    id: 'schedule',
    numeric: false,
    disablePadding: true,
    label: 'Schedule',
    filterBoolean: true,
  },
  {
    id: 'openHours',
    numeric: false,
    disablePadding: true,
    label: 'Opening hours',
  },
  {
    id: 'scheduleIntegrated',
    numeric: false,
    disablePadding: true,
    label: 'Schedule Integrated',
    filterBoolean: true,
  },
  {
    id: 'churnDate',
    numeric: false,
    disablePadding: true,
    label: 'Churn Date',
  },
  {
    id: 'bannerInfo',
    numeric: false,
    disablePadding: true,
    label: 'Banner info displayed',
  },
  {
    id: 'bannerStartDate',
    numeric: false,
    disablePadding: true,
    label: 'Banner information start date',
  },
  {
    id: 'bannerEndDate',
    numeric: false,
    disablePadding: true,
    label: 'Banner information end date',
  },
  {
    id: 'openingHours',
    numeric: false,
    disablePadding: true,
    label: 'Opening hours info displayed',
  },
  {
    id: 'openingHoursStartDate',
    numeric: false,
    disablePadding: true,
    label: 'Opening hours information start date',
  },
  {
    id: 'openingHoursEndDate',
    numeric: false,
    disablePadding: true,
    label: 'Opening hours information end date',
  },
  {
    id: 'importantNotice',
    numeric: false,
    disablePadding: true,
    label: 'Latest internal note',
  },
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    query,
    setQuery,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  const [liveAnchorEl, setLiveAnchorEl] = React.useState(null)
  const [hiddenAnchorEl, setHiddenAnchorEl] = React.useState(null)
  const [scheduleAnchorEl, setScheduleAnchorEl] = React.useState(null)
  const [
    scheduleIntegratedAnchorEl,
    setScheduleIntegratedAnchorEl,
  ] = React.useState(null)

  const getAnchorEl = id => {
    switch (id) {
      case 'liveOnApp':
        return liveAnchorEl
      case 'hidden':
        return hiddenAnchorEl
      case 'schedule':
        return scheduleAnchorEl
      case 'scheduleIntegrated':
        return scheduleIntegratedAnchorEl
      default:
        return
    }
  }

  const handleSetAnchorElement = (id, value) => {
    switch (id) {
      case 'liveOnApp':
        setLiveAnchorEl(value)
        return
      case 'hidden':
        setHiddenAnchorEl(value)
        return
      case 'schedule':
        setScheduleAnchorEl(value)
        return
      case 'scheduleIntegrated':
        setScheduleIntegratedAnchorEl(value)
        return
      default:
        return
    }
  }

  const handleOpenFilter = (event, id) => {
    handleSetAnchorElement(id, event.currentTarget)
  }

  const handleCloseFilter = (id, value) => {
    handleSetAnchorElement(id, null)
    let searchQuery = {...query, [id]: value}

    setQuery(searchQuery)
    props.getData(1, searchQuery, true)
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="none"
          variant="head"
          size="small"
          className={classes.headCell}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all studios'}}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant="head"
            className={classes.headCell}
          >
            {headCell.filterBoolean ? (
              <div>
                <span>{headCell.label}</span>
                <IconButton
                  size="small"
                  onClick={e => handleOpenFilter(e, headCell.id)}
                >
                  <ArrowDropDown />
                </IconButton>
                <FilterBooleanMenu
                  text={headCell.label}
                  anchorEl={getAnchorEl(headCell.id)}
                  handleSelect={value => handleCloseFilter(headCell.id, value)}
                  handleClose={() => handleSetAnchorElement(headCell.id, null)}
                />
              </div>
            ) : headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function StudioData(props) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const toast = useToast()
  const context = useContext(MyContext)
  const rows = context.studios
  const selected = context.selectedStudios
  const setSelected = context.setSelectedStudios
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(null)

  React.useEffect(() => {
    props.getData(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    let sortQuery = {...props.query, sortBy: property, isAsc}

    props.setQuery(sortQuery)
    props.getData(1, sortQuery)

    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    props.getData(newPage + 1)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const deleteBanner = async () => {
    try {
      const response = await deleteBannerApi(deleteId)
      if (response.data && response.data.data && response.data.data._id) {
        toast('success', `Deleted successfully`)
        setOpenConfirmDelete(false)
        setDeleteId(null)
        props.getData(1)
      }
    } catch {
      toast('warning', `Something went wrong`)
    }
  }

  const openConfirmDeleteBanner = banner => {
    props.setType('tBanner')
    setDeleteId(banner._id)
    setOpenConfirmDelete(true)
  }

  const closeConfirmDeleteBanner = () => {
    setDeleteId(null)
    setOpenConfirmDelete(false)
  }

  const updateBanner = (e, data) => {
    e.stopPropagation()
    props.setOpen(true)
    props.setSelected(data.banner)
    props.setType('tBanner')
  }

  const deleteOpeningHours = async () => {
    try {
      const response = await deleteOpenHours(deleteId)
      if (response.data && response.data.data && response.data.data._id) {
        toast('success', `Deleted successfully`)
        setOpenConfirmDelete(false)
        setDeleteId(null)
        props.getData(1)
      }
    } catch {
      toast('warning', `Something went wrong`)
    }
  }

  const updateOpeningHours = (e, data) => {
    e.stopPropagation()
    props.setOpen(true)
    props.setSelected(data.openingHours)
    props.setType('hBanner')
  }

  const openConfirmDeleteOpenHours = openingHours => {
    props.setType('hBanner')
    setDeleteId(openingHours._id)
    setOpenConfirmDelete(true)
  }

  const closeConfirmDeleteOpenHours = () => {
    setDeleteId(null)
    setOpenConfirmDelete(false)
  }

  const DeleteOrEditBanner = data => {
    return (
      <ButtonGroup variant="text" size="small">
        <Button
          onClick={() => openConfirmDeleteBanner(data.banner)}
          size="small"
        >
          <DeleteOutline fontSize="small" color="error" />
        </Button>
        <Button onClick={e => updateBanner(e, data)} size="small">
          <EditOutlined fontSize="small" color="primary" />
        </Button>
      </ButtonGroup>
    )
  }

  const DeleteOrEditHours = data => {
    return (
      <ButtonGroup variant="text" size="small">
        <Button
          onClick={() => openConfirmDeleteOpenHours(data.openingHours)}
          size="small"
        >
          <DeleteOutline fontSize="small" color="error" />
        </Button>
        <Button onClick={e => updateOpeningHours(e, data)} size="small">
          <EditOutlined fontSize="small" color="primary" />
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableHead}>
          <Button onClick={props.onClearFilter} size="small" variant="text">
            Clear Filters
          </Button>
          <TablePagination
            rowsPerPageOptions={[200]}
            component="div"
            count={props.paginationData.totalDocs}
            rowsPerPage={200}
            page={props.paginationData.page - 1}
            onPageChange={handleChangePage}
          />
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table
            id="studioTable"
            stickyHeader={true}
            className="studiosTable"
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            padding="none"
            variant="body"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              getData={props.getData}
              query={props.query}
              setQuery={props.setQuery}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.name, row.id)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell padding="none" variant="body">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      id={labelId}
                      scope="row"
                      padding="none"
                      variant="body"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.manager}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      <a className='emailLink' href={`mailto:${row.email1}`}>{row.email1}</a>
                      <br />
                      {!!row.email2 && <a className='emailLink' href={`mailto:${row.email2}`}>{row.email2}</a>}
                      <br />
                      {!!row.email3 && <a className='emailLink' href={`mailto:${row.email3}`}>{row.email3}</a>}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.liveOnApp ? (
                        <Check fontSize="small" />
                      ) : (
                        <Close fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.hidden ? (
                        <Check fontSize="small" />
                      ) : (
                        <Close fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.schedule ? (
                        <Check fontSize="small" />
                      ) : (
                        <Close fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.openHours ? (
                        <Check fontSize="small" />
                      ) : (
                        <Close fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.scheduleIntegrated ? (
                        <Check fontSize="small" />
                      ) : (
                        <Close fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.churnDate}
                    </TableCell>
                    <TableCell
                      className="td Banner"
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      <div className={classes.textInfo}>
                        <Tooltip title={row.bannerInfo || ''} placement="left">
                          <span>
                            {row.bannerInfo
                              ? row.bannerInfo.slice(0, 35) + '...'
                              : ''}
                          </span>
                        </Tooltip>
                        {row.bannerInfo && DeleteOrEditBanner(row)}
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.bannerStartDate}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.bannerEndDate}
                    </TableCell>
                    <TableCell
                      className="td OpeningHours"
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      <div className={classes.textInfo}>
                        <Tooltip
                          title={row.openingHoursInfo || ''}
                          placement="left"
                        >
                          <span>
                            {row.openingHoursInfo
                              ? row.openingHoursInfo.slice(0, 35) + '...'
                              : ''}
                          </span>
                        </Tooltip>
                        {row.openingHoursInfo && DeleteOrEditHours(row)}
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.openingHoursStartDate}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.openingHoursEndDate}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      <Tooltip
                        title={row.importantNotice || ''}
                        placement="left"
                      >
                        <span>
                          {row.importantNotice
                            ? row.importantNotice.slice(0, 35) + '...'
                            : ''}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmDeleteDialog
        open={openConfirmDelete}
        handleClose={
          props.type === 'tBanner'
            ? closeConfirmDeleteBanner
            : closeConfirmDeleteOpenHours
        }
        handleConfirm={
          props.type === 'tBanner' ? deleteBanner : deleteOpeningHours
        }
        type={props.type === 'tBanner' ? 'banner' : 'opening hours'}
      />
    </div>
  )
}
