import React from 'react'
import {FormControl, Grid, MenuItem, Select} from '@mui/material'
import KPITable from '../Common/KPITable'
import {getKpisMemberships} from '../../apis/api'
import classes from './Kpi.module.css'


const KpisMemberships = ({reformatData, labelsAndKeys}) => {
  const [data, setData] = React.useState([])
  const [isLoading, setLoading] = React.useState(false)

  const getPaymingDate = async () => {
    setLoading(true)
    const res = await getKpisMemberships({membership: membership.value})
    const formatedData = reformatData(res?.data.data)
    setData(formatedData)
    setLoading(false)
  }
  const [membership, setMembership] = React.useState('unlimited-365')

  React.useEffect(() => {
    getPaymingDate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership])

  const memberships = [
    {key: 'Unlimited 365', value: 'unlimited-365'},
    {key: 'Unlimited 200', value: 'unlimited-200'},
    {key: 'Light', value: 'light'},
    {key: 'Unlimited', value: 'unlimited'},
  ]
  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={4} md={2}>
          Select membership type
        </Grid>
        <Grid item xs={4} md={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.textField}
              onChange={e => setMembership(e.target.value)}
              value={membership}
            >
              {memberships?.map(item => {
                return (
                  <MenuItem key={item.key} value={item.value}>
                    {item.key}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="kpiContainer">
        <KPITable
          headerClassName="monthlyMembersHeader"
          title={memberships.find(m => m.value === membership).key}
          labelsAndKeys={labelsAndKeys}
          data={data}
        />
        {isLoading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  )
}

export default KpisMemberships
