import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Button, Table, Tooltip, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC, useMemo } from 'react';
import { IPaginationMeta } from '../../types/common';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment-timezone';
interface IProps {
  newFeeds: Array<any>;
  onOpenUpdate(newfeed: any): void;
  onDelete(id: number): void;
  isLoading: boolean;
  paginationData: IPaginationMeta;
  onChangePagination(page: number, pageSize: number): void;
}
const ListFeedsTable: FC<IProps> = ({
  newFeeds,
  onOpenUpdate,
  onDelete,
  isLoading,
  paginationData,
  onChangePagination,
}) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (value: string) => (
          <Tooltip title={value}>
            <p className="t-3-d">{value}</p>
          </Tooltip>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (value: string) => (
          <Tooltip title={value}>
            <p className="t-3-d">{value}</p>
          </Tooltip>
        ),
      },
      {
        title: 'Push Notification Text',
        dataIndex: 'text',
        key: 'text',
        render: (value: string) => (
          <Tooltip title={value}>
            <p className="t-3-d">{value}</p>
          </Tooltip>
        ),
      },
      {
        title: 'Media',
        dataIndex: 'media',
        key: 'media',
        render: (value: any) =>
          value ? (
            <a target="_blank" href={value} rel="noreferrer">
              Open Media
            </a>
          ) : null,
      },
      {
        title: 'Url',
        dataIndex: 'someURL',
        key: 'someURL',
        render: (value: any) =>
          value ? (
            <a target="_blank" href={value} rel="noreferrer">
              <Tooltip title={value}>
                <p className="t-3-d">{value}</p>
              </Tooltip>
            </a>
          ) : null,
      },
      {
        title: 'Schedule',
        dataIndex: 'schedule',
        key: 'schedule',
        render: (value: { time: string; sent: boolean }) =>
          value ? (
            <Tooltip
              title={
                <div>
                  <p>{moment(value.time).tz('Europe/Stockholm').format()}</p>
                  {!!value.sent && (
                    <>
                      <CheckIcon color="success"> </CheckIcon> Sent
                    </>
                  )}
                </div>
              }
            >
              <AccessTimeIcon color={value.sent ? 'success' : 'secondary'} />
            </Tooltip>
          ) : null,
      },
      {
        title: 'Class ID',
        dataIndex: 'classOccasion',
        key: 'classOccasion',
        render: (value: any) =>
        value ? (
            <Tooltip title={value}>
              <p className="t-3-d-100">{value}</p>
            </Tooltip>
        ) : null,
      },
      {
        title: 'Studio ID',
        dataIndex: 'studio',
        key: 'studio',
        render: (value: any) =>
        value ? (
            <Tooltip title={value}>
              <p className="t-3-d-100">{value}</p>
            </Tooltip>
        ) : null,
      },
      {
        title: 'City',
        dataIndex: 'location',
        key: 'location',
        render: (_: any, record: any) => <p>{record?.location?.city}</p>,
      },
      {
        title: 'Zip',
        dataIndex: 'zip',
        key: 'zip',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_: string, record: any) =>
          record.type === null ? (
            <div>
              <Button icon={<EditOutlined />} type="text" onClick={() => onOpenUpdate(record)} />
              <Popconfirm
                placement="topLeft"
                title={'Delete this newfeed ?'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete(record._id)}
              >
                <Button icon={<DeleteOutline />} type="text" />
              </Popconfirm>
            </div>
          ) : null,
      },
    ],
    [onDelete, onOpenUpdate],
  );
  return (
    <Table
      columns={columns}
      dataSource={newFeeds}
      size="small"
      rowKey="_id"
      loading={isLoading}
      pagination={{
        current: paginationData.page,
        total: paginationData.totalDocs,
        pageSize: paginationData.limit,
        onChange: onChangePagination,
      }}
    ></Table>
  );
};

export default ListFeedsTable;
