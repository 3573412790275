import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  IconButton,
} from "@mui/material";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { getVisits } from "../../apis/api";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import classes from "./Style.module.css";
import { updateInvoiceByStudioId } from "../../apis/invoice";
import { toast } from "react-toastify";
import InvoiceDetail from "./InvoiceDetail";

const headCells = [
  {
    id: "paymentCategory",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  { id: "studio", numeric: false, disablePadding: true, label: "Studio Name" },
  {
    id: "visits",
    numeric: true,
    disablePadding: true,
    label: "Visits Number",
  },
  {
    id: "grossCompensation",
    numeric: true,
    disablePadding: true,
    label: "Gross compensation",
  },
  {
    id: "netCompensation",
    numeric: true,
    disablePadding: true,
    label: "Net Compensation",
  },
  {
    id: "vat",
    numeric: false,
    disablePadding: true,
    label: "VAT",
  },
  {
    id: "vatp",
    numeric: false,
    disablePadding: true,
    label: "VAT %",
  },
  {
    id: "bankgiro",
    numeric: false,
    disablePadding: true,
    label: "Bankgiro",
  },
  {
    id: "bankAccountNumber",
    numeric: false,
    disablePadding: true,
    label: "Bank Account Number",
  },
  {
    id: "invoice",
    label: "Invoice",
  },
];

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            variant="head"
            size="small"
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const InvoicesTable = React.memo(({ studiosInvoices, openVisits, month, year, setMonth, setYear }) => {
  const [viewStudio, setViewStudio] = useState({});

  let totalVisits = 0;
  let totalGrossCompensation = 0;
  let totalNetCompensation = 0;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];


  const changeMonth = (action) => {
    let newMonth = month;
    let newYear = year;

    if (action === "next") {
      if (month < 12) {
        newMonth = month + 1;
      } else {
        newMonth = 1; // Jan
        newYear = year + 1;
      }
    } else if (action === "previous") {
      if (month > 1) {
        newMonth = month - 1;
      } else {
        newMonth = 12;
        newYear = year - 1;
      }
    }

    setMonth(newMonth);
    setYear(newYear);
  };

  const changeYear = (action) => {
    if (action === "next") {
      setYear(year + 1);

    } else if (action === "previous") {
      setYear(year - 1);
    }
  };

  const getallVisits = async (studio, id) => {
    openVisits(studio);
    await getVisits(1, 20, id);
  };

  const VisitsIcon = (studio, id, value) => {
    return (
      <span
        onClick={() => getallVisits(studio, id)}
        style={{ cursor: "pointer" }}
      >
        <img
          src="/assets/icons/visits.svg"
          alt="visits"
          className={classes.visits}
        />
        {value}
      </span>
    );
  };

  const onUpdateInvoice = async (invoice) => {
    try {
      await updateInvoiceByStudioId(viewStudio.studioId, month, year, invoice)
      toast.success('Update invoice successfully')
    } catch (error) {
      toast.error('Update invoice failed')
    }
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={classes.filters}>
          <div className={classes.monthFilter}>
            <IconButton onClick={() => changeMonth("previous")} size="small">
              <KeyboardArrowLeft />
            </IconButton>
            <span>{months[month - 1]}</span>
            <IconButton onClick={() => changeMonth("next")} size="small">
              <KeyboardArrowRight />
            </IconButton>
          </div>
          <div className={classes.monthFilter}>
            <IconButton onClick={() => changeYear("previous")} size="small">
              <KeyboardArrowLeft />
            </IconButton>
            <span>{year}</span>
            <IconButton onClick={() => changeYear("next")} size="small">
              <KeyboardArrowRight />
            </IconButton>
          </div>
        </div>
        <div style={{ marginLeft: "auto", marginTop: 20 }}>
          Total: {studiosInvoices.length}
        </div>
      </div>

      <Paper className={`${classes.paper} invoices`}>
        <TableContainer className={classes.tableContainer}>
          <Table
            id="table"
            stickyHeader={true}
            className="invoice-table invoicesTable"
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead rowCount={studiosInvoices.length} />
            <TableBody>
              {studiosInvoices.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const netCompensation = row.vatPercent
                ? row.grossPrice -
                  (row.grossPrice * Number(row.vatPercent?.split('%')[0])) / 100
                : ''
                totalVisits += row.visitNumber;
                totalGrossCompensation += row.grossPrice;
                totalNetCompensation += netCompensation
                const vatPrice = roundToTwo(row.grossPrice - netCompensation);
                return (
                  <TableRow
                    hover
                    className={
                      row.invoice &&
                      (row.invoice.isDone
                        ? classes.doneStudio
                        : row.invoice.flaggedStudio
                        ? classes.flaggedStudio
                        : '')
                    }
                    role="checkbox"
                    tabIndex={-1}
                    key={row.studioId}
                    style={{ fontSize: '10px' }}
                  >
                    <TableCell className={classes.tableBodyCell} variant="body">
                      {row.paymentCategory ? row.paymentCategory.toUpperCase() : ''}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: '12px' }}
                      id={labelId}
                      scope="row"
                      padding="none"
                      variant="body"
                    >
                      {row.studioName}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {VisitsIcon(row, row.studioId, row.visitNumber)}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {roundToTwo(row.grossPrice)}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {roundToTwo(netCompensation)}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {vatPrice}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row.vatPercent}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row?.bankgiro}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      {row?.bankAccountNumber}
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyCell}
                      align="right"
                      padding="none"
                      variant="body"
                    >
                      <IconButton onClick={() => setViewStudio(row)}>
                        <FindInPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow className={classes.tableFooter}>
                <TableCell>Total</TableCell>
                <TableCell>{studiosInvoices.length}</TableCell>
                <TableCell>{totalVisits}</TableCell>
                <TableCell>{roundToTwo(totalGrossCompensation)}</TableCell>
                <TableCell>{roundToTwo(totalNetCompensation)}</TableCell>
                <TableCell>{roundToTwo(totalGrossCompensation - totalNetCompensation)}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <InvoiceDetail
        isOpen={!!viewStudio && !!viewStudio?.studioId}
        handleClose={() => setViewStudio({})}
        onUpdateInvoice={onUpdateInvoice}
        month={month}
        studioInvoice={viewStudio}
        year={year}
      />
    </div>
  );
});

export default InvoicesTable;

