import Checkbox from '@mui/material/Checkbox';
import React, { FC, memo, useCallback, useState } from 'react';
import { exportVisits } from '../../apis/api';
import UpdateInputVisits from '../ListInvoices/UpdateInputVisits';
import LoadingButton from '../LoadingButton';
import { exportExcelFormat } from '../../utils/exportExcelFormJson';
import { INVOICE_HEADER_EXCEL } from '../../constants/common';
import {
  getExportInvoiceStudioApi,
  updateAllCompensationForStudioApi,
  updateCheckInCompensationApi,
  updateExcludeCheckInApi,
} from '../../apis/invoice';
import { IInvoiceReport } from '../../types/invoices';
import { IPaginationMeta } from '../../types/common';
import Loading from '../Common/Loading';
import classes from './Style.module.css';
import { toast } from 'react-toastify';
import AddFreeRows from './AddFreeRows';
import { Button, Form, InputNumber, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
interface IProps {
  month: number;
  year: number;
  selected: {
    studioId: string;
    studioName: string;
  };
  openVisits(value: boolean): void;
  onRefetchTable: () => void;
}
const EnhancedTable: FC<IProps> = memo((props) => {
  const { month, year, onRefetchTable } = props;
  const [isExporting, setIsExporting] = React.useState(false);
  const [hasUpdatedVisits, setHasUpdatedVisits] = React.useState(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [paginationData, setPaginationData] = React.useState<IPaginationMeta>({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0,
  });

  const [reports, setReports] = useState<IInvoiceReport[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const getExportInvoiceStudio = useCallback(async () => {
    try {
      setLoading(true);
      if (props.selected.studioId) {
        const data = await getExportInvoiceStudioApi({
          studioId: props.selected.studioId,
          page: paginationData.page,
          month,
          year,
        });

        setReports(data.reports);
        setPaginationData(data.meta);
      }
    } catch (error) {
      toast.error("Couldn't fetch visits!");
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, paginationData.page, props.selected.studioId, year]);

  async function handleExportToExcel() {
    try {
      setIsExporting(true);
      const data = await exportVisits(props.selected.studioId);
      exportExcelFormat(
        INVOICE_HEADER_EXCEL,
        data.data,
        `${(props.selected.studioId || 'invoice').replaceAll(' ', '-')}-${month}-${year}.xlsx`,
      );
    } catch (error) {
      toast.error("Couldn't export visits!");
    } finally {
      setIsExporting(false);
    }
  }

  const updateVisit = useCallback(
    async (updatedValue: string, id: string) => {
      try {
        await updateCheckInCompensationApi(id, Number(updatedValue));
        getExportInvoiceStudio();
        onRefetchTable();
      } catch (error) {
        toast.error("Couldn't update visits!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getExportInvoiceStudio],
  );

  React.useEffect(() => {
    getExportInvoiceStudio();
  }, [getExportInvoiceStudio]);

  const handleExcludeCheckIn = useCallback(
    async (reportId: string, exclude: boolean) => {
      try {
        await updateExcludeCheckInApi(reportId, exclude);
        getExportInvoiceStudio();
        onRefetchTable();
      } catch (error) {
        toast.error("Couldn't update visits!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getExportInvoiceStudio],
  );

  const updateGrossForAll = useCallback(async (data: any) => {
    try {
      await updateAllCompensationForStudioApi(
        Number(data.grossCompensation),
        selectedRowKeys as string[],
      );
      getExportInvoiceStudio();
      onRefetchTable();
    } catch (error) {
      toast.error("Couldn't update visits!");
    }
  }, [getExportInvoiceStudio, onRefetchTable, selectedRowKeys]);

  const columns: ColumnsType<IInvoiceReport> = [
    { dataIndex: 'studioName', key: 'studioName', title: 'Studio Name' },
    {
      dataIndex: 'checkInAtClassName',
      key: 'checkInAtClassName',
      title: 'Class check-in type',
      width: 180,
    },
    { dataIndex: 'emailUserCheckIn', key: 'emailUserCheckIn', title: 'User email' },
    {
      dataIndex: 'excluded',
      key: 'excluded',
      title: 'Excluded ?',
      render: (_, row) => (
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
            handleExcludeCheckIn(row._id, !row.excluded);
          }}
          checked={row.excluded}
        />
      ),
    },
    {
      dataIndex: 'grossCompensation', //If you modify the gross compensation manually, the record will no longer change by payment category !
      key: 'grossCompensation',
      title: 'Gross Compensation',
      render: (_, row) => (
        <UpdateInputVisits
          updateVisit={(v: string) => updateVisit(v, row._id)}
          value={row.grossCompensation}
          hasUpdatedVisits={hasUpdatedVisits}
          setHasUpdatedVisits={setHasUpdatedVisits}
        />
      ),
    },
    { dataIndex: 'netCompensation', key: 'netCompensation', title: 'Net Compensation' },
    { dataIndex: 'vat', key: 'vat', title: 'Vat' },
    { dataIndex: 'vatPercent', key: 'vatPercent', title: 'Vat %' },
    { dataIndex: 'checkInDate', key: 'checkInDate', title: 'Check-in Date' },
    { dataIndex: 'checkedDay', key: 'checkedDay', title: 'Weekday' },
    { dataIndex: 'checkInHour', key: 'checkInHour', title: 'Time' },
    { dataIndex: 'checkInDeviceName', key: 'checkInDeviceName', title: 'Device', width: 180 },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <div>
        <Loading isLoading={isLoading} />
        <div className={classes.firstRow}>
          <LoadingButton
            isLoading={isExporting}
            loadingText="Exporting..."
            onClick={handleExportToExcel}
            className={classes.exportFile}
            variant="contained"
            size="small"
          >
            Export To Excel
          </LoadingButton>
        </div>
      </div>

      <div className={classes.root}>
        <AddFreeRows studioId={props.selected.studioId} month={month} year={year} />
        <div>
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          <div style={{ height: '45vh', width: '60vw' }}>
            <Form onFinish={updateGrossForAll} layout="inline" style={{ marginBottom: 16 }}>
              <Form.Item
                label="Gross Compensation"
                name="grossCompensation"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={!selectedRowKeys.length}>
                  Update all visits selected
                </Button>
              </Form.Item>
            </Form>
            <Table
              columns={columns}
              dataSource={reports}
              rowKey="_id"
              rowSelection={rowSelection}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default EnhancedTable;
