import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useToast from "../../hooks/useToast";
import { IGetOrganicUserMonthChartRes } from "../../types/dashboard";
import { getOrganicUserMonthChartApi } from "../../apis/dashboard";
import { MONTH_OBJ } from "../../constants/common";
import moment from "moment";
import { Paper } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Pie chart for number of users on each access status",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const OrganicUserMonthChart = memo(() => {
  const toast = useToast();
  const [dataChart, setDataChart] = useState<IGetOrganicUserMonthChartRes>([]);
  const getAccessStatusMonthChart = useCallback(
    async () => {
      try {
        const res = await getOrganicUserMonthChartApi();

        setDataChart(res);
      } catch (error) {
        toast("warning", "Error when get data for organic user chart");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    getAccessStatusMonthChart();
  }, [getAccessStatusMonthChart]);

  const lineData = useMemo(() => {
    const monthsToNow = moment().get("month");
    const dataSortByMonth = dataChart.sort((i, j) => i.month - j.month);
    const datasets = [
      {
        label: "total",
        data: dataSortByMonth.map((i) => i.total),
        borderColor: "#FE7661",
        backgroundColor: "#FE7661",
      },
      {
        label: "referred",
        data: dataSortByMonth.map((i) => i.referred),
        borderColor: "#6FDB7F",
        backgroundColor: "#6FDB7F",
      },
      {
        label: "organic",
        data: dataSortByMonth.map((i) => i.organic),
        borderColor: "#F70073",
        backgroundColor: "#F70073",
      },
    ];

    return {
      labels: Object.values(MONTH_OBJ).slice(0, monthsToNow),
      datasets: datasets,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataChart)]);

  return (
    <Paper className="dashboard-pie">
      <h3>Access status user monthly</h3>
      <div className="chart-line">
        <Line options={options} data={lineData as any} />
      </div>
    </Paper>
  );
});
export default OrganicUserMonthChart;
