import { IExternalBooking } from '../types/external';
import api from './api';
import apiV2 from './api-v2';

export const getExternalStatusApi = async (
  page: number,
  system: string,
  limit: number,
  studioId: string,
): Promise<{ data: IExternalBooking[]; total: number }> => {
  const res = await api.get(`/studios/external/status`, {
    params: {
      page: page,
      system: system,
      limit: limit,
      studioId: studioId,
    },
  });
  return { data: res.data.data, total: res.data.total };
};

export const getExternalProviderApi = async (): Promise<string[]> => {
  const res = await api.get('/studios/external/provider');
  return res.data;
};

export const getExternalStudioApi = async (): Promise<any> => {
  const res = await apiV2.get('/external-booking');
  return res.data;
};
