import React from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import classes from '../../screens/Dashboard/Dashboard.module.css'

const StatsCard = ({
  children,
  subheader,
  percent,
  totalNumber,
  totalVisits,
  headerClass,
  lastMonthNumber,
  type,
}) => {

  if (type !== 'NumberOfUsers')
    return (
      <Grid item lg={3} md={4} xs={12}>
        <Card
          className={percent >= 0 ? '' : classes.errorCard}
          style={{minHeight: '100%'}}
        >
          <CardHeader
            className={classes.header}
            subheader={subheader}
          />
          <CardContent className={classes.content}>
            <div style={{marginRight: 'auto'}}>
              <Typography variant="caption">This Month</Typography>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {totalNumber}
              </Typography>
            </div>
            {children}
          </CardContent>

          <CardActions>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '-15px',
              }}
            >
              <Typography variant="caption">Last Month</Typography>
              <Typography variant="caption">{lastMonthNumber}</Typography>
            </div>
          </CardActions>
        </Card>
      </Grid>
    )
  else
    return (
      <Grid item lg={3} md={4} xs={12}>
        <Card
          className={percent >= 0 ? '' : classes.errorCard}
          style={{minHeight: '100%'}}
        >
          <CardHeader
            className={`${classes[headerClass]} ${classes.header}`}
            subheader={subheader}
          />
          <CardContent className={classes.content}>
            {percent && percent >= 0 ? (
              <ArrowUpwardIcon className={classes.icon} />
            ) : (
              <ArrowDownwardIcon className={classes.icon} />
            )}
            <div style={{marginRight: 'auto', marginLeft: 10}}>
              <Typography variant="caption">Since Last Month</Typography>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {percent}%
              </Typography>
            </div>
            {children}
          </CardContent>
        </Card>
      </Grid>
    )
}

export default StatsCard
