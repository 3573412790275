// USER STATUS:
const U_STATUS_PAYING = 'Paying' // 1.Paying (Paying user. user with payment status 1-7)
const U_STATUS_AMBASSADOR = 'Ambassador/Influencer' // 2.Ambassador/Influencer (User who advertise for us. user with payment status 8)
const U_STATUS_FREE = 'Free user' // 3.Free user (non-paying user with app installed. user with payment status 9)
const U_STATUS_SIGN_UP = 'Referred signup' // 4.Referred signup (Invited by friend. user with payment status 9)
const U_STATUS_TRIALS = 'Trials' // 5.Trials (User from a certain campaign. user with payment status 8)

export const USER_STATUS_KEYS = [
  'paying',
  'ambassador',
  'free',
  'referred',
  'trial',
]

export const USER_STATUS = [
  {key: 'paying', value: U_STATUS_PAYING},
  {key: 'ambassador', value: U_STATUS_AMBASSADOR},
  {key: 'free', value: U_STATUS_FREE},
  {key: 'referred', value: U_STATUS_SIGN_UP},
  {key: 'trial', value: U_STATUS_TRIALS},
]
export const USER_STATUS_OBJ = {
  paying: {key: 'paying', value: U_STATUS_PAYING},
  ambassador: {key: 'ambassador', value: U_STATUS_AMBASSADOR},
  free: {key: 'free', value: U_STATUS_FREE},
  referred: {key: 'referred', value: U_STATUS_SIGN_UP},
  trial: {key: 'trial', value: U_STATUS_TRIALS},
}
//Membership Status:

const M_STATUS_ACTIVE = 'Active' // 1. Active (active membership / paying)
const M_STATUS_FREEZED = 'Freezed/Paused' // 2. Freezed/Paused (paused membership / paying)
const M_STATUS_CHURNED = 'Churned/Canceled' // 3. Churned/Canceled (inactive membership / non-paying)
const M_STATUS_SIGN_UP = 'Signup' // 4. Signup (invited by friend or just installed app)

export const MEMBERSHIP_STATUS_KEYS = ['active', 'freeze', 'churned', 'signup']
export const MEMBERSHIP_STATUS = [
  {key: 'active', value: M_STATUS_ACTIVE},
  {key: 'freeze', value: M_STATUS_FREEZED},
  {key: 'churned', value: M_STATUS_CHURNED},
  {key: 'signup', value: M_STATUS_SIGN_UP},
]
export const MEMBERSHIP_STATUS_OBJ = {
  active: {key: 'active', value: M_STATUS_ACTIVE},
  freeze: {key: 'freeze', value: M_STATUS_FREEZED},
  churned: {key: 'churned', value: M_STATUS_CHURNED},
  signup: {key: 'signup', value: M_STATUS_SIGN_UP},
}
// Payment Status:

const P_STATUS_STRIPE = 'Stripe' //1. Stripe (payment with debit/credit card)
const P_STATUS_INVOICE = 'Swiftr invoice' //2. Swiftr invoice (payment with invoice 1st party)
const P_STATUS_THIRD = [
  {
    key: 'benify',
    value: 'Benify (3rd party)',
  },
  {
    key: 'actiway',
    value: 'Actiway (3rd party)',
  },
  {
    key: 'wellnet',
    value: 'Wellnet (3rd party)',
  },
  {
    key: 'soderberg',
    value: 'Söderberg & Partners',
  },
  {
    key: 'epassi',
    value: 'ePassi (3rd party)',
  },
] //3. Benify (payment with invoice 3rd party)
const P_STATUS_CREDIT = 'Giftcard/Credits' // 7. (manually created credits which gives user certain nr of check-ins)
const P_STATUS_NO_SOURCE = 'No payment source' //9. No payment source (no payment method. user status 3-4)
export const PAYMENT_STATUS = [
  {
    key: 'stripe',
    value: P_STATUS_STRIPE,
  },
  {
    key: 'invoice',
    value: P_STATUS_INVOICE,
  },
  {
    key: 'giftcard',
    value: P_STATUS_CREDIT,
  },
  {
    key: 'no',
    value: P_STATUS_NO_SOURCE,
  },
  ...P_STATUS_THIRD,
]

export const PAYMENT_STATUS_OBJ = {
  stripe: {
    key: 'stripe',
    value: P_STATUS_STRIPE,
  },
  invoice: {
    key: 'invoice',
    value: P_STATUS_INVOICE,
  },
  giftcard: {
    key: 'giftcard',
    value: P_STATUS_CREDIT,
  },
  no: {
    key: 'no',
    value: P_STATUS_NO_SOURCE,
  },
  benify: {
    key: 'benify',
    value: 'Benify (3rd party)',
  },
  actiway: {
    key: 'actiway',
    value: 'Actiway (3rd party)',
  },
  wellnet: {
    key: 'wellnet',
    value: 'Wellnet (3rd party)',
  },
  soderberg: {
    key: 'soderberg',
    value: 'Söderberg & Partners',
  },
  epassi: {
    key: 'epassi',
    value: 'ePassi (3rd party)',
  },
}

export const PAYMENT_STATUS_KEYS = [
  'stripe',
  'invoice',
  'giftcard',
  'no',
  'benify',
  'actiway',
  'wellnet',
  'soderberg',
  'epassi',
]
