import { Button, Input, Modal, Popconfirm, Space, Table, Tag, message } from 'antd';
import { ChangeEvent, useState } from 'react';
import {
  IZipValue,
  IZipValueEdit,
  addZipToRegionApi,
  createZipApi,
  deleteZipApi,
  deleteZipGroupApi,
  editZipApi,
  zipApi,
} from '../apis/zip';
import { EditOutlined, DeleteOutlined } from '@mui/icons-material';

const ZipCode = () => {
  const { data, refetch } = zipApi.useGetZipsQuery(undefined);

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEditAdd, setIsOpenEditAdd] = useState(false);
  const [isOpenAddZip, setIsOpenAddZip] = useState('');
  const [selectId, setSelectId] = useState({
    id: '',
    zipCode: 0,
  });

  const [selectEditId, setSelectEditId] = useState<IZipValueEdit>();

  const [newRegion, setNewRegion] = useState('');

  const [newZipCodes, setNewZipCodes] = useState<Array<IZipValue>>([]);

  const [inputValue, setInputValue] = useState<IZipValue>({ from: 0, to: 0 });

  const handleInputConfirm = () => {
    if (inputValue) {
      setNewZipCodes((pre) => [...pre, inputValue]);
    }
    setInputValue({ from: 0, to: 0 });
  };

  const onChangeInputValue = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.valueAsNumber);
    setInputValue((pre: IZipValue) => ({ ...pre, [key]: value || 0 }));
  };

  const onSubmitNew = async () => {
    try {
      await createZipApi({ region: newRegion, zipCodes: newZipCodes });
      message.success('Create zip code successfully');
      refetch();
      setIsOpenAdd(false);
      setNewRegion('');
      setNewZipCodes([]);
    } catch (error) {
      message.error('Create zip code failed');
    }
  };

  const onDeleteZip = async () => {
    try {
      await deleteZipApi({ id: selectId.id, zip: selectId.zipCode });
      message.success('Delete zip code successfully');
      refetch();
      setSelectId({ id: '', zipCode: 0 });
    } catch (error) {
      message.error('Delete zip code failed');
    }
  };

  const onEditZip = async () => {
    try {
      const zipValue: IZipValueEdit = {
        _id: selectEditId?.zipCodeId,
        from: selectEditId?.from || 0,
        to: selectEditId?.to || 0,
      };
      await editZipApi({
        id: selectEditId?._id,
        zip: selectEditId?.zipCodeId,
        zipCodes: [zipValue],
      });
      message.success('Edit zip code successfully');
      setIsOpenEditAdd(false);
      refetch();
    } catch (error) {
      message.error('Edit zip code failed');
    }
  };

  const onSubmitAdd = async () => {
    try {
      await addZipToRegionApi({ id: isOpenAddZip, zip: newZipCodes });
      message.success('Add zip code successfully');
      refetch();
      setIsOpenAddZip('');
      setNewZipCodes([]);
    } catch (error) {
      message.error('Add zip code failed');
    }
  };

  const onDeleteZipGroup = async (id: string) => {
    try {
      await deleteZipGroupApi({ id: id});
      message.success('Delete zip code successfully');
      refetch();
      setSelectId({ id: '', zipCode: 0 });
    } catch (error) {
      message.error('Delete zip code failed');
    }
  }

  return (
    <div>
      <Space style={{ marginBottom: 24 }} direction="vertical">
        <Button onClick={() => setIsOpenAdd(true)}>Add zip code region</Button>
      </Space>

      <Table
        dataSource={data?.data || []}
        rowKey="_id"
        columns={[
          {
            title: 'City/ Region',
            width: 180,
            dataIndex: 'region',
            key: 'region',
          },
          {
            title: 'ZipCodes',
            dataIndex: 'zipCodes',
            key: 'zipCodes',
            render: (zipCodes: IZipValue[], row) => (
              <div>
                {zipCodes &&
                  zipCodes.map((zip) => (
                    <Popconfirm
                      key={zip._id}
                      open={selectId.id === row._id && selectId.zipCode === zip.from}
                      title="Are you sure to delete this zip code?"
                      onCancel={() => setSelectId({ id: '', zipCode: 0 })}
                      onConfirm={onDeleteZip}
                    >
                      <Tag
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          setSelectId({
                            id: row._id,
                            zipCode: zip.from,
                          });
                        }}
                      >
                        {zip.from} - {zip.to}
                        <Button
                          onClick={() => {
                            setIsOpenEditAdd(true);
                            setSelectEditId({ ...zip, _id: row._id, zipCodeId: zip._id });
                          }}
                          style={{ background: 'none', border: 'none', boxShadow: 'none' }}
                          icon={
                            <EditOutlined
                              style={{ fontSize: '20px', paddingTop: '4px', marginLeft: '10px' }}
                            />
                          }
                        />
                      </Tag>
                    </Popconfirm>
                  ))}
              </div>
            ),
          },
          {
            width: 300,
            title: 'Action',
            render: (_, row) => (
              <Space direction="horizontal">
                <Button onClick={() => setIsOpenAddZip(row._id)}>Add zip</Button>
                <Popconfirm title="Are you sure to delete this zip group?" onConfirm={() => onDeleteZipGroup(row._id)}>
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#af1212',
                      boxShadow: 'none',
                    }}
                  />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />

      <Modal
        title="Create new region zip code"
        open={isOpenAdd}
        onCancel={() => {
          setIsOpenAdd(false);
          setNewRegion('');
          setNewZipCodes([]);
        }}
        onOk={onSubmitNew}
        okButtonProps={{
          disabled: !newRegion || !newZipCodes.length,
        }}
      >
        <Input
          value={newRegion}
          placeholder="Region name"
          title="Region name"
          onChange={(e) => setNewRegion(e.target.value)}
        />
        <Space direction="vertical" style={{ marginTop: 24 }}>
          {newZipCodes.map((tag) => (
            <Tag closable onClose={() => setNewZipCodes((pre) => pre.filter((i) => i !== tag))}>
              {tag.from} - {tag.to}
            </Tag>
          ))}
          <Space direction="horizontal">
            <Input
              type="number"
              placeholder="From"
              value={inputValue?.from}
              onChange={(e) => onChangeInputValue('from', e)}
            />
            <Input
              type="number"
              placeholder="To"
              value={inputValue?.to}
              onChange={(e) => onChangeInputValue('to', e)}
            />
            <Button onClick={handleInputConfirm}>Add zip code</Button>
          </Space>
        </Space>
      </Modal>

      <Modal
        title="Edit zip code region"
        open={isOpenEditAdd}
        onCancel={() => {
          setIsOpenEditAdd(false);
        }}
        onOk={onEditZip}
      >
        <Space direction="vertical" style={{ marginTop: 24 }}>
          <Space direction="horizontal">
            <Input
              type="number"
              placeholder="From"
              value={selectEditId?.from || 0}
              onChange={(e) =>
                setSelectEditId((prevEditId: any) => ({
                  ...prevEditId,
                  from: Number(e.target.value),
                }))
              }
            />
            <Input
              type="number"
              placeholder="To"
              value={selectEditId?.to || 0}
              onChange={(e) =>
                setSelectEditId((prevEditId: any) => ({
                  ...prevEditId,
                  to: Number(e.target.value),
                }))
              }
            />
          </Space>
        </Space>
      </Modal>

      <Modal
        title="Add zip code to region"
        open={!!isOpenAddZip}
        onCancel={() => {
          setIsOpenAddZip('');
          setNewZipCodes([]);
        }}
        onOk={onSubmitAdd}
        okButtonProps={{
          disabled: !newZipCodes.length,
        }}
      >
        <Space direction="vertical" style={{ marginTop: 24 }}>
          {newZipCodes.map((tag) => (
            <Tag
              key={tag._id}
              closable
              onClose={() => setNewZipCodes((pre) => pre.filter((i) => i !== tag))}
            >
              {tag.from} - {tag.to}
            </Tag>
          ))}
          <Space direction="horizontal">
            <Input
              type="number"
              placeholder="From"
              value={inputValue.from}
              onChange={(e) => onChangeInputValue('from', e)}
            />
            <Input
              type="number"
              placeholder="To"
              value={inputValue.to}
              onChange={(e) => onChangeInputValue('to', e)}
            />
            <Button onClick={handleInputConfirm}>Add zip code</Button>
          </Space>
        </Space>
      </Modal>
    </div>
  );
};

export default ZipCode;
