import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createBannerApi,
  getAllBannerApi,
  deleteBannerApi,
  editBannerApi,
} from '../apis/banner.api';
import { toast } from 'react-toastify';
import { IBanners } from '../types/banner';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import CustomizedDialog from '../components/Common/CustomizedDialog';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';

const Banners = () => {
  const [banners, setBanners] = useState<IBanners[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [isOpenAdd, setIsOpenAdd] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [editId, setEditId] = useState('');

  const getAllBanners = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getAllBannerApi();
      setBanners(data);
    } catch (error) {
      toast.error('Fetch banners is failed');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllBanners();
  }, [getAllBanners]);

  
  useEffect(() => {
    if(isOpenAdd===false){
      setEditId('')
    }
  }, [isOpenAdd]);

  const onSuccess = () => {
    setIsOpenAdd(false);
    form.resetFields();
  };
  const openEdit = useCallback(
    (data: IBanners) => {
      setEditId(data._id);
      const formData = {
        ...data,
        rangePicker: [moment(data.startDate), moment(data.endDate)],
      };

      form.setFieldsValue(formData);
      setIsOpenAdd(true);
    },
    [form],
  );
  const onCreate = async (data: IBanners & { rangePicker: string[] }) => {
    setIsSaveLoading(true);
    const { rangePicker, ...body } = data;
    const startDate = moment(rangePicker[0]).toISOString();
    const endDate = moment(rangePicker[1]).toISOString();

    try {
      const req = {
        ...body,
        startDate,
        endDate,
      };
      if (!editId) {
        await createBannerApi(req);
        toast.success('Create banner successfully!');
      } else {
        await editBannerApi(editId, req);
        toast.success('Edit banner successfully!');
      }
      getAllBanners();
      onSuccess();
    } catch (error) {
      const message = (error as any).response?.data?.message;
      toast.error(message || (!editId ? 'Create campaign is failed' : 'Edit campaign is failed'));
    } finally {
      setIsSaveLoading(false);
    }
  };
  const onDelete = useCallback(
    async (id: string) => {
      try {
        await deleteBannerApi(id);
        toast.success(`Delete successfully`);
        getAllBanners();
      } catch (error) {
        toast.error(`Cannot delete`);
      }
    },
    [getAllBanners],
  );
  const columns: ColumnsType<IBanners> = useMemo(
    () => [
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (value: string) => value,
      },
      {
        title: 'BgColor',
        dataIndex: 'bgColor',
        key: 'bgColor',
        render: (value) => (
          <div style={{ display: 'flex' }}>
            {value}{' '}
            <div
              style={{
                backgroundColor: value,
                color: 'white',
                height: 20,
                width: 20,
                marginLeft: '5px',
                border: '1px solid #ccc',
              }}
            ></div>
          </div>
        ),
      },
      {
        title: 'TextColor',
        dataIndex: 'textColor',
        key: 'textColor',
        render: (value) => (
          <div style={{ display: 'flex' }}>
            {value}{' '}
            <div
              style={{
                backgroundColor: value,
                color: 'white',
                height: 20,
                width: 20,
                marginLeft: '5px',
                border: '1px solid #ccc',
              }}
            ></div>
          </div>
        ),
      },
      {
        title: 'StartDate',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (value) => <span>{moment(value).format('lll')}</span>,
      },
      {
        title: 'EndDate',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (value) => <span>{moment(value).format('lll')}</span>,
      },
      {
        title: 'Action',
        render: (_, record) => (
          <div>
            <Button
              type="primary"
              style={{ background: 'none', border: 'none', color: '#76ade0', boxShadow: 'none' }}
              disabled={moment(record.endDate).isBefore(moment())}
              icon={<EditOutlined />}
              onClick={() => openEdit(record)}
            />
            <Popconfirm
              placement="topLeft"
              title={'Delete this campaign ?'}
              okText="Yes"
              cancelText="No"
              onConfirm={() => onDelete(record._id)}
              disabled={moment(record.endDate).isBefore(moment())}
            >
              <Button
                disabled={moment(record.endDate).isBefore(moment())}
                type="primary"
                danger
                icon={<DeleteOutlined />}
                style={{ background: 'none', border: 'none', color: '#af1212', boxShadow: 'none' }}
              />
            </Popconfirm>
          </div>
        ),
      },
    ],
    [onDelete, openEdit],
  );

  return (
    <div>
      <Button type="primary" onClick={() => setIsOpenAdd(true)}>
        Add banner
      </Button>

      <Table
        dataSource={banners}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
        pagination={false}
      />

      <CustomizedDialog
        handleClose={onSuccess}
        open={isOpenAdd}
        title={!editId ? 'Create banner' : 'Edit Banner'}
        showFooter={true}
        size="md"
        handleSave={() => form.submit()}
        saveTitle={!editId ? 'Create' : 'Edit'}
        disableSave={isSaveLoading}
      >
        <Form layout="vertical" style={{ width: '35vw' }} onFinish={onCreate} form={form}>
          <Form.Item
            label="Content"
            rules={[{ required: true, message: 'Content is required !' }]}
            name="content"
          >
            <TextArea placeholder="Campaign Name" />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Start Time -> End Time"
                name="rangePicker"
                rules={[
                  {
                    required: true,
                    message: 'Start time and end time is required !',
                    type: 'array' as const,
                  },
                ]}
              >
                <DatePicker.RangePicker
                  showTime
                  showSecond={false}
                  disabledDate={(date) => date.isBefore(moment().subtract(1, 'day'))}
                  popupStyle={{ zIndex: 1301 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item
                label="Background Color"
                rules={[{ required: true, message: 'Background Color is required !' }]}
                name="bgColor"
                initialValue={'#000000'}
              >
                <Input type="color" className="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Text Color"
                rules={[{ required: true, message: 'Text Color is required !' }]}
                name="textColor"
                initialValue={'#ffffff'}
              >
                <Input type="color" className="" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomizedDialog>
    </div>
  );
};

export default Banners;
