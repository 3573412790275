import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import useToast from '../../hooks/useToast'
import {IGetAccessStatusMonthChartRes} from '../../types/dashboard'
import {getAccessStatusMonthChartApi} from '../../apis/dashboard'
import {MONTH_OBJ} from '../../constants/common'
import moment from 'moment'
import {Paper} from '@mui/material'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'Pie chart for number of users on each access status',
    },
  },
}

const AccessStatusMonthChart = memo(() => {
  const toast = useToast()
  const [dataChart, setDataChart] = useState<IGetAccessStatusMonthChartRes>([])
  const getAccessStatusMonthChart = useCallback(
    async () => {
      try {
        const res = await getAccessStatusMonthChartApi()

        setDataChart(res)
      } catch (error) {
        toast('warning', 'Error when get data for organic user chart')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(() => {
    getAccessStatusMonthChart()
  }, [getAccessStatusMonthChart])

  const lineData = useMemo(() => {
    const monthsToNow = moment().get('month')
    const datasets =
      dataChart.map(i => {
        const color = '#' + Math.random().toString(16).slice(-6)
        return {
          label: i.accessStatus || 'other',
          data: Object.keys(MONTH_OBJ)
            .slice(0, monthsToNow)
            .map(month => {
              const count = i.month.find(j => String(j.month) === month)
              return count ? count.count : 0
            }),
          borderColor: color,
          backgroundColor: color,
        }
      }) || []
    return {
      labels: Object.values(MONTH_OBJ).slice(0, monthsToNow),
      datasets: datasets,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataChart)])

  return (
    <Paper className="dashboard-pie">
      <h3>Access status user monthly</h3>
      <div className='chart-line'>
      <Line options={options} data={lineData as any} />
      </div>
    </Paper>
  )
})
export default AccessStatusMonthChart
