import { createApi } from '@reduxjs/toolkit/query/react';
import apiV2, { axiosBaseQuery } from './api-v2';

export interface IZipValue {
  from: number;
  to: number;
  _id?: string;
}

export interface IZipValueEdit {
  from: number;
  to: number;
  _id?: string;
  zipCodeId?: string | undefined;
}
export interface IZip {
  _id: string;
  region: string;
  zipCodes: IZipValue[];
}

export const zipApi = createApi({
  reducerPath: 'getOwnListApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/zip' }),
  endpoints: (builder) => ({
    getZips: builder.query<{data: IZip[]}, undefined>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),
    getZipZones: builder.query<{data: string[]}, undefined>({
      query: () => ({
        url: '/zones',
        method: 'GET',
      }),
    }),
  }),
});

export const createZipApi = async (data: Omit<IZip, '_id'>) => {
  return await apiV2.post(`/zip`, data);
};


export const deleteZipApi = async (data:{id: string, zip: number}) => {
  return await apiV2.delete(`/zip/${data.id}/${data.zip}`);
};

export const deleteZipGroupApi = async (data:{id: string}) => {
  return await apiV2.delete(`/zip/${data.id}`);
};

export const editZipApi = async (data:{id: string | undefined, zip: string| undefined, zipCodes: IZipValue[]}) => {
  const { id, zip, zipCodes } = data;
  return await apiV2.put(`/zip/edit/${id}/${zip}`, { zipCodes })
}


export const addZipToRegionApi = async (data:{id: string, zip: IZipValue[]}) => {
  return await apiV2.put(`/zip/${data.id}`, {zipCodes: data.zip});
};
