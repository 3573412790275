import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import CustomizedDialog from "../Common/CustomizedDialog";
import styled from "styled-components";
import {
  IInvoiceDescription,
  IStudioInvoice,
} from "../../services/invoice/type";
import { Box } from "@mui/system";
import {
  Chip,
  FormControl,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { getInvoiceByStudioId } from "../../apis/invoice";
import { previewInvoice, publishSingleInvoice } from "../../apis/api";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import AddFreeRows from "./AddFreeRows";
import { roundToTwoDecimalPlaces, toFixedTwo } from "../../utils/common";
interface IProps {
  isOpen: boolean;
  handleClose(): void;
  studioInvoice: IStudioInvoice;
  onUpdateInvoice(invoice: IInvoiceDescription): void;
  month: number;
  year: number;
}

const InvoiceDetail: FC<IProps> = ({
  isOpen,
  handleClose,
  studioInvoice,
  onUpdateInvoice,
  month,
  year,
}) => {
  const [invoice, setInvoice] = useState<IInvoiceDescription>({});
  const [loading, setLoading] = useState(false);
  // const categories = useAppSelector(state => state.invoice.categories);
  const loadInvoiceData = useCallback(() => {
    if (studioInvoice.studioId) {
      getInvoiceByStudioId(studioInvoice.studioId, month, year).then((data) => {
        setInvoice(data.data);
      });
    }
  }, [month, studioInvoice.studioId, year]);

  useEffect(() => {
    if (isOpen) {
      loadInvoiceData();
    }
  }, [isOpen, loadInvoiceData]);

  // const handleChangeCateSelect = (event: any) => {
  //   console.log(event.target.value);
  // };

  const onChangeInvoiceDetail =
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setInvoice((pre) => ({
        ...pre,
        [key]: event.target.value,
      }));
    };

  const onPreviewInvoice = async () => {
    try {
      setLoading(true);
      const response = await previewInvoice(studioInvoice.studioId, {
        month,
        year,
      });
      const link = document.createElement("a");
      link.download = `Studio_invoice_${studioInvoice.studioId}.pdf`;
      link.href = response.data.pdf_url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loadInvoiceData();
    } catch (error) {
      console.error(
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const publishSingleReport = async () => {
    try {
      setLoading(true);
      await publishSingleInvoice(studioInvoice.studioId, { year, month });
      loadInvoiceData();
    } catch (error) {
      toast.error("Error publishing invoice");
    } finally {
      setLoading(false);
    }
  };
  return (
    <CustomizedDialog
      open={isOpen}
      handleClose={handleClose}
      showFooter
      title={`${studioInvoice?.studioName}'s Invoice`}
      size="lg"
      handleSave={() => onUpdateInvoice(invoice)}
    >
      <Box component="form" sx={{ width: "50vw", marginBottom: 2 }}>
        <DetailField>
          <p>Studio name</p>
          <span>{studioInvoice?.studioName}</span>
          <p>Studio email</p>
          <span>{studioInvoice?.email}</span>
          <p>Bankgiro</p>
          <span>{studioInvoice?.bankgiro}</span>
          <p>Bank Account Number</p>
          <span>{studioInvoice?.bankAccountNumber}</span>
          <p>Month</p>
          <span>
            {month} / {year}
          </span>
          <p>Category</p>
          <span>{studioInvoice?.paymentCategory}</span>
          <p>Gross compensation</p>
          <span>{toFixedTwo(roundToTwoDecimalPlaces(studioInvoice?.grossPrice))}</span>
          <p>Vat %</p>
          <span>{studioInvoice?.vatPercent}</span>
          <p>FLagged Studio</p>
          <span>{studioInvoice?.specialDeals}</span>
          <p>Published</p>
          <span>
            <Chip
              label={invoice.published ? "Published" : "Unpublished"}
              color={invoice.published ? "success" : "info"}
              size="small"
            />
          </span>
          <p>PDF</p>
          <span>
            <Tooltip title="Generate a new pdf with new data and change the invoice status to Unpublished">
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="small"
                sx={{ marginRight: 1 }}
                onClick={onPreviewInvoice}
              >
                Preview
              </LoadingButton>
            </Tooltip>
            <Tooltip title="Send an email containing the latest invoice generated from the preview function">
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="small"
                onClick={publishSingleReport}
              >
                Publish
              </LoadingButton>
            </Tooltip>
          </span>
        </DetailField>
        <Grid container justifyContent={"space-between"} spacing={2}>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={studioInvoice?.paymentCategory || ""}
                label="Category"
                onChange={handleChangeCateSelect}
              >
                
                {categories.map(cate => <MenuItem value={cate.category}>{cate.category}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid> */}

          {/*<Grid item xs={6}>*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <TextField*/}
          {/*      value={invoice?.additionalCompensation || ""}*/}
          {/*      label="Additional Compensation"*/}
          {/*      onChange={onChangeInvoiceDetail("additionalCompensation")}*/}
          {/*      type={"number"}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                value={invoice?.commentOnPdf || ""}
                label="Comment on PDF"
                onChange={onChangeInvoiceDetail("commentOnPdf")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {isOpen && <AddFreeRows studioId={studioInvoice.studioId} month={month} year={year}/>}
    </CustomizedDialog>
  );
};

export default InvoiceDetail;

const DetailField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 24px;
  p {
    margin: 0;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
  }

  span {
    margin: 0;
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
  }
`;
