import { combineReducers } from '@reduxjs/toolkit';
import invoiceReducer from './invoice';
import { zipApi } from '../apis/zip';
const rootReducer = combineReducers({
  invoice: invoiceReducer,
  [zipApi.reducerPath]: zipApi.reducer,
});

export const reduxMiddleware = [
  zipApi.middleware,
];

export default rootReducer;