import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  createCampaignApi,
  deleteCampaignApi,
  editCampaignApi,
  getAllCampaignsApi,
  getPlans,
} from '../apis/campaign.api';
import CustomizedDialog from '../components/Common/CustomizedDialog';
import { IAddCampaignReq, ICampaign } from '../types/campaign';
import { DatePicker, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  Form,
  Input,
  Col,
  Row,
  Checkbox,
  Select,
  Radio,
  Space,
  Table,
  Button,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';

const CampaignScreen: FC = () => {
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paging, setPaging] = useState({
    page: 0,
    total: 0,
  });
  const [planOptions, setPlanOptions] = useState<Array<{ id: string; ordinary_price: number }>>([]);
  const [isOpenAdd, setIsOpenAdd] = useState<boolean>(false);
  const [isUseCoupon, setIsUseCoupon] = useState(false);
  const [discountType, setDiscountType] = useState(1);
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');
  const [form] = Form.useForm();
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [useExisted, setUseExisted] = useState(false);

  const isDev = process.env.REACT_APP_API_BASE_URL === 'https://dev-api.swiftr.app/v1' || process.env.REACT_APP_API_BASE_URL === 'http://localhost:5000/v1'
  const getAllCampaigns = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getAllCampaignsApi(paging.page, 10);
      setCampaigns(data.data);
      setPaging({ page: data.pagination.page, total: data.pagination.total });
    } catch (error) {
      toast.error('Fetch campaign is failed');
    } finally {
      setIsLoading(false);
    }
  }, [paging.page]);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const fetchPlans = useCallback(async () => {
    const data = await getPlans();
    setPlanOptions(data?.plans);
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await deleteCampaignApi(id);
        toast.success(`Delete successfully`);
        getAllCampaigns();
      } catch (error) {
        toast.error(`Cannot delete`);
      }
    },
    [getAllCampaigns],
  );

  const openEdit = useCallback(
    (data: ICampaign) => {
      setEditId(data._id);
      setEditName(data.name);
      const {
        startTime,
        endTime,
        pricePlanLight,
        pricePlanUnlimited,
        pricePlanUnlimited365,
        stripeCouponCode,
        ...leftData
      } = data;
      const formData = {
        ...leftData,
        rangePicker: [moment(startTime), moment(endTime)],
        existedCoupon: stripeCouponCode || '',
      };
      if (stripeCouponCode) {
        setIsUseCoupon(true);
        setDiscountType(leftData.amountOff ? 2 : 1);
        setUseExisted(true)
      }
      form.setFieldsValue(formData);
      setIsOpenAdd(true);
    },
    [form],
  );
  const columns: ColumnsType<ICampaign> = useMemo(
    () => [
      {
        dataIndex: 'name',
        title: 'Campaign name',
      },
      {
        dataIndex: 'startTime',
        title: 'Start time',
        render: (value) => moment(value).format('lll'),
      },
      {
        dataIndex: 'endTime',
        title: 'End time',
        render: (value) => moment(value).format('lll'),
      },
      {
        dataIndex: 'plans',
        title: 'Plans',
        render: (value) => value ? value.join(' | ') : '',
      },
      {
        dataIndex: 'percentOff',
        title: 'Percent off',
        render: (value) => (value || 0) + ' %',
      },
      {
        dataIndex: 'amountOff',
        title: 'Amount off',
        render: (value) => (value || 0) + ' SEK',
      },
      {
        dataIndex: 'stripeCouponCode',
        title: 'Coupon code',
      },
      {
        dataIndex: 'applyForReturnMember',
        title: 'Apply for return member ?',
        render: (value) => <Checkbox checked={!!value} />,
      },
      {
        dataIndex: 'action',
        title: 'Action',
        render: (_, record) => (
          <Space size="middle">
            {/* <Button type="primary" shape="circle" icon={<SearchOutlined />} /> */}
            <Button
              type="primary"
              shape="circle"
              disabled={moment(record.endTime).isBefore(moment())}
              icon={<EditOutlined />}
              onClick={() => openEdit(record)}
            />
            <Popconfirm
              placement="topLeft"
              title={'Delete this campaign ?'}
              okText="Yes"
              cancelText="No"
              onConfirm={() => onDelete(record._id)}
              disabled={moment(record.endTime).isBefore(moment())}
            >
              <Button
                disabled={moment(record.endTime).isBefore(moment())}
                type="primary"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [onDelete, openEdit],
  );

  const onSuccess = () => {
    setIsOpenAdd(false);
    form.resetFields();
    setIsUseCoupon(false);
    setDiscountType(1);
    setEditId('');
    setEditName('');
    setUseExisted(false);
  };
  const onCreate = async (data: IAddCampaignReq & { rangePicker: string[] }) => {
    setIsSaveLoading(true);
    const { rangePicker, ...body } = data;
    const startTime = moment(rangePicker[0]).toISOString();
    const endTime = moment(rangePicker[1]).toISOString();

    try {
      const req = {
        ...body,
        startTime,
        endTime,
        ...previewPrices,
      };
      if (editId) {
        await editCampaignApi(editId, req);
        toast.success('Edit campaign successfully!');
      } else {
        await createCampaignApi(req);
        toast.success('Create campaign successfully!');
      }
      getAllCampaigns();
      onSuccess();
    } catch (error) {
      const message = (error as any).response?.data?.message;
      toast.error(message || 'Create campaign is failed');
    } finally {
      setIsSaveLoading(false);
    }
  };

  const onChangeUseCoupon = (value: boolean) => {
    setIsUseCoupon(value);
    if (!value) {
      form.resetFields(['plans', 'percentOff', 'amountOff']);
    }
  };
  const planValues: string[] = Form.useWatch('plans', form);
  const percentOff = Form.useWatch('percentOff', form);
  const amountOff = Form.useWatch('amountOff', form);

  const previewPrices = useMemo(() => {
    const obj = {
      pricePlanLight: 0,
      pricePlanUnlimited: 0,
      pricePlanUnlimited365: 0,
    };
    planOptions.forEach((i) => {
      let price = i.ordinary_price;
      if ((planValues || []).includes(i.id)) {
        price = i.ordinary_price - (amountOff ? amountOff : (i.ordinary_price * percentOff) / 100);
      }

      if (i.id.includes('light')) {
        obj.pricePlanLight = Math.floor(price);
      } else if (i.id.includes('365')) {
        obj.pricePlanUnlimited365 = Math.floor(price);
      } else {
        obj.pricePlanUnlimited = Math.floor(price);
      }
    });
    return obj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOff, percentOff, JSON.stringify(planOptions), JSON.stringify(planValues)]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <h3>Campaigns</h3>
        <Button type="primary" onClick={() => setIsOpenAdd(true)}>
          Add campaign
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={campaigns}
        loading={isLoading}
        rowKey="_id"
        pagination={{
          total: paging.total,
          pageSize: 10,
          current: paging.page + 1,
          onChange: (p) => setPaging({ ...paging, page: p }),
        }}
      />

      <CustomizedDialog
        handleClose={onSuccess}
        open={isOpenAdd}
        title={editId ? `Edit campaign ${editName}` : 'Create a campaign'}
        showFooter={true}
        size="lg"
        handleSave={() => form.submit()}
        saveTitle={editId ? 'Save edit' : 'Create new'}
        disableSave={isSaveLoading}
      >
        <Form layout="vertical" style={{ width: '50vw' }} onFinish={onCreate} form={form}>
          <Form.Item
            label="Campaign Name"
            rules={[{ required: true, message: 'Campaign name is required !' }]}
            name="name"
          >
            <Input placeholder="Campaign Name" />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Start Time -> End Time"
                name="rangePicker"
                rules={[
                  {
                    required: true,
                    message: 'Start time and end time is required !',
                    type: 'array' as const,
                  },
                ]}
              >
                <DatePicker.RangePicker
                  showTime
                  showSecond={false}
                  disabledDate={(date) => date.isBefore(moment().subtract(1, 'day'))}
                  popupStyle={{ zIndex: 1301 }}
                />
              </Form.Item>
            </Col>
            <Col span={4}></Col>
            <Col span={8}>
              <Form.Item
                label=" "
                name="applyForReturnMember"
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox>Apply for return member</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Image link"
                name="imageUrl"
                rules={[{ required: true, message: 'Image is required !' }]}
              >
                <Input placeholder="Image link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Image link in mobile"
                name="imageMobileUrl"
                rules={[{ required: true, message: 'Mobile image is required !' }]}
              >
                <Input placeholder="Image link in mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Swedish note" name="note">
                <Input placeholder="Note" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="English note" name="noteEn">
                <Input placeholder="English note" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Swedish note in mobile" name="appNote">
                <Input placeholder="Note" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="English note in mobile" name="appNoteEn">
                <Input placeholder="English note" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Checkbox checked={isUseCoupon} onChange={(e) => onChangeUseCoupon(e.target.checked)}>
              Use coupon
            </Checkbox>
          </Row>

          {isUseCoupon && (
            <Row style={{ marginTop: 16, marginBottom: 16 }}>
              <Radio.Group
                value={useExisted}
                onChange={(e) => {
                  setUseExisted(e.target.value);
                }}
              >
                <Space>
                  <Radio value={false}>Create coupon form dashboard</Radio>
                  <Radio value={true}>Use existed coupon form Stripe</Radio>
                </Space>
              </Radio.Group>
            </Row>
          )}

          {isUseCoupon &&
            (!useExisted ? (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Type">
                      <Radio.Group
                        value={discountType}
                        onChange={(e) => {
                          setDiscountType(e.target.value);
                        }}
                        disabled={!isUseCoupon}
                      >
                        <Space>
                          <Radio value={1}>Percentage discount</Radio>
                          <Radio value={2}>Fixed amount discount</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {discountType === 1 ? (
                      <Form.Item
                        label="Percentage off"
                        name="percentOff"
                        rules={[
                          {
                            type: 'number',
                            required: isUseCoupon,
                            min: 0,
                            max: 100,
                            message: 'Percentage off is from 0 to 100',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Percentage off"
                          addonAfter="%"
                          disabled={!isUseCoupon}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Discount amount"
                        name="amountOff"
                        rules={[{ type: 'number', required: isUseCoupon }]}
                      >
                        <InputNumber
                          placeholder="Discount amount"
                          addonBefore="SEK"
                          addonAfter="SEK"
                          disabled={!isUseCoupon}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
            ) : (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Existed coupon id" name="existedCoupon">
                    <Input placeholder="Existed coupon id" disabled={!isUseCoupon} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <a
                    href={isDev ? 'https://dashboard.stripe.com/test/coupons/create' : "https://dashboard.stripe.com/coupons/create"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a coupon by stripe !
                  </a>
                  <br />
                  <a
                    href="https://stripe.com/docs/billing/subscriptions/coupons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read about coupon of stripe ?
                  </a>
                </Col>
              </Row>
            ))}
          <Form.Item
            name="plans"
            label="Apply plans"
            rules={[
              {
                type: 'array',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select plan you want apply campaign"
              allowClear
              options={planOptions.map((i) => ({ value: i.id, label: i.id?.toUpperCase() }))}
              disabled={!isUseCoupon}
            ></Select>
          </Form.Item>
          {!useExisted && <Row gutter={16} style={{ marginBottom: 12 }}>
            {Object.keys(previewPrices).map((key, k: number) => (
              <Col span={8} key={k}>
                {key}: {previewPrices[key as keyof typeof previewPrices]} SEK
              </Col>
            ))}
          </Row>}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Plan light note" name="planLightNote">
                <Input placeholder="Plan light note" disabled={!isUseCoupon} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Plan unlimited note" name="planUnlimitedNote">
                <Input placeholder="Plan unlimited note" disabled={!isUseCoupon} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Plan unlimited 365 note" name="planUnlimited365Note">
                <Input placeholder="Plan unlimited 365 note" disabled={!isUseCoupon} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomizedDialog>
    </div>
  );
};

export default CampaignScreen;
