import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {Pie} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  // ChartOptions,
} from 'chart.js'
import {getAccessStatusUserChartApi} from '../../apis/dashboard'
import useToast from '../../hooks/useToast'
import {IGetAccessStatusUserChartRes} from '../../types/dashboard'
import {Paper} from '@mui/material'
ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: true,
      text: 'Pie chart for number of users on each access status',
    },
  },
}

const UserAccessStatusChart = memo(() => {
  const toast = useToast()
  const [dataChart, setDataChart] = useState<IGetAccessStatusUserChartRes>([])
  const getOrganicUserChart = useCallback(
    async () => {
      try {
        const res = await getAccessStatusUserChartApi()

        setDataChart(res)
      } catch (error) {
        toast('warning', 'Error when get data for organic user chart')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(() => {
    getOrganicUserChart()
  }, [getOrganicUserChart])

  const pieData = useMemo(() => {
    const labels = dataChart.map(i => `${i.accessStatus}`)
    const data = dataChart.map(i => i.count)
    return {
      labels: labels,
      datasets: [
        {
          label: '# of Votes',
          data: data,
          backgroundColor: [
            '#FE7661',
            '#6FDB7F',
            '#F70073',
            '#C3A0D5',
            '#FCC879',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataChart)])
  return (
    <Paper className="dashboard-pie">
      <h3>Access status user</h3>
      <div className="chart-access">
        <Pie options={options} data={pieData} />
      </div>
    </Paper>
  )
})
export default UserAccessStatusChart
