import React from 'react'

const UpdateInputVisits = ({
  value,
  updateVisit,
  hasUpdatedVisits,
  setHasUpdatedVisits,
}) => {
  const [updatedValue, setUpdatedValue] = React.useState('')
  const [isEdit, setEdit] = React.useState(false)
  const [updatedField, setUpdatedField] = React.useState(false)

  React.useEffect(() => {
    setUpdatedValue(value)
  }, [value])

  const updateText = (e, text) => {
    e.stopPropagation()
    setUpdatedValue(text)
  }
  const update = e => {
    e.stopPropagation()

    updateVisit(updatedValue)
    setEdit(false)
    setHasUpdatedVisits(false)

    if (value !== updatedValue) setUpdatedField(true)
  }

  if (isEdit)
    return (
      <input
        onChange={e => {
          updateText(e, e.target.value)
        }}
        value={updatedValue}
        type="text"
        onBlur={e => update(e)}
        autoFocus
      />
    )
  return (
    <span onClick={(e) => { e.stopPropagation(); setEdit(true) }} style={{ cursor: 'pointer' }}>
      {updatedField && !hasUpdatedVisits ? (
        <strong>{updatedValue}</strong>
      ) : (
        updatedValue
      )}
    </span>
  )
}

export default UpdateInputVisits
