import React, {FC, memo} from 'react'
interface IProps{
  isLoading: boolean
}
const Loading:FC<IProps> = memo(({isLoading}) => {
  return isLoading ? (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  ) : null
})

export default Loading
