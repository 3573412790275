import axios from 'axios'
import {userIsAuthenticated, getAccessToken} from '../utils/local-storage'

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL + '/swiftr-admin/dashboard/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

const api = axios.create(config)

api.interceptors.request.use(
  req => {
    if (userIsAuthenticated()) {
      const accessToken = getAccessToken()
      req.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return req
  },
  error => Promise.reject(error),
)

export const getStudios = (page = 1, limit = 100, params, isSearch) => {
  const updatedConfig = {...config}

  if (params && Object.keys(params).length) {
    Object.keys(params).forEach(k => params[k] === '' && delete params[k])
    updatedConfig.params = params
  }

  if (isSearch) {
    return api.get(`/studios/search?page=${page}&limit=${limit}`, updatedConfig)
  }

  return api.get(`/studios?page=${page}&limit=${limit}`, updatedConfig)
}

export const getAllFeeds = (page = 1, limit = 200, types, forMode) =>
  api.post(`/newsfeed/read-all`, {page, limit, sort: '-createdAt', types, ...(forMode && {forMode})})
export const deleteFeed = _id => api.post(`/newsfeed/remove`, {_id})
export const getCities = () =>
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/locations/cities`, config)

export const addFeedWithImage = data =>
  api.post('/newsfeed/create-with-image', data, config)

export const addFeedWithVideo = data =>
  api.post('/newsfeed/create-with-video', data, config)

export const updateFeedWithImage = data =>
  api.post('/newsfeed/update-with-image', data, config)

export const updateFeedWithVideo = data =>
  api.post('/newsfeed/update-with-video', data, config)

export const getInvoices = params => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get(`/invoices`, config)
}

export const getVisits = (page = 1, limit = 10, id, params) => {
  const updatedConfig = config
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get(`studios/${id}/checkins?page=${page}&limit=${limit}`, config)
}

export const updateInvoice = body => api.post('/invoices', body, config)
export const updateAllGrossCompensations = (id, body) =>
  api.post(`studios/${id}/checkins`, body, config)
export const getUserTypes = params => {
  const updatedConfig = config
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/invoices/user-types', updatedConfig)
}
export const getCategories = params => {
  const updatedConfig = config
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/invoices/categories', updatedConfig)
}
export const editCategory = (data, id) =>
  api.put(`/invoices/categories/${id}`, data)

export const addBanner = data => api.post('/studios/banners', data)
export const deleteBannerApi = id => api.delete(`/studios/banners/${id}`)

export const editBanner = (id, data) => api.put(`/studios/banners/${id}`, data)
export const editOpenHours = (id, data) =>
  api.put(`/studios/opening-hours/${id}`, data)

export const addOpenHours = body => api.post('/studios/opening-hours', body)
export const deleteOpenHours = id => api.delete(`/studios/opening-hours/${id}`)

export const refreshToken = body => api.post('/auth/refresh-access-token', body)
export const getGraphs = () => api.get('/stats/graphs')
export const getStats = () => api.get('/stats')
export const login = body => api.post('/auth/login', body)
export const logout = () => api.post('/auth/logout')

export const getKpis = params => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/kpis', updatedConfig)
}

export const getKpisMemberships = params => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/kpis/memberships', updatedConfig)
}

export const getKpisGenders = params => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/kpis/genders', updatedConfig)
}

export const getKpisCities = params => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/kpis/cities', updatedConfig)
}

export const getKpisCustomers = (params = {page: 1, limit: 200}) => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get(`/kpis/customers`, updatedConfig)
}

export const exportCustomersKPIS = () => {
  const updatedConfig = {...config}
  return api.get('/kpis/customers/export', updatedConfig)
}

export const searchCustomers = params => {
  const updatedConfig = config
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get('/kpis/customers/search', updatedConfig)
}

export const exportVisits = id => {
  const updatedConfig = {...config}
  return api.get(`/studios/${id}/export-visits`, updatedConfig)
}

export const exportAllStudiosVisits = params => {
  const updatedConfig = {...config, params}
  return api.get(`/studios/export-visits`, updatedConfig)
}

export const previewInvoice = (studioId, params) => {
  const updatedConfig = {...config }
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.get(`/invoices/preview?studioId=${studioId}`, updatedConfig)
}

export const publishSingleInvoice = (studioId, params) => {
  const updatedConfig = {...config}
  if (params && Object.keys(params).length) updatedConfig.params = params
  return api.post(`/invoices/${studioId}/publish`, {}, updatedConfig)
}

export const publishAllInvoices = () => {
  return api.post('/invoices/publish')
}

export const getPublishingAllInvoicesState = () => {
  return api.get('/invoices/publish/state')
}

export const exportStudios = () => {
  return api.get('/studios/export', {...config })
}

export default api
