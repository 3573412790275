import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICategory, InvoiceState } from './type'


const initialState: InvoiceState = {
  categories: [],
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setCategories: ((state, {payload}: PayloadAction<ICategory[]>) => {
        state.categories = payload
    })
  },
})

// Action creators are generated for each case reducer function
export const { setCategories } = invoiceSlice.actions

const invoiceReducer =  invoiceSlice.reducer;
export default invoiceReducer