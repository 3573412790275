export const SET_STUDIOS = 'SET_STUDIOS'
export const SET_SELECTED_STUDIOS = 'SET_SELECTED_STUDIOS'
export const SET_NOTIFICATION_MESSAGE = 'SET_NOTIFICATION_MESSAGE'
export const SET_USER = 'SET_USER'
export const SET_SELECTED_VISITS = 'SET_SELECTED_VISITS'
export const SET_VISITS_DATES = 'SET_VISITS_DATES'
export const ADD_TO_INVOICE_LIST = 'ADD_TO_INVOICE_LIST'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'

const setStudios = (studios, state) => {
  return {...state, studios}
}

const setSelectedStudios = (selectedStudios, state) => {
  return {...state, selectedStudios}
}

const setSelectedVisits = (selectedVisits, state) => {
  return {...state, selectedVisits}
}

const setVisitsDates = (visitsDates, state) => {
  return {...state, visitsDates}
}

const setNotification = (notification, state) => {
  return {...state, notification}
}

const auth = (user, state) => {
  return {...state, user}
}

const addToInvoicesList = (invoice, state) => {
  return {...state, invoicesList: [...state.invoicesList, invoice]}
}

const setInvoicesList = (invoices, state) => {
  return {...state, invoicesList: invoices}
}

export const Reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return auth(action.user, state)
    case SET_STUDIOS:
      return setStudios(action.studios, state)
    case SET_SELECTED_STUDIOS:
      return setSelectedStudios(action.selectedStudios, state)
    case SET_NOTIFICATION_MESSAGE:
      return setNotification(action.notification, state)
    case SET_SELECTED_VISITS:
      return setSelectedVisits(action.selectedVisits, state)
    case SET_VISITS_DATES:
      return setVisitsDates(action.visitsDates, state)
    case ADD_TO_INVOICE_LIST:
      return addToInvoicesList(action.invoice, state)
    case UPDATE_INVOICES:
      return setInvoicesList(action.invoices, state)
    default:
      return state
  }
}
