import React, {useEffect} from 'react'
import {FormControl, Grid, MenuItem, Select} from '@mui/material'
import KPITable from '../Common/KPITable'
import {getKpisGenders} from '../../apis/api'
import classes from './Kpi.module.css'

const KpisGenders = ({reformatData, labelsAndKeys}) => {
  const [data, setData] = React.useState([])
  const [gender, setGender] = React.useState('Male')
  const [isLoading, setLoading] = React.useState(false)

  const getGenderData = async () => {
    setLoading(true)
    const res = await getKpisGenders({gender})
    const formatedData = reformatData(res?.data.data)
    setData(formatedData)
    setLoading(false)
  }

  useEffect(() => {
    getGenderData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender])

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={4} md={2}>
          Select Gender
        </Grid>
        <Grid item xs={4} md={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.textField}
              onChange={e => setGender(e.target.value)}
              value={gender}
            >
              {[
                {key: 'Male', value: 'Male'},
                {key: 'Female', value: 'Female'},
              ].map(item => {
                return (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="kpiContainer">
        <KPITable
          headerClassName="kpisGenderHeader"
          title={gender}
          labelsAndKeys={labelsAndKeys}
          data={data}
        />
        {isLoading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  )
}

export default KpisGenders
