import React from 'react'
// Material-UI
import {Paper, Typography} from '@mui/material'

// Charts
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'

import {Animation, EventTracker} from '@devexpress/dx-react-chart'
import {LegendItem, LegendLabel, LegendRoot} from './ChartLegend'

const LineChart = ({classes, chartData, title, height = 300, children}) => {
  return (
    <Paper>
      <Typography align="left" variant="body2" className={classes.graphHeader}>
        {title}
      </Typography>
      <div className={classes.lineChartContainer}>
        <Chart data={chartData} height={height}>
          <ArgumentAxis />
          <ValueAxis />
          {children}
          <Typography variant="caption" display="block" gutterBottom>
            <Legend
              rootComponent={LegendRoot}
              labelComponent={LegendLabel}
              itemComponent={LegendItem}
              position="bottom"
            />
          </Typography>
          <Animation />

          <EventTracker />
          <Tooltip />
        </Chart>
      </div>
    </Paper>
  )
}

export default LineChart
