import React, {useState} from 'react'
import {Menu, MenuItem, Button, Grid, Dialog} from '@mui/material'
import StudioData from '../components/ListStudios/StudioData'
import BannerForm from '../components/Forms/BannerForm'
import MyContext from '../context/context'
import useToast from '../hooks/useToast'
import SearchStudiosBy from '../components/ListStudios/SearchStudiosBy'
import {exportStudios, getStudios} from '../apis/api'
import moment from 'moment'

// Component Style
import classes from './ListStudios.module.css'
import LoadingButton from '../components/LoadingButton'
import Loading from '../components/Common/Loading'
import exportExcelFormJson from '../utils/exportExcelFormJson'
import { LONG_DAY_TIME } from '../constants/time-format'
import { STUDIOS_EXCEL } from '../constants/common'

function ListStudios() {
  const context = React.useContext(MyContext)
  const notify = useToast()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState('')
  const [selected, setSelected] = React.useState({})
  const [pagination, setPagination] = React.useState({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0,
  })

  const [isLoading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = React.useState(undefined)
  const [query, setQuery] = React.useState({
    q: '',
    country: '',
    schedule: '',
    hidden: '',
    scheduleIntegrated: '',
    sortBy: '',
    isAsc: false,
  })
  const [loadingExport, setLoadingExport] = React.useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickMenu = type => {
    if (context.selectedStudios && context.selectedStudios.length) {
      setType(type)
      setOpen(true)
      handleClose()
    } else {
      notify('info', `Please select one or more studios`)
    }
  }

  const setRows = context.setStudios
  const getData = async (page, q = searchParams, isSearch) => {
    try {
      setLoading(true)
      const res = await getStudios(
        page,
        200, // limit
        q,
        isSearch || Object.values(q || {}).some(v => v),
      )
      const data = res?.data.data.map(studio => {
        const openHours =
          studio.openingHours && studio.openingHours.length
            ? studio.openingHours[studio.openingHours.length - 1]
            : {}
        const openHoursStartDate = openHours.startDate
          ? moment(openHours.startDate).format('YYYY-MM-DD')
          : ''
        const openHoursEndDate = openHours.endDate
          ? moment(openHours.endDate).format('YYYY-MM-DD')
          : ''

        const banner =
          studio.banners && studio.banners.length
            ? studio.banners[studio.banners.length - 1]
            : {}
        const bannerStartDate = banner.startDate
          ? moment(banner.startDate).format('YYYY-MM-DD')
          : ''
        const bannerEndDate = banner.endDate
          ? moment(banner.endDate).format('YYYY-MM-DD')
          : ''

        return {
          id: studio._id,
          name: studio.name,
          city: studio.city,
          manager: studio.studioManager ? studio.studioManager : '',
          email1:
            studio.contact && studio.contact.email ? studio.contact.email : '',
          email2:
            studio.contact && studio.contact.email1
              ? studio.contact.email1
              : '',
          email3:
            studio.contact && studio.contact.email2
              ? studio.contact.email2
              : '',
          liveOnApp: studio.liveOnApp,
          hidden: studio.hidden,
          schedule: studio.schedule,
          openHours: studio.openHours?.sv,
          scheduleIntegrated: studio.scheduleIntegrated,
          openingHoursInfo: openHours.details || '',
          openingHoursStartDate: openHoursStartDate,
          openingHoursEndDate: openHoursEndDate,
          bannerInfo: banner.message || '',
          bannerStartDate: bannerStartDate,
          bannerEndDate: bannerEndDate,
          importantNotice: studio.importantNotice || '',
          churnDate: studio.churnDate
            ? moment(studio.churnDate).format('YYYY-MM-DD')
            : '',
          banner,
          openingHours: openHours,
        }
      })
      setRows([...data])
      setPagination(res?.data.meta)
      setSearchParams(q || searchParams)
    } catch (error) {
      notify('error', 'Error when fetching studios')
    } finally {
      setLoading(false)
    }
  }

  const onClearFilter = async () => {
    const defaultQuery = {
      q: '',
      country: '',
      schedule: '',
      hidden: '',
      scheduleIntegrated: '',
    }
    setQuery(defaultQuery)
    getData(1, defaultQuery, false)
  }

  React.useEffect(() => {
    return () => {
      context.setStudios([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!open) getData(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  async function handleExportToExcel() {
    try {
      setLoadingExport(true)
      const res =  await exportStudios()
      exportExcelFormJson({
        apiData: res.data,
        fileName: `studios-${moment().format(LONG_DAY_TIME)}.xlsx`,
        header: STUDIOS_EXCEL,
      })
    } catch (error) {
      notify('error', 'Error exporting studios')
    } finally {
      setLoadingExport(false)
    }
  }

  return (
    <React.Fragment>
      <Loading isLoading={isLoading} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.container}>
            <h2>Studios</h2>
          </div>
        </Grid>
        <Grid className={classes.menuContainer} item xs={12} sm={6} gap={2}>
          <LoadingButton
            isLoading={loadingExport}
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.exportBtn}
            onClick={handleExportToExcel}
            variant="contained"
            size='small'
          >
            Export to excel
          </LoadingButton>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.menuBtn}
            onClick={handleClick}
            variant="contained"
            size='small'
          >
            Create Temporary Banner
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="createMenu"
          >
            <MenuItem
              onClick={() => {
                clickMenu('tBanner')
                setSelected({})
              }}
            >
              Create Temporary Banner
            </MenuItem>
            <MenuItem
              onClick={() => {
                clickMenu('hBanner')
                setSelected({})
              }}
            >
              Create opening hour information
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12} sm={8}>
          <SearchStudiosBy
            getData={getData}
            query={query}
            setQuery={setQuery}
          />
        </Grid>
      </Grid>
      <StudioData
        setType={setType}
        setOpen={setOpen}
        selected={selected}
        setSelected={setSelected}
        paginationData={pagination}
        setLoading={setLoading}
        getData={getData}
        type={type}
        query={query}
        setQuery={setQuery}
        onClearFilter={onClearFilter}
      />
      {open && (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg" className={classes.customDialog}>
          {type === 'tBanner' && (
            <BannerForm
              bannerName={type}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
          )}
          {type === 'hBanner' && (
            <BannerForm
              bannerName={type}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
          )}
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default ListStudios
