import React from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import classes from './Style.module.css'

const SearchStudiosBy = ({getData, query, setQuery}) => {
  const [searchText, changeSearchText] = React.useState('')
  const [country, setCountry] = React.useState('')

  React.useEffect(() => {
    changeSearchText(query.q)
    setCountry(query.country)
  }, [query])

  const onChangeSearchText = e => {
    changeSearchText(e.target.value)
  }

  const handleSearchClick = e => {
    e.preventDefault()
    handleUpdate()
  }

  const handleCountryChage = e => {
    const country = e.target.value
    setCountry(country)
    handleUpdate(country)
  }

  const handleUpdate = (updatedCountry = country) => {
    const searchQuery = {
      ...query,
      q: searchText,
      ...(updatedCountry && {country: updatedCountry}),
    }
    setQuery(searchQuery)
    getData(1, searchQuery, true)
  }

  return (
    <form onSubmit={handleSearchClick}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={3} md={4} className={classes.textFieldContainer}>
          <TextField
            id="outlined-multiline-static"
            name="title"
            label="Search here..."
            variant="outlined"
            value={searchText?.length ? searchText : ""}
            onChange={e => onChangeSearchText(e)}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="country-select" style={{minWidth: 120}}>Country</InputLabel>
            <Select
              labelId="country-select"
              label="Select Country"
              className={classes.textField}
              onChange={handleCountryChage}
              style={{minWidth: 120}}
              value={country || ''}
            >
              <MenuItem value="">Select Country</MenuItem>
              {[
                {key: 'Sweden', value: 'SE'},
                {key: 'Luxembourg', value: 'LU'},
              ].map(item => {
                return (
                  <MenuItem value={item.value} key={item.key}>
                    {item.key}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} md={2} className={classes.btnContainer}>
          <button className={classes.btn}>Search</button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SearchStudiosBy
