import {useContext} from 'react'
import MyContext from '../context/context'

type IType = 'success' |  'error' | 'danger' | 'info' |'warning'

function useToast() {
  const context = useContext(MyContext)

  function notify(type: IType, message: string) {
    switch (type) {
      case 'success':
        context.setNotificationMessage({
          title: 'Success',
          description: message,
          backgroundColor: '#5cb85c',
        })
        setTimeout(() => {
          context.setNotificationMessage({})
        }, 5000)
        break
      case 'error':
        context.setNotificationMessage({
          title: 'Error',
          description: message,
          backgroundColor: '#d9534f',
        })
        setTimeout(() => {
          context.setNotificationMessage({})
        }, 5000)
        break
      case 'danger':
        context.setNotificationMessage({
          title: 'Danger',
          description: message,
          backgroundColor: '#d9534f',
        })
        setTimeout(() => {
          context.setNotificationMessage({})
        }, 5000)
        break
      case 'info':
        context.setNotificationMessage({
          title: 'Info',
          description: message,
          backgroundColor: '#5bc0de',
        })
        setTimeout(() => {
          context.setNotificationMessage({})
        }, 5000)
        break
      case 'warning':
        context.setNotificationMessage({
          title: 'Warning',
          description: message,
          backgroundColor: '#f0ad4e',
        })
        setTimeout(() => {
          context.setNotificationMessage({})
        }, 5000)
        break
      default:
        return {}
    }
  }
  return notify
}

export default useToast
