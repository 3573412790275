import React, { useContext, useEffect } from 'react'
import ListMenu from '../components/ListMenu/ListMenu.Component'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Routes from '../Routes/Route'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Toast from '../commons/Toast'
import MyContext from '../context/context'
import api, { refreshToken, logout } from '../apis/api'
import {
  getRefreshToken,
  getUser,
  setAccessToken,
  userIsAuthenticated,
} from '../utils/local-storage'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import useToast from '../hooks/useToast'
// Component Style
import classes from './Dashboard.module.css'
import { Box, useMediaQuery } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar';
import apiV2 from '../apis/api-v2'

export default function Dashboard() {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const interceptorId = api.interceptors.response.use(
    res => Promise.resolve(res),
    async error => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          const userRefreshToken = getRefreshToken()
          if (userRefreshToken) {
            const res = await refreshToken({ refreshToken: userRefreshToken })
            await setAccessToken(res?.data.accessToken)
            error.config.headers[
              'Authorization'
            ] = `Bearer ${res?.data.accessToken}`
            return api.request(error.config)
          } else {
            navigate('/login')
          }
        }
        return Promise.reject(error)
      }
    },
  )

  const interceptor2Id = apiV2.interceptors.response.use(
    res => Promise.resolve(res),
    async error => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          const userRefreshToken = getRefreshToken()
          if (userRefreshToken) {
            const res = await refreshToken({ refreshToken: userRefreshToken })
            await setAccessToken(res?.data.accessToken)
            error.config.headers[
              'Authorization'
            ] = `Bearer ${res?.data.accessToken}`
            return apiV2.request(error.config)
          } else {
            navigate('/login')
          }
        }
        return Promise.reject(error)
      }
    },
  )
  
  const context = useContext(MyContext)
  useEffect(() => {
    const isAuthenticated = userIsAuthenticated()
    if (!isAuthenticated) {
      navigate('/login')
    }
    const { user } = getUser()
    context.auth(user)

    return () => {
      api.interceptors.response.eject(interceptorId)
      apiV2.interceptors.response.eject(interceptor2Id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogout = async () => {
    try {
      const res = await logout()
      if (res.status === 200) {
        localStorage.clear()
        context.auth({})
        navigate('/login')
      }
    } catch (error) {
      toast('error', 'Error logging out')
    }
  }

  const position = 'top-right'
  const toastList = [context.notification]

  if (!userIsAuthenticated()) {
    return <></>
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        {context.notification?.title && (
          <Toast position={position} toastList={toastList} />
        )}
        <CssBaseline />
        <AppBar position="fixed" style={{ backgroundColor: '#FF7878', zIndex:1000}} className={classes.appBar}>
          <Toolbar className={classes.header}>
            <div className={classes.logoContainer}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div
                onClick={() => navigate('/dashboard')}
                className={classes.home}
              >
                <img src="/assets/icons/logo.svg" alt="logo" />
              </div>
            </div>
            <div>
              <IconButton
                size="medium"
                className={classes.logoutIcon}
                onClick={handleLogout}
              >
                <ExitToAppIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
          <Drawer
            variant={matchUpMd ? 'persistent' : 'temporary'}

            anchor="left"
            open={true}
            sx={{
              '& .MuiDrawer-paper': {
                width: 260,
                background: theme.palette.background.default,
                color: theme.palette.text.primary,
                borderRight: 'none',
                [theme.breakpoints.up('md')]: {
                  top: '64px'
                },
                zIndex: 500,
              }
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
          >
            <PerfectScrollbar
              component="div"
              style={{
                height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              <ListMenu />

            </PerfectScrollbar>
          </Drawer>
        </Box>
        <div className={classes.content}>
          <PerfectScrollbar component="div" style={{
                // paddingLeft: '16px',
                paddingRight: '16px'
              }}>
            <Routes />
          </PerfectScrollbar>
        </div>
      </Box>
    </React.Fragment>
  )
}