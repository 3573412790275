import React from 'react'

export default React.createContext({
  studios: [],
  selectedStudios: [],
  notification: {},
  user: {},
  selectedVisits: [],
  visitsDates: {},
  invoicesList: [],
  setSelectedVisits: selectedVisits => {},
  setVisitsDates: visitsDates => {},
  auth: user => {},
  setStudios: studios => {},
  setSelectedStudios: selectedStudios => {},
  setNotificationMessage: notification => {},
  addToInvoicesList: invoice => {},
  setInvoicesList: inVoices => {},
})
