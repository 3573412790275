import React, {useReducer} from 'react'
import MyAppContext from './context'
import {
  Reducer,
  SET_STUDIOS,
  SET_SELECTED_STUDIOS,
  SET_NOTIFICATION_MESSAGE,
  SET_USER,
  SET_SELECTED_VISITS,
  SET_VISITS_DATES,
  ADD_TO_INVOICE_LIST,
  UPDATE_INVOICES,
} from './reducers'

/**
 * Makes sure the component that is going to use dispatch is mounted before
 * calling dispatch
 *
 * @param {useReducer.dispatch} dispatch the dispatch func returned from useReducer
 */
function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)

  React.useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}

const GlobalState = props => {
  const [myState, dispatch] = useReducer(Reducer, {
    studios: [],
    selectedStudios: [],
    notification: {},
    user: {},
    selectedVisits: [],
    visitsDates: {},
    invoicesList: [],
  })

  const safeDispatch = useSafeDispatch(dispatch)

  const setStudios = React.useCallback(
    studios => safeDispatch({type: SET_STUDIOS, studios}),
    [safeDispatch],
  )

  const auth = React.useCallback(user => safeDispatch({type: SET_USER, user}), [
    safeDispatch,
  ])

  const setSelectedStudios = React.useCallback(
    selectedStudios =>
      safeDispatch({type: SET_SELECTED_STUDIOS, selectedStudios}),
    [safeDispatch],
  )

  const setSelectedVisits = React.useCallback(
    selectedVisits => safeDispatch({type: SET_SELECTED_VISITS, selectedVisits}),
    [safeDispatch],
  )

  const setVisitsDates = React.useCallback(
    visitsDates => safeDispatch({type: SET_VISITS_DATES, visitsDates}),
    [safeDispatch],
  )

  const setNotificationMessage = React.useCallback(
    notification =>
      safeDispatch({type: SET_NOTIFICATION_MESSAGE, notification}),
    [safeDispatch],
  )

  const addToInvoicesList = React.useCallback(
    invoice => safeDispatch({type: ADD_TO_INVOICE_LIST, invoice}),
    [safeDispatch],
  )

  const setInvoicesList = React.useCallback(
    invoices => safeDispatch({type: UPDATE_INVOICES, invoices}),
    [safeDispatch],
  )

  return (
    <MyAppContext.Provider
      value={{
        studios: myState.studios,
        selectedStudios: myState.selectedStudios,
        notification: myState.notification,
        user: myState.user,
        visitsDates: myState.visitsDates,
        selectedVisits: myState.selectedVisits,
        invoicesList: myState.invoicesList,
        setSelectedVisits: setSelectedVisits,
        setVisitsDates: setVisitsDates,
        auth: auth,
        setNotificationMessage: setNotificationMessage,
        setStudios: setStudios,
        setSelectedStudios: setSelectedStudios,
        addToInvoicesList: addToInvoicesList,
        setInvoicesList: setInvoicesList,
      }}
    >
      {props.children}
    </MyAppContext.Provider>
  )
}

export default GlobalState
