import { IUser } from '../types/user';
import apiV2 from './api-v2';

export const getUserMissingSSNApi = async (): Promise<IUser[]> => {
  const res = await apiV2.get(`/users/feature/user-missing-ssn`);
  return res.data;
};
export const approvedUserMissingSSNApi = async (userId: string): Promise<IUser[]> => {
  const res = await apiV2.patch(`/users/feature/user-missing-ssn?userId=${userId}`);
  return res.data;
};

export const deniedUserMissingSSNApi = async (userId: string): Promise<IUser[]> => {
  const res = await apiV2.patch(`/users/feature/user-missing-ssn/denied?userId=${userId}`);
  return res.data;
};


