import React, {useCallback, useState} from 'react'
import KPITable from '../components/Common/KPITable'
import {getKpis} from '../apis/api'
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material'
import classes from './Paying.module.css';

const PayingMembers = () => {
  const labelsAndKeys = [
    {name: 'Opening balance members(OB)', key: 'openingBalance'},
    {
      name: 'New Paying Members',
      key: 'newPayingMembers',
      className: 'newPayingMembers',
    },
    {name: 'Churned members', key: 'churnedMembers'},
    {name: 'Closing balance', key: 'closingBalance'},
    {name: 'Closing balance freezed members', key: 'freezedMemebrs'},
    {name: 'Closing balance paying', key: 'closingBalancePaying'},
    {name: 'Net increase', key: 'netIncrease'},
    {name: 'Churned %', key: 'churnedPercent', className: 'churnedPercent'},
    {name: 'Freezed box %', key: 'freezedPercent'},
  ]

  const [country, setCountry] = React.useState('SE')
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [data, setData] = React.useState([])
  const [isLoading, setLoading] = useState(false)

  const changeYear = action => {
    if (action === 'next') {
      setYear(year + 1)
    } else if (action === 'previous') {
      setYear(year - 1)
    }
  }

  const reformatData = array => {
    const formated = {
      openingBalance: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      newPayingMembers: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      churnedMembers: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      churnedPercent: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      closingBalance: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      closingBalancePaying: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      freezedMemebrs: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      freezedPercent: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      netIncrease: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
    }

    array.forEach(month => {
      Object.keys(month).forEach(item => {
        if (formated[item]) {
          let value =
            month[item] > 0 && month[item] < 1
              ? month[item].toFixed(2)
              : month[item]
          switch (month.month) {
            case 1:
              formated[item].jan = value
              break
            case 2:
              formated[item].feb = value
              break
            case 3:
              formated[item].mar = value
              break
            case 4:
              formated[item].apr = value
              break
            case 5:
              formated[item].may = value
              break
            case 6:
              formated[item].jun = value
              break
            case 7:
              formated[item].jul = value
              break
            case 8:
              formated[item].aug = value
              break
            case 9:
              formated[item].sep = value
              break
            case 10:
              formated[item].oct = value
              break
            case 11:
              formated[item].nov = value
              break
            case 12:
              formated[item].dec = value
              break
            default:
          }
        }
      })
    })
    return formated
  }

  const getPaymingDate = useCallback(
    async () => {
      try {
        setLoading(true)
        const res = await getKpis({country, year})
        const formatedData = reformatData(res?.data.data)
        setData(formatedData)
      } catch (error) {
      console.log("🚀 ~ file: PayingMembers.js ~ line 269 ~ error", error)
        
      }finally{
        setLoading(false)
      }
    },
    [country, year],
  )
  

  React.useEffect(() => {
    getPaymingDate()
  }, [getPaymingDate])

  return (
    <>
      <div>
        <h2>Paying Members</h2>
        <Grid
          container
          alignItems="center"
          spacing={3}
          className={classes.container}
        >
          <Grid item>
            <div className={classes.monthFilter}>
              <IconButton
                className="arrow left"
                onClick={() => changeYear('previous')}
                size="small"
              >
                <KeyboardArrowLeft />
              </IconButton>
              <span>{year}</span>
              <IconButton onClick={() => changeYear('next')} size="small">
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.textField}
                onChange={e => setCountry(e.target.value)}
                style={{height: '40px', width: '165px'}}
                value={country}
              >
                {[
                  {key: 'SE', value: 'Swedan'},
                  {key: 'LU', value: 'Luxembourg'},
                ].map(item => {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="kpiContainer">
        <KPITable
          headerClassName="payingsTable"
          title="Paying members"
          labelsAndKeys={labelsAndKeys}
          data={data}
        />
        {isLoading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  )
}

export default PayingMembers
