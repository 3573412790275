import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { getOrganization, setOrganization } from '../../apis/invoice';
import { toast } from 'react-toastify';

interface IProps {
  isOpen: boolean;
  onClose():void;
}

const CompanyInfo: FC<IProps> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const name = Form.useWatch('name', form);
  const address = Form.useWatch('address', form);
  const phone = Form.useWatch('phone', form);
  const email = Form.useWatch('email', form);
  const website = Form.useWatch('website', form);
  const organizationNumber = Form.useWatch('organizationNumber', form);
  const free1 = Form.useWatch('free1', form);
  const free2 = Form.useWatch('free2', form);
  const free3 = Form.useWatch('free3', form);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      getOrganization().then((res) => {
        form.setFieldsValue(res.data);
      });
    }
  }, [isOpen, form]);

  const onFinish = async(data: any) => {
    setLoading(true)
    try {
        await setOrganization(data)
        toast.success('Save organization successfully!')
        form.resetFields()
        onClose();
    } catch (error) {
        toast.error('Cannot save organization!')
    } finally{
        setLoading(false)
    }
  }
  
  return (
    <Modal title="Company info" open={isOpen} width={1200} onOk={() => form.submit()} onCancel={onClose} confirmLoading={loading}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Company name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Company address" name="address">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Telephone number" name="phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="E-post" name="email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Website" name="website">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Organisationsnummer" name="organizationNumber">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Vår referens" name="myReference">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label="Free text column 1" name="free1">
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Free text column 2" name="free2">
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Free text column 3" name="free3">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <p>Preview:</p>
      <Row className="text-break p-no-m">
        <Col span={8}>
          <b>Adress</b>
          <p>{name}</p>
          <p>{address}</p>
          <p>{free1}</p>
        </Col>
        <Col span={8}>
          <b>Telefon</b>
          <p>{phone}</p>
          <p>E-post</p>
          <p>{email}</p>
          <p>{website}</p>
          <p>{free2}</p>
        </Col>
        <Col span={8}>
          <b>Organisationsnummer</b>
          <p>{organizationNumber}</p>
          {!!organizationNumber && <p>Godkänd för F-skatt</p>}
          <p>{free3}</p>
        </Col>
      </Row>
    </Modal>
  );
};

export default CompanyInfo;
