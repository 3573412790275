import {
  Box,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { addBanner, addOpenHours, editBanner, editOpenHours } from '../../apis/api';
import MyContext from '../../context/context';
import useToast from '../../hooks/useToast';

// Component Style
import classes from './BannerForm.module.css';
import { DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

function BannerForm({ bannerName, setOpen, selected }) {
  const context = useContext(MyContext);

  const [color, setColor] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { handleSubmit, errors, setValue, control } = useForm();
  const handleChange = (event) => {
    setColor(event.target.value);
  };

  const toast = useToast();

  React.useEffect(() => {
    setColor(bannerName === 'tBanner' ? '#E74C3C' : '#186A3B');
    if (selected && selected._id) {
      setValue('startDate', selected.startDate.slice(0, 10));
      setValue('endDate', selected.endDate.slice(0, 10));
      setValue('message', selected[bannerName === 'tBanner' ? 'message' : 'details']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = async (data) => {
    setIsSubmitting(true);
    const payload = {
      [bannerName === 'tBanner' ? 'message' : 'details']: data.message,
      color,
      startDate: data.startDate,
      endDate: data.endDate,
      permanent: false,
    };
    try {
      const response =
        bannerName === 'tBanner'
          ? await editBanner(selected._id, payload)
          : await editOpenHours(selected._id, payload);
      if (response.data && response.data.data && response.data.data) {
        toast('success', `Updated successfully`);
        setOpen(false);
      }
    } catch (error) {
      const errorsApi = error.response.data?.errors;
      const errorMessage = errorsApi
        ? Object.values(errorsApi).join('\n')
        : `Sorry, something went wrong`;
      setIsSubmitting(false);
      toast('warning', errorMessage);
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const payload = {
      studios: context.selectedStudios,
      [bannerName === 'tBanner' ? 'message' : 'details']: data.message,
      color,
      startDate: data.startDate,
      endDate: data.endDate,
      permanent: false,
    };
    try {
      const response =
        bannerName === 'tBanner' ? await addBanner(payload) : await addOpenHours(payload);
      if (response.data && response.data.success) {
        toast('success', `Created successfully`);
        setOpen(false);
      }
    } catch (err) {
      const errMess =
        err.response?.data?.message ||
        `Sorry you can not set multiple ${
          bannerName === 'tBanner' ? 'banner' : 'opening hours'
        } at the same time`;

      setIsSubmitting(false);
      toast('warning', errMess);
    }
  };

  return (
    <Box>
      <DialogTitle>
        <h1>{bannerName === 'tBanner' ? 'Temporary Banner' : 'Opening Hours'} </h1>
      </DialogTitle>
      <form onSubmit={handleSubmit(selected && selected._id ? onUpdate : onSubmit)}>
        <Grid
          container
          className={classes.bannerContainer}
          spacing={1}
          justifyContent="space-between"
        >
          {isSubmitting && <img className="loader" alt="loader" src="assets/images/loader.gif" />}
          <Grid item xs={6}>
            <Paper sx={{ padding: 8 }}>
              <Grid container gap={4}>
                <Grid item xs={6} sm={6}>
                  <Controller
                    control={control}
                    rules={{ required: true, maxLength: 500 }}
                    name="message"
                    render={({ field: { onChange, value } }) => (
                      <TextArea
                        onChange={onChange}
                        value={value}
                        rows={4}
                        placeholder={
                          bannerName === 'tBanner'
                            ? 'Temporary banner message'
                            : 'Opening hours details'
                        }
                      />
                    )}
                  />
                  {errors?.message && errors?.message?.type === 'required' && (
                    <span className={classes.error}>This field is required</span>
                  )}
                  {errors?.message && errors?.message?.type === 'maxLength' && (
                    <span className={classes.error}>Max length is 500 letters</span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <span
                      className={`${classes.colorInd} ${classes.color}`}
                      style={{ backgroundColor: color }}
                    ></span>
                    <InputLabel id="demo-simple-select-outlined-label">Color</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={color}
                      onChange={handleChange}
                      label="Select color"
                    >
                      <MenuItem value="#186A3B">
                        {' '}
                        <span
                          className={classes.color}
                          style={{ backgroundColor: '#186A3B' }}
                        ></span>
                        Green
                      </MenuItem>
                      <MenuItem value="#E74C3C">
                        {' '}
                        <span
                          className={classes.color}
                          style={{ backgroundColor: '#E74C3C' }}
                        ></span>
                        Red
                      </MenuItem>
                      <MenuItem value="#343a40">
                        <span
                          className={classes.color}
                          style={{ backgroundColor: '#343a40' }}
                        ></span>
                        Black
                      </MenuItem>
                      <MenuItem value="#BB8FCE">
                        <span
                          className={classes.color}
                          style={{ backgroundColor: '#BB8FCE' }}
                        ></span>
                        Purple
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Show between</span>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        value={selected._id && (value ? moment(value) : '') }
                        placeholder="From"
                        className={classes.ssContainer}
                      />
                    )}
                  />
                  {errors?.startDate && (
                    <span className={classes.error}>Start date is required</span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <Controller
                    control={control}
                    name="endDate"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        placeholder="To"
                        value={selected._id && (value ? moment(value) : '') }
                        className={classes.ssContainer}
                      />
                    )}
                  />

                  {errors?.endDate && <span className={classes.error}>End date is required</span>}
                </Grid>
                <button type="submit" disabled={isSubmitting} className={classes.btn} name="SAVE">
                  {selected && selected._id ? 'UPDATE' : 'PUBLISH'}
                </button>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={6} className={classes.imgPreview}>
            <img
              src={
                bannerName === 'tBanner'
                  ? 'assets/images/banner.png'
                  : 'assets/images/openingHours.png'
              }
              className={classes.preview}
              alt="Preview"
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default BannerForm;
