import React from 'react'
import {Grid} from '@mui/material'
import classes from '../../screens/Dashboard/Dashboard.module.css'
import StatsCard from './StatsCard'

const StatsCardsGrid = ({stats}) => {


  return (
    <Grid container spacing={3}>
      <StatsCard
        headerClass="newPaying"
        subheader="New Paying Users"
        percent={stats.payingUsers?.percent}
        totalNumber={stats.payingUsers?.currentMonthUsers}
        lastMonthNumber={stats.payingUsers?.lastMonthUsers}
        type="NewPaying"
      >
        <img
          src="/assets/icons/new-paying.svg"
          className={classes.avatar}
          alt="new"
        />
      </StatsCard>
      <StatsCard
        headerClass="churned"
        subheader="Churned Members"
        percent={
          Math.round((stats.churnedMembers?.percent + Number.EPSILON) * 100) /
            100 || 0
        }
        totalNumber={stats.churnedMembers?.currentMonthUsers}
        lastMonthNumber={stats.churnedMembers?.lastMonthUsers}
        type="ChurnedMembers"
      >
        <img
          src="/assets/icons/churned.svg"
          className={classes.avatar}
          alt="churned"
        />
      </StatsCard>
      <StatsCard
        headerClass="average"
        subheader="Average Visits Per Paying Members"
        totalNumber={stats.visitsPerPayingMembers?.currentMonthVisits}
        type="AvgVisits"
        lastMonthNumber={stats.visitsPerPayingMembers?.lastMonthVisits}
      >
        <img
          src="/assets/icons/average.svg"
          className={classes.avatar}
          alt="average"
        />
      </StatsCard>
      {/* users */}
      <StatsCard
        headerClass="total"
        subheader="Total Number Of Users"
        percent={stats.users?.percent}
        totalNumber={stats.users?.totalUsers}
        type="NumberOfUsers"
      >
        <img
          src="/assets/icons/total.svg"
          className={classes.avatar}
          alt="total"
        />
      </StatsCard>
    </Grid>
  )
}

export default StatsCardsGrid
