import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';

import { userIsAuthenticated, getAccessToken } from '../utils/local-storage';

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL_V2,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const apiV2 = axios.create(config);

apiV2.interceptors.request.use(
  (req) => {
    if (userIsAuthenticated()) {
      const accessToken = getAccessToken();
      if (req.headers) {
        req.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
    return req;
  },
  (error) => Promise.reject(error),
);

export default apiV2;

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: 'GET' | 'POST' | 'PUT' | 'DELETE';
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await apiV2({ url: baseUrl + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      if (typeof axiosError === 'string') {
        return {
          error: {
            data: axiosError,
          },
        };
      }

      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || (err.response?.data as any)?.title || err.message,
        },
      };
    }
  };
