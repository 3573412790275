import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

export default function KPITable({
  labelsAndKeys,
  data,
  title,
  headerClassName,
}) {
  return (
    <div className='customTable' style={{marginTop: '25px'}}>
      <TableContainer component={Paper}>
        <Table
          className={headerClassName}
          aria-label="simple table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell className="custom" variant="body">
                {title}
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                JAN
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                FEB
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                MAR
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                APR
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                MAY
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                JUN
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                JUL
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                AUG
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                SEP
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                OCT
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                NOV
              </TableCell>
              <TableCell className="custom" align="right" variant="body">
                DEC
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data[labelsAndKeys[0].key] &&
              labelsAndKeys &&
              labelsAndKeys.map(
                (row, index) =>
                  labelsAndKeys[index].key && (
                    <TableRow
                      key={labelsAndKeys[index].key}
                      className={labelsAndKeys[index].className}
                    >
                      <TableCell
                        style={{minWidth: '200px', fontSize: '13px'}}
                        component="th"
                        scope="row"
                        variant="body"
                      >
                        {labelsAndKeys[index].name}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['jan'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['jan']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['feb'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['feb']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['mar'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['mar']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['apr'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['apr']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['may'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['may']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['jun'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['jun']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['jul'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['jul']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['aug'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['aug']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['sep'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['sep']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['oct'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['oct']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['nov'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['nov']}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            data[labelsAndKeys[index].key]['dec'] < 0
                              ? 'red'
                              : '',
                        }}
                        align="right"
                        variant="body"
                      >
                        {data[labelsAndKeys[index].key]['dec']}
                      </TableCell>
                    </TableRow>
                  ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
