import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Categories from '../components/ListInvoices/Categories';
import InvoicesTable from '../components/ListInvoices';
import CompensationSummary from '../components/ListInvoices/compensationSummary';
import Visits from '../components/ListInvoices/visits';
import {
  getCategories,
  getInvoices,
  getPublishingAllInvoicesState,
  previewInvoice,
  publishAllInvoices,
} from '../apis/api';
import useToast from '../hooks/useToast';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import { exportExcelFormat } from '../utils/exportExcelFormJson';
import { INVOICE_HEADER_EXCEL, INVOICE_TOTAL_HEADER_EXCEL } from '../constants/common';
import {
  exportAllStudiosVisits,
  getInvoiceByStudioId,
  getInvoicePdfZipApi,
  manualSyncInvoiceApi,
} from '../apis/invoice';
import classes from './invoices.module.scss'; // Import css modules stylesheet as styles
import CustomizedDialog from '../components/Common/CustomizedDialog';
import moment from 'moment';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { setCategories } from '../services/invoice';
import CompanyInfo from '../components/ListInvoices/CompanyInfo';
import { Progress } from 'antd';
import StudiosNoVisits from '../components/ListInvoices/StudiosNoVisits';

const generateInvoicesStates = {
  CHECKING: 'CHECKING',
  GENERATING: 'GENERATING',
  NOT_GENERATING: 'NOT_GENERATING',
};

function ListStudioInvoices() {
  const [generateInvoicesState, setGenerateInvoicesState] = React.useState(
    generateInvoicesStates.CHECKING,
  );
  const toast = useToast();

  const publishInvoices = async () => {
    if (window.confirm('Are you sure you want to publish all invoices?')) {
      try {
        setGenerateInvoicesState(generateInvoicesStates.GENERATING);
        await publishAllInvoices();
      } catch (error) {
        toast('error', 'Error publishing invoices');
      } finally {
        setGenerateInvoicesState(generateInvoicesStates.NOT_GENERATING);
      }
    }
  };

  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [isVisitOpen, setIsVisitsOpen] = React.useState(false);
  const [selectedInvoice, setSelected] = React.useState();
  const [isExporting, setIsExporting] = React.useState(false);
  const [syncLoading, setSyncLoading] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [studiosInvoices, setStudiosInvoices] = useState([]);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [openCompanyInfo, setOpenCompanyInfo] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const dispatch = useAppDispatch();

  const getStudiosInvoicesData = useCallback(async () => {
    setLoading(true);
    const res = await getInvoices({ year, month });
    const data = res?.data;
    setStudiosInvoices(data);
    setLoading(false);
  }, [month, year]);

  const getData = useCallback(async () => {
    setLoading(true);
    const res = await getCategories({ year, month });
    const data = res?.data.data;
    dispatch(setCategories(data));
  }, [month, year, dispatch]);
  const onRefetchTable = getStudiosInvoicesData;
  const openVisitsAndSetData = (selectedItem) => {
    setIsVisitsOpen(true);
    setSelected(selectedItem);
  };

  useEffect(() => {
    getStudiosInvoicesData();
  }, [getStudiosInvoicesData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      const data = await exportAllStudiosVisits({ year, month });
      exportExcelFormat(INVOICE_HEADER_EXCEL, data, `studio-reporting-${month}-${year}.xlsx`);
    } catch (e) {
      if (e?.code === 'ERR_NETWORK') {
        toast(
          'error',
          'Server timeout because there are too many studios, try exporting one by one!',
        );
      } else {
        toast('error', "Couldn't export visits!");
      }
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  React.useEffect(() => {
    async function getGenerateInvoicesState() {
      const res = await getPublishingAllInvoicesState();
      let isLoading = res?.data.isPublishing || false;

      setGenerateInvoicesState(
        isLoading ? generateInvoicesStates.GENERATING : generateInvoicesStates.NOT_GENERATING,
      );
      return isLoading;
    }

    const intervalId = setInterval(async () => {
      let isGeneratingInvoices = await getGenerateInvoicesState();
      if (!isGeneratingInvoices) {
        setGenerateInvoicesState(generateInvoicesStates.NOT_GENERATING);
        clearInterval(intervalId);
      }
    }, 5 * 1000 /* 5 seconds */);
  }, []);

  const manualSyncInvoice = useCallback(async () => {
    setSyncLoading(true);
    try {
      const backMonth = moment().month() + 1 - month;
      await manualSyncInvoiceApi(backMonth);
      onRefetchTable();
    } catch (error) {
    } finally {
      setSyncLoading(false);
    }
  }, [month, onRefetchTable]);

  const exportSummaryToExcel = useCallback(() => {
    const excelData = studiosInvoices.map((i) => ({
      category: i.paymentCategory,
      studioName: i.studioName,
      visitsNumber: i.visitNumber,
      grossCompensation: i.grossPrice,
      vatPercent: i?.vatPercent || '',
      flaggedStudio: i?.specialDeals,
      bankgiro: i?.bankgiro || '',
      bankAccountNumber: i?.bankAccountNumber || '',
    }));
    exportExcelFormat(
      INVOICE_TOTAL_HEADER_EXCEL,
      excelData,
      `studio-reporting-total-${month}-${year}.xlsx`,
    );
  }, [studiosInvoices, month, year]);

  const onPreviewAll = useCallback(
    async () => {
      setLoading(true);
      try {
        const publicIds = [];
        setLoadingProgress(1);
        for (let index = 0; index < studiosInvoices.length; index++) {
          try {
            await getInvoiceByStudioId(studiosInvoices[index].studioId, month, year);
            const r = await previewInvoice(studiosInvoices[index].studioId, { month, year });

            publicIds.push(r.data.public_id);
            setLoadingProgress((publicIds.length / studiosInvoices.length) * 100);
          } catch (error) {
            console.error(error);
          }
        }
        const res = await getInvoicePdfZipApi(publicIds);
        window.open(res.data.url);
      } catch (error) {
        toast('error', "Couldn't export preview files!");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, JSON.stringify(studiosInvoices), year],
  );

  useEffect(() => {
    const lastSync = localStorage.getItem('sync-invoice-at');
    if(!lastSync || moment().diff(moment(lastSync), 'hours') > 1){
      console.count('asdasdsad')
      manualSyncInvoice()
      localStorage.setItem('sync-invoice-at', moment().toISOString())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignContent: 'flex-start',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <h2 style={{ margin: 0 }}>Studio Reporting Table</h2>
        {loading && (
          <div className="loader-container">
            {loadingProgress ? (
              <Progress
                type="line"
                style={{ position: 'fixed', top: 50, left: 0, right: 0 }}
                percent={Math.floor(loadingProgress)}
              />
            ) : null}
            <div className="loader"></div>
          </div>
        )}
        <Stack direction="row" spacing={2}>
          <Button
            className={classes.defaultBtn}
            variant="contained"
            onClick={() => setOpenCompanyInfo(true)}
            size="small"
            endIcon={<EditIcon />}
          >
            Swiftr Info
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={onPreviewAll}
            size="small"
            disabled={loading}
          >
            Preview all
          </Button>
          <LoadingButton
            loading={isExporting}
            variant="contained"
            className={classes.exportBtn}
            size="small"
            onClick={exportToExcel}
          >
            Export all
          </LoadingButton>
          <LoadingButton
            loading={isExporting}
            variant="contained"
            size="small"
            className={classes.exportBtn}
            onClick={exportSummaryToExcel}
          >
            Export
          </LoadingButton>
          <Button
            className={classes.defaultBtn}
            variant="contained"
            onClick={() => setOpenEditCategory(true)}
            size="small"
            endIcon={<EditIcon />}
          >
            Studio Categories
          </Button>
          {month === moment().month() + 1 && (
            <LoadingButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={classes.menuBtn}
              size="small"
              variant="contained"
              onClick={() => publishInvoices()}
              loading={generateInvoicesState !== generateInvoicesStates.NOT_GENERATING}
              loadingIndicator={
                generateInvoicesState === generateInvoicesStates.CHECKING
                  ? 'Checking state...'
                  : 'Generating...'
              }
            >
              Publish all invoice
            </LoadingButton>
          )}
        </Stack>
      </div>
      <div>
        Data is automatically synced once a day
        <LoadingButton
          loading={syncLoading}
          loadingIndicator="sync..."
          variant="outlined"
          onClick={manualSyncInvoice}
          className={classes.sync_now_btn}
        >
          Sync now
        </LoadingButton>
      </div>
      <InvoicesTable
        studiosInvoices={studiosInvoices}
        openVisits={openVisitsAndSetData}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
      />
      <StudiosNoVisits {...{ year, month }} />
      <CompensationSummary {...{ year, month }} />
      <CustomizedDialog
        open={isVisitOpen}
        handleClose={() => setIsVisitsOpen(false)}
        title={selectedInvoice?.studioName}
        size="lg"
      >
        <Visits
          selected={selectedInvoice}
          openVisits={setIsVisitsOpen}
          month={month}
          year={year}
          onRefetchTable={onRefetchTable}
        />
      </CustomizedDialog>

      <Categories
        open={openEditCategory}
        onClose={() => setOpenEditCategory(false)}
        year={year}
        month={month}
        onRefetchTable={onRefetchTable}
      />
      <CompanyInfo isOpen={openCompanyInfo} onClose={() => setOpenCompanyInfo(false)} />
    </React.Fragment>
  );
}

export default ListStudioInvoices;
