import React from 'react'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CampaignIcon from '@mui/icons-material/Campaign';
import FlagIcon from '@mui/icons-material/Flag';
import HandymanIcon from '@mui/icons-material/Handyman';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate, useLocation } from 'react-router-dom'
import { clsx } from 'clsx';

import MyContext from '../../context/context'

// Component Style
import classes from './ListMenu.module.css'

function ListMenu() {
  const context = React.useContext(MyContext)
  const { user } = context
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={classes.avatarC}>
        <div className={classes.logoContainer}>
          <img alt="logo" width="30" height="30" src="assets/images/user.png" />
        </div>
        <strong className={classes.name}>
          {user.firstName} {user.lastName}
        </strong>
      </div>
      <Divider />
      <List>
        <ListItem
          onClick={() => navigate('/dashboard')}
          className={clsx([classes.listItem, location.pathname === '/dashboard' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-dashboard ${location.pathname === '/dashboard' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/studios')}
          className={clsx([classes.listItem, location.pathname === '/studios' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-studio--managment ${location.pathname === '/studios' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Studio Managment" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/invoicing')}
          className={clsx([classes.listItem, location.pathname === '/invoicing' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-invoicing ${location.pathname === '/invoicing' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Invoicing" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/customers-kpis')}
          className={clsx([classes.listItem, location.pathname === '/customers-kpis' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-customer-kpis ${location.pathname === '/customers-kpis' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Customers kpis" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/user-missing-ssn')}
          className={clsx([classes.listItem, location.pathname === '/user-missing-ssn' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <SupervisorAccountIcon className={location.pathname === '/user-missing-ssn' ? classes.active : ''}/>
          </ListItemIcon>
          <ListItemText primary="Users Missing SSN" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/paying-members')}
          className={clsx([classes.listItem, location.pathname === '/paying-members' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-paying-members ${location.pathname === '/paying-members' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Paying Members" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/monthly-members')}
          className={clsx([classes.listItem, location.pathname === '/monthly-members' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-monthly-members ${location.pathname === '/monthly-members' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="Monthly Members" />
        </ListItem>

        <ListItem
          onClick={() => navigate('/new-feeds')}
          className={clsx([classes.listItem, location.pathname === '/new-feeds' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-news-feed ${location.pathname === '/new-feeds' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="News Feed" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/external/status')}
          className={clsx([classes.listItem, location.pathname === '/external/status' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <i
              className={`icon-news-feed ${location.pathname === '/external/status' ? classes.active : ''
                }`}
            ></i>
          </ListItemIcon>
          <ListItemText primary="External booking status" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/campaigns')}
          className={clsx([classes.listItem, location.pathname === '/campaigns' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <CampaignIcon className={location.pathname === '/campaigns' ? classes.active : ''}/>
          </ListItemIcon>
          <ListItemText primary="Campaigns" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/banners')}
          className={clsx([classes.listItem, location.pathname === '/banners' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <FlagIcon className={location.pathname === '/banners' ? classes.active : ''}/>
          </ListItemIcon>
          <ListItemText primary="Banners" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/scripts')}
          className={clsx([classes.listItem, location.pathname === '/scripts' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <HandymanIcon className={location.pathname === '/scripts' ? classes.active : ''}/>
          </ListItemIcon>
          <ListItemText primary="Scripts" />
        </ListItem>
        <ListItem
          onClick={() => navigate('/zipCodes')}
          className={clsx([classes.listItem, location.pathname === '/zipCodes' ? classes.active : ''])}
        >
          <ListItemIcon className={classes.icons}>
            <CropFreeIcon className={location.pathname === '/zipCodes' ? classes.active : ''}/>
          </ListItemIcon>
          <ListItemText primary="ZipCodes" />
        </ListItem>
      </List>
    </React.Fragment>
  )
}

export default ListMenu
