import { Form, Input, Col, Row, Select, Radio, Button, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { INewsfeed } from '../../types/newsfeed';

import { UploadOutlined } from '@ant-design/icons';
import React, { useMemo, memo, useState, useEffect } from 'react';
import {
  getCities,
  addFeedWithImage,
  addFeedWithVideo,
  updateFeedWithImage,
  updateFeedWithVideo,
} from '../../apis/api';
import MapForm from './MapForm';
import useToast from '../../hooks/useToast';
import classes from './NewsFeedForm.module.css';
import isEmpty from 'lodash/isEmpty';
import CustomizedDialog from '../Common/CustomizedDialog';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import FormItem from 'antd/es/form/FormItem';
import { zipApi } from '../../apis/zip';
moment.tz.setDefault('Europe/Stockholm');

const NewsFeedForm = memo(({ getData, setOpen, selected, open }: any) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [coordinates, setCoordinates] = React.useState([]);
  const [schedule, setSchedule] = useState();
  const [city, setCity] = React.useState('');
  const [type, setType] = React.useState('');
  const [typeText, setTextType] = React.useState('');
  const [typeValue, setTypeValue] = React.useState<string>('');
  const [value, setValue] = React.useState('all');
  const [paying, setPaying] = React.useState('all');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [cities, setCities] = React.useState([]);
  const [isMap, changeIsMap] = React.useState(false);
  const toast = useToast();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const types = [
    {
      value: 'general',
      name: 'General',
    },
    {
      value: 'StudioInvitation',
      name: 'New Studio',
    },
  ];

  const { data: zipZones } = zipApi.useGetZipZonesQuery(undefined);
  const { data: zipGroups } = zipApi.useGetZipsQuery(undefined);
  React.useEffect(() => {
    setTextType(getProperText(type) as string);
  }, [type]);

  const watchMedia = Form.useWatch('media', form);
  const watchedCountry = Form.useWatch('country', form);

  React.useEffect(() => {
    if (watchedCountry === 'LU') {
      setCity('');
    }
  }, [watchedCountry]);

  useEffect(() => {
    if (open && selected._id) {
      form.setFieldsValue({
        ...selected,
        schedule: moment(selected?.schedule?.time || selected?.schedule), 
        action: selected.someURL,
      });
    }
    if(!open){
      setSchedule(undefined);
    }
  }, [form, open, selected, selected._id]);

  useEffect(() => {
    const getCitiesData = async () => {
      const res = await getCities();
      setCities(res?.data);
    };
    getCitiesData();
  }, []);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onSuccess = () => {
    form.resetFields();
    setFileList([]);
    setOpen(false);
  };

  const onSubmit = async (data: INewsfeed) => {
    setIsSubmitting(true);
    const formData = new FormData();
    if (schedule) {
      formData.append('schedule', moment(schedule).toISOString());
    }
    if (data && data.media) {
      if (typeof data.media === 'string') {
        formData.append('media', data.media);
      } else {
        fileList.forEach((file) => {
          formData.append('media', file as RcFile);
        });
      }
    }
    formData.append('description', data.description);
    formData.append('text', data.text);
    formData.append('title', data.title);
    if (data.zip) {
      formData.append('zip', data.zip || '');
    }
    if(data.zipGroup){
      formData.append('zipGroup', data.zipGroup || '');
    }
    if (value === 'all') {
      formData.append('forall', 'true');
    }
    if (paying === 'true' || paying === 'false') formData.append('paying', paying);
    if (data['user']) formData.append('user', data['user'].trim());
    if (value === 'specific') {
      if (data['country'] && coordinates.length === 0) {
        if (!city) formData.append('country', data['country']);
        if (city && coordinates.length === 0) formData.append('city', city);
      } else if (coordinates[0]) {
        formData.append('polygonType', 'Polygon');
        coordinates.forEach((point) => formData.append('polygonCoordinates', point));
      }
    }
    if (data.newsType !== 'general' && data.newsType !== null)
      formData.append('type', data.newsType!);
    if (data.landing) formData.append(data.landing, typeValue);
    try {
      if (data && data.media && data.media.file && data.media.file.type.includes('image')) {
        if (open && selected._id) {
          formData.append('_id', selected._id);
          const res = await updateFeedWithImage(formData);
          if (res.status === 200) {
            await getData();
            toast('success', 'Record has been updated successfully');
            setOpen(false);
          }
        } else {
          const res = await addFeedWithImage(formData);
          if (res.status === 200 || res.status === 204) {
            await getData();
            toast('success', 'Record has been added successfully');
            setOpen(false);
          }
        }
      } else if (data && data.media && data.media.file && data.media.file.type.includes('video')) {
        if (open && selected._id) {
          formData.append('_id', selected._id);
          const res = await updateFeedWithVideo(formData);
          if (res.status === 200) {
            await getData();
            toast('success', 'Record has been updated successfully');
            setOpen(false);
          }
        } else {
          const res = await addFeedWithVideo(formData);
          if (res.status === 200) {
            await getData();
            toast('success', 'Record has been added successfully');
            setOpen(false);
          }
        }
      } else {
        if (open && selected._id) {
          formData.append('_id', selected._id);
          const res = await updateFeedWithImage(formData);
          if (res.status === 200) {
            await getData();
            toast('success', 'Record has been updated successfully');
            setOpen(false);
          }
        } else {
          const res = await addFeedWithImage(formData);
          if (res.status === 200) {
            await getData();
            toast('success', 'Record has been added successfully');
            setOpen(false);
          }
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);
      let message = error?.response?.data?.message || 'Something went wrong try reloading the page';
      message = message.includes('MongoObjectId') ? 'Class Id/ Studio Id is not invalid' : message;
      toast('error', message);
    } finally {
      setIsSubmitting(false);
      onSuccess();
    }
  };

  const getProperText = (type: any) => {
    switch (type) {
      case 'classOccasion':
        return 'Class Id';
      case 'someURL':
        return 'URL';
      case 'studio':
        return 'Studio Id';
    }
  };

  const changeToMap = () => {
    changeIsMap(true);
  };

  const changeToList = () => {
    changeIsMap(false);
  };

  const disableByEdit = useMemo(() => {
    return !selected || !isEmpty(selected);
  }, [selected]);

  return (
    <CustomizedDialog
      size="lg"
      open={open}
      handleClose={onSuccess}
      title="Create new feed"
      showFooter
      saveTitle={selected && selected.title ? 'Update' : 'PUBLISH'}
      handleSave={() => form.submit()}
      buttonLoading={isSubmitting}
    >
      <Form layout="vertical" style={{ width: '50vw' }} onFinish={onSubmit} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Title"
              rules={[{ required: true, message: 'Tilte is required !' }]}
              name="title"
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Push notification text"
              rules={[{ required: true, message: 'Push notification text is required !' }]}
              name="text"
            >
              <Input
                placeholder="Push notification text"
                disabled={!!selected && !!selected.title}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Description is required !' }]}
        >
          <TextArea rows={4} placeholder="Description" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Select landing action" name="landing">
              <Select
                placeholder="Select landing action"
                value={type}
                onChange={(e) => setType(e)}
                disabled={!!selected && !!selected.title}
              >
                <Select.Option value="">
                  <em>Not set</em>
                </Select.Option>
                <Select.Option value="someURL">Url</Select.Option>
                <Select.Option value="classOccasion">Class Id</Select.Option>
                <Select.Option value="studio">Studio Id</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Action target"
              name="action"
              rules={[{ required: type !== '', message: 'Action target is required!' }]}
            >
              <Input
                placeholder="Action target"
                disabled={!typeText || !!(selected && selected.title)}
                value={typeValue}
                onChange={(e) => setTypeValue(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Select Type" name="newsType" initialValue={null}>
              <Select disabled={!!selected && !!selected.title} placeholder={'Select Type'}>
                {types.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="media" label="Upload image or video">
              <Upload disabled={!!selected && !!selected.title} {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
                {watchMedia && selected && selected.media ? (
                  <img className={classes.image} src={selected.media} alt="media preview" />
                ) : (
                  <span style={{ marginLeft: '15px' }}>Upload image or video</span>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Schedule" name="schedule">
              <DatePicker
                style={{ width: '100%' }}
                disabled={!!selected && !!selected.title && selected?.schedule?.sent === true}
                value={schedule}
                onChange={(v: any) => {
                  setSchedule(v);
                }}
                showTime
                showSecond={false}
                disabledDate={(date) => date.isBefore(moment().subtract(1, 'day'))}
                popupStyle={{ zIndex: 1301 }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Zip City" name="zip">
              <Select placeholder="ZipZones" mode="multiple">
                {zipZones?.data.map((i) => (
                  <Select.Option value={i} label={i} key={i}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Zip Groups" name="zipGroup">
              <Select
                options={zipGroups?.data}
                fieldNames={{ label: 'region', value: '_id' }}
                placeholder="Zip Groups"
              />
            </Form.Item>
          </Col>
        </Row>

        <Col span={32}>
          <Form.Item>
            <Radio.Group
              style={{ width: '100%' }}
              name="userCase"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Radio value="all">Send to all users</Radio>
                </Col>
                <Col span={8}>
                  <Radio value="specific" disabled={disableByEdit}>
                    Select user group
                  </Radio>
                </Col>
                <Col span={8}>
                  <Radio value="specific-user" disabled={disableByEdit}>
                    Select specific user
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Row>
          {value === 'specific-user' && (
            <Col span={8}>
              <FormItem label="Send for specific user (ID)" name="user">
                <Input placeholder="Send for specific user (ID)" />
              </FormItem>
            </Col>
          )}

          {value === 'specific' && (
            <>
              {!isMap && (
                <>
                  <Col span={24}>
                    <Form.Item>
                      <Radio.Group
                        name="paying"
                        value={paying}
                        onChange={(e) => setPaying(e.target.value)}
                        style={{ width: '100%' }}
                      >
                        <Row>
                          <Col span={8}>
                            <Radio value="all">Send to all</Radio>
                          </Col>
                          <Col span={8}>
                            <Radio value="true">Send to Paying users.</Radio>
                          </Col>
                          <Col span={8}>
                            <Radio value="false">Send to Non Paying users.</Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {!isMap && (
                    <Row style={{ width: '100%' }}>
                      <Col span={8} style={{ paddingRight: '15px' }}>
                        <Form.Item label="Select Country" name="country">
                          <Select placeholder="Select Country" value="city">
                            <Select.Option value="">
                              <em>Not set</em>
                            </Select.Option>
                            <Select.Option value="Sweden">Sweden</Select.Option>
                            {/* <Select.Option value="Luxembourg">Luxembourg</Select.Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Select City" name="city">
                          <Select
                            placeholder="Select City"
                            value={city}
                            onChange={(e) => setCity(e)}
                            disabled={watchedCountry === 'LU' || !watchedCountry || disableByEdit}
                          >
                            <Select.Option value="">
                              <em>Not set</em>
                            </Select.Option>
                            {cities.map((item, index) => {
                              return (
                                <Select.Option key={`city_${index}`} value={item}>
                                  {item}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ paddingTop: '35px', paddingLeft: '30px' }}>
                        <a
                          className={classes.link}
                          {...(!disableByEdit && { onClick: changeToMap })}
                        >
                          Send to a specific area
                        </a>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {isMap && (
                <Row style={{ width: '100%' }}>
                  <Col>
                    <a className={classes.link} {...(!disableByEdit && { onClick: changeToList })}>
                      Send to a specific country or city
                    </a>
                  </Col>
                  <Col span={24} style={{ width: '100%' }}>
                    <MapForm
                      setCoordinates={setCoordinates}
                      selected={selected && selected.title}
                      coordinates={coordinates}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Row>
      </Form>
    </CustomizedDialog>
  );
});

export default NewsFeedForm;
