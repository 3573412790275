import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  PropTypes,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  getExternalProviderApi,
  getExternalStatusApi,
  getExternalStudioApi,
} from '../../apis/external';
import { IExternalBooking, IIntegrations } from '../../types/external';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Select, Tabs, TabsProps } from 'antd';
// import api from
interface IItemStatusObject {
  status:
    | 'booked'
    | 'pending'
    | 'pending-cancel'
    | 'aborted'
    | 'archived'
    | 'waiting'
    | 'checked-in';
  tooltip: string;
  color: Exclude<PropTypes.Color, 'inherit'>;
}
const ExternalStatus = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<IExternalBooking[]>([]);
  const [provider, setProvider] = useState<string[]>([]);
  const [studio, setStudio] = useState<IIntegrations[]>([]);
  const [studioId, setStudioId] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  

  const [pagination, setPagination] = useState({
    page: 1,
    total: total,
  });
  const [itemPerPage, setItemPerPage] = useState<number>(20);

  const [viewIssue, setViewIssue] = useState<string>('');
  const [system, setSystem] = useState<string>('gymsystem');

  const getStatus = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getExternalStatusApi(pagination.page, system, itemPerPage, studioId);
      setBookings(data.data);
      setTotal(data.total)
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [pagination.page, system, itemPerPage, studioId]);

  const getProvider = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getExternalProviderApi();
      setProvider(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getStudio = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getExternalStudioApi();
      setStudio(data.response.integrations);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);


  useEffect(() => {
    getProvider();
  }, [getProvider]);

  useEffect(() => {
    getStudio();
  }, [getStudio]);

  useEffect(() => {
   setStudioId('')
  }, [system]);

  const studioOption = studio
  ?.filter((j) => j.provider === system)
  ?.map((i: IIntegrations) => ({ value: i.studio, label: i.studio_name }))

  const getItemStatus = (booking: IExternalBooking): IItemStatusObject => {
    if (booking.completed_check_in) {
      return { color: 'primary', status: 'checked-in', tooltip: '' }; // completed
    }
    if (booking.completed_external_booking) {
      return { color: 'primary', status: 'booked', tooltip: 'Completed sync booking' }; // completed
    }
    if (booking.aborted) {
      return {
        color: 'secondary',
        status: 'aborted',
        tooltip: 'Too many failed attempts = aborted booking',
      }; // Too many failed attempts = aborted booking
    }
    if (booking.archived) {
      return {
        color: 'secondary',
        status: 'archived',
        tooltip: 'Completed cancellation in external system',
      }; //Archived booking = completed cancellation in external system
    }
    if (booking.pending_booking) {
      return { color: 'primary', status: 'pending', tooltip: '' }; // pending
    }

    if (booking.pending_cancellation) {
      return { color: 'secondary', status: 'pending-cancel', tooltip: '' }; // pending cancel
    }
    return { color: 'primary', status: 'waiting', tooltip: '' };
  };

  const columns = useMemo(
    () => [
      {
        name: 'User name',
        selector: (row: IExternalBooking) => row.userFirstName + ' ' + row.userLastName,
      },
      {
        name: 'Studio',
        selector: (row: IExternalBooking) =>
          studio?.find((i: IIntegrations) => i.studio === row.studio)?.studio_name,
      },
      {
        name: 'Status',
        cell: (row: IExternalBooking) => {
          const status = getItemStatus(row);
          return (
            <Tooltip title={status.tooltip}>
              <Chip
                variant="outlined"
                size="small"
                color={status.color}
                label={status.status}
              ></Chip>
            </Tooltip>
          );
        },
      },
      {
        name: 'Created date',
        selector: (row: IExternalBooking) => moment(row.created).format('lll'),
      },
      {
        name: 'Issues',
        cell: (row: IExternalBooking) => (
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Tooltip title={row?.issues[0]?.message || ''}>
              <p>{row?.issues[0]?.message.slice(0, 20) || ''}</p>
            </Tooltip>
            {row?.issues[0]?.message && (
              <IconButton onClick={() => setViewIssue(row._id)}>
                <MoreHorizIcon />
              </IconButton>
            )}
          </Grid>
        ),
      },
      {
        name: 'User ID',
        cell: (row: IExternalBooking) => (
          <b
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_ADMIN}/user/month-summary/${row.user}`,
                '_blank',
              );
            }}
          >
            {row.user}
          </b>
        ),
      },
      {
        name: 'Studio ID',
        selector: (row: IExternalBooking) => row.studio,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(studio)],
  );
  const onChange = (key: string) => {
    setSystem(provider[Number(key)]);
  };

  const items: TabsProps['items'] = provider.map((i, index) => ({
    key: `${index}`,
    label: i.charAt(0).toLocaleUpperCase() + i.slice(1),
  }));
  

  return (
    <div className="external_status_table">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      <div style={{float: 'right', marginRight:'30px', marginBottom:'10px'}}>
        <Select
          placeholder="Select studio"
          style={{ width: 220 }}
          onChange={(e) => setStudioId(e)}
          value={
            studioOption.find((i) => i.value === studioId)?.label ||
            studioOption.find((i) => i.value === studioId)?.value
          }
          options={studioOption}
        />
      </div>
      <DataTable
        columns={columns as any}
        data={bookings}
        progressPending={loading}
        pagination={total > itemPerPage ? true : false}
        paginationServer
        paginationTotalRows={total}
        paginationPerPage={itemPerPage}
        onChangeRowsPerPage={(e) => setItemPerPage(e)}
        onChangePage={(page) => {
          setPagination((pre) => ({ ...pre, page }));
        }}
      />
      <Dialog open={!!viewIssue} onClose={() => setViewIssue('')}>
        <DialogTitle>Booking issues</DialogTitle>
        <DialogContent>
          <Grid container wrap="nowrap" direction="column" spacing={2}>
            {bookings
              .find((i) => i._id === viewIssue)
              ?.issues.map((i) => (
                <Grid item xs={12}>
                  <Paper style={{ padding: '8px' }}>
                    {' '}
                    <Tooltip title={i.message || ''}>
                      <p>{i.message.slice(0, 200) || ''}</p>
                    </Tooltip>{' '}
                    {moment(i.date).format('lll')}
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExternalStatus;
