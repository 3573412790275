import React, {useState} from 'react'
import {FormControl, Grid, MenuItem, Select} from '@mui/material'
import useToast from '../../hooks/useToast'
import KPITable from '../Common/KPITable'
import {getCities, getKpisCities} from '../../apis/api'
import classes from './Kpi.module.css'

const KpisCities = ({reformatData, labelsAndKeys}) => {
  const [data, setData] = React.useState([])
  const toast = useToast()
  const [city, setCity] = React.useState('Stockholm')
  const [isLoading, setLoading] = React.useState(false)
  const [cities, setCities] = useState(['Stockholm'])

  const getGenderData = async () => {
    setLoading(true)
    const res = await getKpisCities({city})
    const formatedData = reformatData(res?.data.data)
    setData(formatedData)
    setLoading(false)
  }

  React.useEffect(() => {
    getGenderData()
    getAllCities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city])

  const getAllCities = async () => {
    try {
      const res = await getCities()
      setCities(res?.data)
    } catch (error) {
      toast('error', 'Error fetching cities')
    }
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={4} md={2}>
          Select City
        </Grid>
        <Grid item xs={4} md={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.textField}
              onChange={e => setCity(e.target.value)}
              value={city}
            >
              {cities.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="kpiContainer">
        <KPITable
          headerClassName="kpiCity"
          title={city}
          labelsAndKeys={labelsAndKeys}
          data={data}
        />
        {isLoading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  )
}

export default KpisCities
