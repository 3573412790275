import axios from "axios"
import { IAddCampaignReq, IGetAllCampaignRes } from "../types/campaign"
import { getAccessToken } from "../utils/local-storage"
import api from "./api"

export const getPlans = async () => {
  const accessToken = getAccessToken();
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/pricing-plans?light=1`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  return res.data
}
export const getAllCampaignsApi = async (page: number, limit: number = 10): Promise<IGetAllCampaignRes> => {
  const res = await api.get(`/campaigns?page=${page}&limit=${limit}`,)
  return res.data
}

export const createCampaignApi = async (data: IAddCampaignReq) =>{
  return await api.post(`/campaigns`, data)
}

export const deleteCampaignApi = async (id:string) => {
  return await api.delete(`/campaigns/${id}`)
}


export const editCampaignApi = async (id:string, data: IAddCampaignReq) => {
  return await api.put(`/campaigns/${id}`, data)
}
