const CryptoJS = require('crypto-js')
// const { Buffer } = require('buffer');
// const algorithm = 'aes-256-ctr'
const secretKey = process.env.REACT_APP_SECRET_KEY
// const iv = Buffer.from(process.env.REACT_APP_INITIALIZATION_VECTOR, 'hex')

// export function encrypt(rawText) {
//   const cipher = crypto.createCipheriv(algorithm, secretKey, iv)
//   const encrypted = Buffer.concat([cipher.update(rawText), cipher.final()])
//   return encrypted.toString('hex')
// }

// export function decrypt(encryptedText) {
//   const decipher = crypto.createDecipheriv(
//     algorithm,
//     secretKey,
//     Buffer.from(iv, 'hex'),
//   )
//   const decrpyted = Buffer.concat([
//     decipher.update(Buffer.from(encryptedText, 'hex')),
//     decipher.final(),
//   ])
//   return decrpyted.toString()
// }
export function encrypt(rawText) {
  return CryptoJS.AES.encrypt(rawText, secretKey);
}

export function decrypt(encryptedText) {
  const result = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(CryptoJS.enc.Utf8);
  return result
}