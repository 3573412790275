export const MONTH_OBJ = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
}

export const INVOICE_HEADER_EXCEL = [
  {
    key: 'studioName',
    label: 'Studio name',
  },
  {
    key: 'checkInId',
    label: 'Visit id',
  },
  {
    key: 'grossCompensation',
    label: 'Gross compensation',
  },

  {
    key: 'paymentCategory',
    label: 'Category',
  },
  {
    key: 'vatPercent',
    label: 'VAT %',
  },
  {
    key: 'vat',
    label: 'VAT',
  },
  {
    key: 'netCompensation',
    label: 'Net compensation',
  },
  {
    key: 'fraud',
    label: 'Potential fraud',
  },
  {
    key: 'excluded',
    label: 'Exclude visit from report',
  },
  {
    key: 'emailUserCheckIn',
    label: 'Email address',
  },
  {
    key: 'checkInDeviceName',
    label: 'Device',
  },
  {
    key: 'checkInAtClassName',
    label: 'Checkin at class type',
  },

  {
    key: 'checkInDate',
    label: 'CheckIn date',
  },
  {
    key: 'checkInHour',
    label: 'CheckIn time',
  },
  {
    key: 'checkedDay',
    label: 'Weekday',
  },
]

export const INVOICE_TOTAL_HEADER_EXCEL = [
  {
    key: 'category',
    label: 'Category',
  },
  {
    key: 'studioName',
    label: 'Studio name',
  },
  {
    key: 'visitsNumber',
    label: 'Visits Number',
  },
  {
    key: 'grossCompensation',
    label: 'Gross compensation',
  },
  {
    key: 'vatPercent',
    label: 'VAT %',
  },
  // {
  //   key: 'commentOnPdf',
  //   label: 'Comment on PDF',
  // },
  // {
  //   key: 'additionalCompensation',
  //   label: 'Additional Compensation',
  // },
  {
    key: 'flaggedStudio',
    label: 'FLagged Studio',
  },
  {
    key: 'bankgiro',
    label: 'Bankgiro',
  },
  {
    key: 'bankAccountNumber',
    label: 'Bank Details',
  },
  // {
  //   key: 'published',
  //   label: 'Published',
  // },
  // {
  //   key: 'isDone',
  //   label: 'Done Editing',
  // },

  // {
  //   key: 'pdf',
  //   label: 'PDF preview',
  // },
]
export const CUSTOMER_KPI_KEYS = [
  {
    key: 'firstName',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {key: 'memberId', label: 'Member Id'},
  {key: 'socialSecurityNumber', label: 'Social Security number'},
  {key: 'churnDate', label: 'Churn Date'},
  {key: 'plan', label: 'Plan'},
  {key: 'userStatus', label: 'User status'},
  {key: 'membershipStatus', label: 'Membership status'},
  {key: 'paymentStatus', label: 'Payment status'},
  {key: 'membershipPeriod', label: 'Membership period'},
  {key: 'paymentSourceCard', label: 'Payment card number'},
  {key: 'paymentSourceType', label: 'Payment card Type'},
  {key: 'activeUntil', label: 'Active until'},
  {key: 'currency', label: 'Currency'},
  {key: 'country', label: 'Country'},
  {key: 'city', label: 'City'},
  {key: 'gender', label: 'Gender'},
  {key: 'birthdate', label: 'Year of Birth'},
]

export const STUDIOS_EXCEL = [
  'Studio Name',
  'Studio id',
  'City name',
  'Email address',
  'Phone number',
  'Hidden',
  'Live on App',
  'Churn Date',
  'Schedule Integrated',
  'Schedule',
  'Studio Manager',
  'Banner info displayed',
  'Banner information start date',
  'Banner information end date',
  'Opening hours info displayed',
  'Opening hours information start date',
  'Opening hours information end date',
  'Latest internal note',
]
