import React from 'react'
import {GoogleMap, LoadScript, DrawingManager} from '@react-google-maps/api'
import { GOOGLE_MAPS_API_KEY } from '../../constants/config'

const containerStyle = {
  width: '100%',
  height: '400px',
}

const center = {
  lat: 58.298584,
  lng: 12.961619,
}

const type = ['drawing']

const MapForm = ({setCoordinates, coordinates, selected}) => {
  // const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter(new window.google.maps.LatLng(center.lat, center.lng), 10)
    if (coordinates && coordinates.length) {
      var newC = coordinates[0]
        .slice(0, coordinates[0].length - 1)
        .map(item => {
          return {lng: item[0], lat: item[1]}
        })
      setTimeout(() => {
        ShowPolygon(newC, map)
      }, 1500)
    }
    // setMap(map)
  }, [coordinates])

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null)
  }, [])
  const [isDrawing, setDrawing] = React.useState(false)
  const [currentPolygon, setCurrent] = React.useState(null)
  const onPolygonLoad = drawingManager => {}

  const ShowPolygon = (data, sap) => {
    const updated = new window.google.maps.Polygon({
      paths: data,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    })

    updated.setMap(sap)
  }

  const handleClick = () => {
    setDrawing(!isDrawing)
    if (currentPolygon) {
      currentPolygon.setMap(null)
      setCurrent(null)
    }
  }

  const onPolygonComplete = polygon => {
    setCurrent(polygon)
    const vertices = polygon.getPath()
    const tempCoordinates = []
    for (let i = 0; i < vertices.getLength(); i++) {
      const xy = vertices.getAt(i)
      console.log(xy.lng(), xy.lat())
      tempCoordinates.push([xy.lng(), xy.lat()])
    }

    setTimeout(() => {
      setCoordinates(tempCoordinates)
    }, 100)
  }

  return (
    <div className="mapContainer">
      {selected ? (
        ''
      ) : (
        <button type="button" onClick={handleClick}>
          Click to start drawing
        </button>
      )}
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        libraries={type}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <DrawingManager
            onLoad={onPolygonLoad}
            onPolygonComplete={onPolygonComplete}
            drawingMode={!isDrawing ? null : 'polygon'}
            options={{drawingControl: false}}
          />
          <></>
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default MapForm
