import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import React, {useCallback, useState} from 'react'
import {getUserTypes} from '../../apis/api'
import classes from './Style.module.css'
const headCells = [
  {label: 'User training access status'},
  {label: 'Number Of Visits'},
  {
    label: 'Net compensation',
  },
  {
    label: 'Amount Excl VAT',
  },
  {
    label: 'VAT',
  },
  {
    label: 'Share',
  },
]

function EnhancedTableHead(props) {
  const {classes} = props

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell className="th" key={headCell.label} variant="body">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function CompensationSummary({year, month}) {

  const [rows, setRows] = useState([])
  const getData = useCallback(async () => {
    const res = await getUserTypes({year, month})
    const data = res?.data.data
    setRows(data)
  }, [month, year])

  React.useEffect(() => {
    getData()
  }, [getData])

  return (
    <div className={classes.root}>
      <h1>Compensation Summary</h1>
      <Paper className={`${classes.paper}`}>
        <TableContainer>
          <Table
            id="table"
            stickyHeader={true}
            className={`compensationTable`}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              // rowCount={rows.length}
            />
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow hover key={row.type}>
                    <TableCell className="td" align="left" variant="body">
                      {row.type}
                    </TableCell>
                    <TableCell className="td" align="left" variant="body">
                      {row.visits}
                    </TableCell>
                    <TableCell className="td" align="left" variant="body">
                      {row.netCompensation}
                    </TableCell>
                    <TableCell className="td" align="left" variant="body">
                      {row.grossCompensation}
                    </TableCell>
                    <TableCell className="td" align="left" variant="body">
                      {row.vat}
                    </TableCell>
                    <TableCell className="td" align="left" variant="body">
                      {row.visitsPercent.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
