import { IBanners } from './../types/banner';
import apiV2 from './api-v2';

export const getAllBannerApi = async (): Promise<IBanners[]> => {
  const res = await apiV2.get(`/banners/all`);
  return res.data
};


export const createBannerApi = async (data: IBanners): Promise<IBanners[]> => {
  return await apiV2.post(`/banners`, data);
};

export const deleteBannerApi = async (id:string) => {
  return await apiV2.delete(`/banners/${id}`)
}

export const editBannerApi = async (id:string, data: IBanners) => {
  return await apiV2.put(`/banners/${id}`, data)
}
