import React, { FC } from "react";
import { TextField } from "@mui/material";
import { Control, FieldValues, RegisterOptions, useController } from "react-hook-form";

interface IProps {
  control: Control<FieldValues, any>, name: string,
  rules: RegisterOptions,
  label: string,
  type:  React.InputHTMLAttributes<unknown>['type'],
  id?: string;
}
const Input: FC<IProps> = ({ control, name, rules, label, type, id, ...props }) => {
  const {
    field: { onChange, onBlur, name: nameField, value, ref },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: "",
  });

  return (
    <TextField
    id={id}
      onChange={onChange} // send value to hook form 
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={nameField} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      variant="outlined"
      label={label}
      type={type}
      fullWidth
      {...props}
    />
  );
}

export default Input