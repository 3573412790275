import {
  IGetAccessStatusMonthChartRes,
  IGetAccessStatusUserChartRes,
  IGetOrganicUserChartRes,
  IGetOrganicUserMonthChartRes,
} from '../types/dashboard'
import api from './api'

export const getOrganicUserChartApi =
  async (): Promise<IGetOrganicUserChartRes> => {
    const res = await api.get('/stats/organic')
    return res.data
  }

export const getOrganicUserMonthChartApi =
  async (): Promise<IGetOrganicUserMonthChartRes> => {
    const res = await api.get('/stats/organic-month')
    return res.data
  }

export const getAccessStatusUserChartApi =
  async (): Promise<IGetAccessStatusUserChartRes> => {
    const res = await api.get('/stats/accessStatus')
    return res.data
  }

  
export const getAccessStatusMonthChartApi =
  async (): Promise<IGetAccessStatusMonthChartRes> => {
    const res = await api.get('/stats/accessStatus-month')
    return res.data
  }
