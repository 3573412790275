import * as XLSX from 'xlsx'
const exportExcelFormJson = ({
  apiData,
  fileName,
  header,
}: {
  apiData: any
  fileName: string
  header: string[]
}) => {
  const Heading = [header]

  const wb = XLSX.utils.book_new()
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
  XLSX.utils.sheet_add_aoa(ws, Heading)

  XLSX.utils.sheet_add_json(ws, apiData, {origin: 'A2', skipHeader: true})

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  return XLSX.writeFile(wb, fileName)
}

const exportExcelFormat = (
  template: Array<{key: string; label: string}>,
  dataRaw: any,
  fileName: string
) => {
  const orderForExcel = dataRaw.map((item: any) => {
    return template.reduce((pre: any, cur) => {
      pre[cur.key] =
        item[cur.key] !== false && !item[cur.key] ? '' : String(item[cur.key])
      return pre
    }, {})
  })
  exportExcelFormJson({
    apiData: orderForExcel,
    fileName: fileName,
    header: template.map(i => i.label),
  })
}

export default exportExcelFormJson

export {exportExcelFormat}
