import React from 'react'

// Material-UI
import {Paper, Typography} from '@mui/material'

// Charts
import {
  Chart,
  PieSeries,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import {Animation, EventTracker, Palette} from '@devexpress/dx-react-chart'
import { useNavigate} from 'react-router-dom'
import {LegendItem, LegendLabel, LegendRoot} from './ChartLegend'

const PieChart = React.memo(
  ({
    classes,
    chartData,
    title,
    valueField,
    argumentField,
    useLegend,
    legendPosition = 'right',
    subtitle,
    mdWidth,
    className,
    isCountries,
  }) => {
    const navigate = useNavigate()
    chartData.sort((a, b) => (a[argumentField] < b[argumentField] ? -1 : 1))

    const goToKpis = () => {
      navigate('/monthly-members')
    }

    return (
      <Paper>
        <Typography
          align="left"
          variant="body2"
          className={classes.pieChartHeader}
        >
          {title}

          {!isCountries && (
            <span onClick={() => goToKpis()} className={classes.viewDashboard}>
              View Dashboard
            </span>
          )}
        </Typography>
        <Chart data={chartData} height={295}>
          <Palette
            scheme={['#FE7661', '#6FDB7F', '#F70073', '#C3A0D5', '#FCC879']}
          />
          <Typography
            align="left"
            variant="subtitle2"
            className={classes.pieChartSubtitle}
          >
            {subtitle}
          </Typography>
          <PieSeries
            valueField={valueField}
            argumentField={argumentField}
            innerRadius={0.6}
          />
          <EventTracker />
          <Tooltip
            contentComponent={({text, targetItem}) => (
              <>
                <strong>{chartData[targetItem.point][argumentField]}</strong>{' '}
                <br />
                {text}
              </>
            )}
          />
          {useLegend && (
            <div className={classes.legendContainer}>
              {legendPosition === 'top' || legendPosition === 'bottom' ? (
                <Legend
                  position={legendPosition}
                  labelComponent={LegendLabel}
                  rootComponent={LegendRoot}
                  itemComponent={LegendItem}
                />
              ) : (
                <Legend
                  position={legendPosition}
                  labelComponent={LegendLabel}
                  itemComponent={LegendItem}
                />
              )}
            </div>
          )}
          <Animation />
        </Chart>
      </Paper>
    )
  },
)

export default PieChart
