import React, {useEffect, useState} from 'react'

// Material-UI
import {Grid, Divider, Paper, Typography} from '@mui/material'

// Charts
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui'

import {Animation, LineSeries, Stack} from '@devexpress/dx-react-chart'

// Component Style
import classes from './Dashboard.module.css'

import StatsCardsGrid from '../../components/stats/StatsCardsGrid'
import PieChart from '../../components/stats/PieChart'
import LineChart from '../../components/stats/LineChart'
import {getGraphs, getStats} from '../../apis/api'
import OrganicUserChart from '../../components/Dashboard/OrganicUserChart'
import UserAccessStatusChart from '../../components/Dashboard/UserAccessStatusChart'
import AccessStatusMonthChart from '../../components/Dashboard/AccessStatusMonthChart'
import OrganicUserMonthChart from '../../components/Dashboard/OrganicUserMonthChart'

const Dashboard = () => {

  const [stats, setStats] = useState({})
  const [graphs, setGraphs] = useState({})
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const getDashboardStats = async () => {
    try {
      const statsRes = await getStats()
      setStats(statsRes.data)
      const graphsRes = await getGraphs()
      setGraphs(graphsRes.data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getDashboardStats()
  }, [])

  const membershipTypes = stats.membershipTypes
    ? stats.membershipTypes.map(item => {
        if (!item.membership) {
          item.membership = 'No membership'
        }
        return item
      })
    : []

  const ageGroups = graphs.ageGroups
    ? graphs.ageGroups.map(group => {
        if (group.age === '0 - 25') return {...group, age: 'Below 25'}
        if (group.age === '50 - ') return {...group, age: 'Above 50'}
        return group
      })
    : []

  const membershipGrowthByMonth = graphs?.membershipGrowthByMonth?.map(
    month => {
      let sum = 0
      for (let m in month) if (m !== 'month') sum += parseFloat(month[m])
      return {...month, month: months[month.month - 1], total: sum}
    },
  )

  const membershipWeekByWeek =
    graphs?.membershipWeekByWeek?.map(x => ({...x, week: String(x.week)})) || []

  return (
    <React.Fragment>
      <StatsCardsGrid stats={stats} />

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LineChart
            height={400}
            classes={classes}
            title="Member acquisition graph by week"
            chartData={membershipWeekByWeek}
          >
            <BarSeries
              name="New members"
              valueField="currentYear"
              argumentField="week"
              color="blue"
            />
            <BarSeries
              name="Churned"
              valueField="currentYearUnsubscribed"
              argumentField="week"
              color="red"
            />
            <LineSeries
              name="Last year"
              valueField="lastYear"
              argumentField="week"
              color="orange"
            />
            <LineSeries
              name="2 years ago"
              valueField="beforeLastYear"
              argumentField="week"
              color="gray"
            />
            <Stack stacks={[{series: ['New members', 'Churned']}]} />
          </LineChart>
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart
            classes={classes}
            chartData={membershipTypes}
            title="Membership Types"
            valueField="count"
            argumentField="membership"
            subtitle="Number of users by membership type"
            useLegend
            className={classes.stackedItemFirst}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LineChart
            classes={classes}
            title="Graph on net growth members for each membership"
            chartData={membershipGrowthByMonth || []}
            valueField="light"
            argumentField="month"
          >
            <LineSeries
              name="Total"
              valueField="total"
              argumentField="month"
              color="black"
            />
            <LineSeries
              name="Unlimited"
              valueField="unlimited"
              argumentField="month"
            />
            <LineSeries
              name="Unlimited-200"
              valueField="unlimited-200"
              argumentField="month"
            />
            <LineSeries
              name="Unlimited-365"
              valueField="unlimited-365"
              argumentField="month"
            />
            <LineSeries
              name="Paused"
              valueField="paused_membership"
              argumentField="month"
            />
          </LineChart>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography
              align="left"
              variant="body2"
              className={classes.graphHeader}
            >
              User Age Groups
            </Typography>
            <div className={classes.lineChartContainer}>
              <Chart data={ageGroups} height={295}>
                <ArgumentAxis />
                <ValueAxis />
                <BarSeries valueField="count" argumentField="age" />
                <Animation />
              </Chart>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart
            classes={classes}
            chartData={stats.genderTypes ? stats.genderTypes : []}
            title="Gender"
            valueField="count"
            argumentField="gender"
            subtitle="Number of users by gender"
            useLegend
            legendPosition="bottom"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <PieChart
            classes={classes}
            chartData={stats.countries ? stats.countries : []}
            title="Countries"
            valueField="count"
            argumentField="country"
            subtitle="Number of users by country"
            mdWidth={12}
            className={classes.stackedItemSecond}
            isCountries
            useLegend
            legendPosition="bottom"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OrganicUserChart />
        </Grid>
        <Grid item xs={12} md={12}>
          <OrganicUserMonthChart />
        </Grid>
        <Grid item xs={12} md={12}>
          <UserAccessStatusChart />
        </Grid>
        <Grid item xs={12} md={12}>
          <AccessStatusMonthChart />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Dashboard
