import { IPaginationMeta } from '../types/common'
import {IFreeRowInvoice, IInvoice, IInvoiceReport} from '../types/invoices'
import api from './api'

export const exportAllStudiosVisits = async (params: {
  year: number
  month: number
}): Promise<IInvoiceReport[]> => {
  const res = await api.get(`/studios/export-visits`, { params })
  return res.data
}

export const getExportInvoiceStudioApi = async ({
  studioId,
  page,
  month,
  year,
}: {
  studioId: string
  page: number
  month: number
  year: number
}): Promise<{
  invoice: IInvoice
  reports: IInvoiceReport[]
  meta: IPaginationMeta
}> => {
  const res = await api.get(`/invoices/${studioId}/month-invoice`, {
    params: { page, month, year },
  })
  return res.data
}

export const updateCheckInCompensationApi = async (
  invoiceId: string,
  compensation: number
): Promise<IInvoice> => {
  const res = await api.put(`/invoices/${invoiceId}/compensation`, {
    compensation,
  })
  return res.data
}

export const updateAllCompensationForStudioApi = async (
  compensation: number,
  ids: string[]
) => {
  const res = await api.put(`/invoices/report/allCompensation`, {
    compensation,
    ids,
  })
  return res.data
}

export const updateExcludeCheckInApi = async (
  reportId: string,
  exclude: boolean
): Promise<IInvoice> => {
  const res = await api.put(`/invoices/report/exclude`, { reportId, exclude })
  return res.data
}

export const manualSyncInvoiceApi = async (backMonth: number) => {
  const res = await api.post(`/invoices/report/sync`, {backMonth})
  return res.data
}

export const createCategoryApi = async (name: string, price: number) => {
  return await api.post('invoices/categories', { category: name, pricePerCategory: price })
}

export const getInvoiceByStudioId = async (studioId: string,
  month: number,
  year: number,) => {
  return await api.get(`invoices/studio/${studioId}?month=${month}&year=${year}`)
}

export const updateInvoiceByStudioId = async (studioId: string,
  month: number,
  year: number, invoice: any) => {
  return await api.put(`invoices/studio/${studioId}`, { ...invoice, month, year })
}

export const createFreeRowApi = async (
  studioId: string,
  month: number,
  year: number,
  freeRow: IFreeRowInvoice,
) => {
  return await api.post(`invoices/studio/${studioId}/freeRows`, { ...freeRow, month, year });
};

export const getFreeRowApi = async (studioId: string, month: number, year: number): Promise<{_id: string, freeRows: IFreeRowInvoice[]}> => {
  const res = await api.get(`invoices/studio/${studioId}/freeRows`, {
    params: {
      month,
      year,
    },
  });
  return res.data
};

export const delFreeRowApi = async (invoiceId: string, id: string) => {
  return await api.delete(`invoices/studio/${invoiceId}/freeRows/${id}`);
};

export const setOrganization = async (body: any) => {
  return await api.put(`organization`, body)
}

export const getOrganization = async () => {
  return await api.get(`organization`)
}

export const previewAllApi = (studioIds: string[], month: number, year: number) => {
  return api.post(`invoices/preview-all`, {studioIds, month, year})
}


export const getInvoicePdfZipApi = (publicIds: string[]) => {
  return api.post(`invoices/get-zip`, {publicIds})
}


interface IGetStudioWithZeroVisitsRes {
  page: number;
  limit: number;
  total: number;
  data: Array<{
    name: string;
    shortId: string;
    _id: string;
  }>;
}

export const getStudioWithZeroVisitsApi = async (
  month: number,
  year: number,
  page: number,
  limit: number,
): Promise<IGetStudioWithZeroVisitsRes> => {
  const res = await api.get(`invoices/zero-visits`, {
    params: {
      month,
      year,
      page,
      limit
    },
  });
  return res.data;
};
