import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, Space, Table, Button, InputNumber, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IFreeRowInvoice } from '../../types/invoices';
import {createFreeRowApi, delFreeRowApi, getFreeRowApi} from '../../apis/invoice';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@mui/icons-material';
import { roundToTwoDecimalPlaces, toFixedTwo } from '../../utils/common';

interface IProps {
  studioId: string;
  month: number;
  year: number;
}

const AddFreeRows: FC<IProps> = ({ studioId, month, year }) => {
  const [rows, setRows] = useState<IFreeRowInvoice[]>([]);
  const [invoiceId, setInvoiceId] = useState('');

  const getFreeRow = useCallback(async () => {
    try {
      const res = await getFreeRowApi(studioId, month, year);
      if(res?._id){
        setInvoiceId(res._id)
      }
      setRows(res?.freeRows || []);
    } catch (e) {
      toast.error(`Cannot get free rows`);
    }
  }, [month, studioId, year]);

  useEffect(() => {
    getFreeRow();
  }, [getFreeRow]);

  const onDelete = useCallback(async (id: string) => {
    try {
      await delFreeRowApi(invoiceId, id);
      toast.success('Delete free row successfully!');
      getFreeRow()
    }catch (e) {
      toast.error('Cannot free row!')
    }
  }, [getFreeRow, invoiceId]) ;
  const addedColumns: ColumnsType<IFreeRowInvoice> = useMemo(
    () => [
      { dataIndex: 'text' },
      { dataIndex: 'quantity' },
      { dataIndex: 'price', render: (value) => toFixedTwo(roundToTwoDecimalPlaces(value)) },
      {
        dataIndex: '_id',
        render: (value) => (
          <Popconfirm
            placement="topLeft"
            title={'Delete this free row ?'}
            okText="Yes"
            cancelText="No"
            zIndex={1333}
            onConfirm={() => onDelete(value)}
          >
            <Button danger type="text" icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
        ),
      },
    ],
    [onDelete],
  );

  const [form] = Form.useForm();


  const onFinish = async (data: IFreeRowInvoice) => {
    try {
      await createFreeRowApi(studioId, month, year, data);
      toast.success('Create free row successfully!');
      getFreeRow();
      form.resetFields();
    } catch (e) {
      console.error(e);
      toast.error(`Cannot create free rows`);
    }
  };
  return (
    <div style={{ marginBottom: 12 }}>
      Add free text rows on report:
      <Form layout="vertical" size="small" style={{ marginBottom: 8, width: '500px' }} onFinish={onFinish} form={form}>
        <Space>
          <Form.Item label="Text" name="text" rules={[{ required: true }]}>
            <Input placeholder="Free Text ..." />
          </Form.Item>
          <Form.Item label="Antal" name="quantity" rules={[{ required: true }]}>
            <InputNumber placeholder="Antal..." />
          </Form.Item>
          <Form.Item label="Pris" name="price" rules={[{ required: true }]}>
            <InputNumber placeholder="Price" addonAfter={'SEK'} />
          </Form.Item>
          <Form.Item label=" ">
            <Button type="primary" htmlType="submit" disabled={rows.length >= 10}>
              Add more &gt;&gt;
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {!!rows.length && (
        <Table
          showHeader={false}
          columns={addedColumns}
          dataSource={rows}
          rowKey={'_id'}
          style={{ width: '500px' }}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
};

export default AddFreeRows;
