import React, { FC, memo } from 'react'
import {
  Button,
  Grid,
  TextField,
} from '@mui/material'
import classes from './Style.module.css';

interface IProps{
  changeSearchQuery(text: string): void;
}

const SearchCustomerBy:FC<IProps> = memo(({changeSearchQuery}) => {
  const [searchText, changeSearchText] = React.useState('')

  const onChangeSearchText = (value: string) => {
    changeSearchText(value)
  }

  const handleSearchClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    changeSearchQuery(searchText)
  }

  return (
    <form onSubmit={handleSearchClick}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4} md={6} className={classes.textFieldContainer}>
          <TextField
            size="small"
            id="outlined-multiline-static"
            name="title"
            label="Search here..."
            variant="outlined"
            value={searchText}
            onChange={(e) => onChangeSearchText(e.target.value)}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={2} md={2} className={classes.btnContainer}>
          <Button type="submit" className={classes.btn} variant="contained"
            size='small'>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  )
})

export default SearchCustomerBy
