import {encrypt, decrypt} from './storage-crypt'

const storageKeys = Object.freeze({
  user: 'user',
})

export function setUser(user) {
  const encryptedUser = encrypt(JSON.stringify(user))
  localStorage.setItem(storageKeys.user, encryptedUser)
}

export function getUser() {
  try {
    const encryptedUser = localStorage.getItem(storageKeys.user)
    const decryptedUser = decrypt(encryptedUser)
    const user = JSON.parse(decryptedUser)
    return user
  } catch (error) {
    return {}
  }
}

export function userIsAuthenticated() {
  try {
    const user = getUser()
    return Object.keys(user).length > 0
  } catch (error) {
    return false
  }
}

export function getAccessToken() {
  try {
    const user = getUser()
    return user.accessToken
  } catch (error) {
    return undefined
  }
}

export function getRefreshToken() {
  try {
    const user = getUser()
    return user.refreshToken
  } catch (error) {
    return undefined
  }
}

export function setAccessToken(accessToken) {
  return new Promise((resolve, reject) => {
    const user = getUser()
    const newUser = {...user, accessToken}
    setUser(newUser)
    return resolve()
  })
}
