import React from 'react'
import {Menu, MenuItem} from '@mui/material'

const FilterBooleanMenu = ({anchorEl, handleClose, handleSelect, text}) => (
  <Menu
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={() => handleSelect('true')}>{text}</MenuItem>
    <MenuItem onClick={() => handleSelect('false')}>not {text}</MenuItem>
    <MenuItem onClick={() => handleSelect('')}>Reset</MenuItem>
  </Menu>
)

export default FilterBooleanMenu
