import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ListStudios from "../screens/ListStudios";
import Invoices from "../screens/Invoices";
import Dashboard from "../screens/Dashboard/Dashboard";
import Newfeed from "../screens/Newfeeds";
import PayingMembers from "../screens/PayingMembers";
import MonthlyMembers from "../screens/MonthlyMembers";
import CusomersKpis from "../screens/ListCustomers";
import ExternalStatus from "../screens/ExternalStatus";
import CampaignScreen from "../screens/Campaigns";
import Banners from "../screens/Banners";
import ScriptScreen from "../screens/scripts";
import UserMissSSN from "../screens/UserMissSSN";
import ZipCode from "../screens/ZipCode";

export default function RoutesRoot() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="studios" element={<ListStudios />} />
      <Route path="/invoicing" element={<Invoices />} />
      <Route path="/customers-kpis" element={<CusomersKpis />} />
      <Route path="/paying-members" element={<PayingMembers />} />
      <Route path="/monthly-members" element={<MonthlyMembers />} />
      <Route path="/new-feeds" element={<Newfeed />} />
      <Route path="/external/status" element={<ExternalStatus />} />
      <Route path="/campaigns" element={<CampaignScreen />} />
      <Route path="/banners" element={<Banners/>} />
      <Route path="/user-missing-ssn" element={<UserMissSSN/>} />
      <Route path="/scripts" element={<ScriptScreen />} />
      <Route path="/zipCodes" element={<ZipCode />} />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
}
