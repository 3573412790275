import { Button, Card, List, Popconfirm, message, Switch } from 'antd';
import React, { useState } from 'react';
import apiV2 from '../apis/api-v2';

const ScriptScreen = () => {
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const onChange = async (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setLoadingSwitch(true);

    try {
      if (checked) {
        await apiV2.post('/scripts/enable-show-in-app-message');
        message.success('Run script successfully !');
      } else {
        await apiV2.post('/scripts/disable-show-in-app-message');
        message.success('Run script successfully !');
      }
    } catch (error) {
      message.error('Run script error !');
    } finally {
      setLoadingSwitch(false);
    }
  };
  return (
    <Card size="small" title="List of scripts">
      <List size="small">
        <List.Item
          actions={[<RunScriptButton url="notification-to-wrong-social-security-number" />]}
        >
          <span>Send notification to users who have wrong social security number</span>
        </List.Item>
        <List.Item
          actions={[<Switch onChange={onChange}  loading={loadingSwitch} />]}
        >
          <span>Send notifications to users about Swiftr termination</span>
        </List.Item>
      </List>
    </Card>
  );
};

export default ScriptScreen;

const RunScriptButton = ({ url }: { url: string }) => {
  const [loading, setLoading] = useState(false);
  const onRun = async () => {
    setLoading(true);
    try {
      await apiV2.post('/scripts/' + url);
      message.success('Run script successfully !');
    } catch (error) {
      message.error('Run script error !');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Popconfirm
      placement="leftTop"
      title={'Are you sure you want to run this script?'}
      onConfirm={onRun}
      okText="Yes"
      cancelText="No"
    >
      <Button loading={loading}>Run</Button>
    </Popconfirm>
  );
};
