import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import MyContext from '../../context/context'
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { editCategory, getCategories } from '../../apis/api'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material'
import { toast } from 'react-toastify'
import classes from './Style.module.css';
// import { createCategoryApi } from '../../apis/invoice'
import { ICategory } from '../../services/invoice/type'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setCategories } from '../../services/invoice'

const headCells = [{ label: 'Category' }, { label: 'Price per category' }]

function EnhancedTableHead(props: any) {
  const { classes } = props

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell className="th" key={headCell.label} variant="body">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface IProps {
  open: boolean
  year?: number
  month?: number
  onClose: () => void
  onRefetchTable: () => void
}

const CompensationSummary: FC<IProps> = ({
  open,
  year,
  month,
  onClose,
  onRefetchTable,
}) => {

  const context = useContext(MyContext)
  const selected = context.selectedStudios
  const [rawCate, setRawCate] = useState<ICategory[]>([])
  const [rows, setRows] = useState<ICategory[]>([])
  const [isLoading, setLoading] = useState(false)
  // const [newCate, setNewCate] = useState({
  //   name: '',
  //   price: 0,
  // })

  const dispatch = useAppDispatch()
  const getData = useCallback(async () => {
    setLoading(true)
    const res = await getCategories({ year, month })
    const data = res?.data.data
    dispatch(setCategories(data))
    setRawCate(data)
    setRows(data)
    setLoading(false)
  }, [dispatch, month, year])

  useEffect(() => {
    if (open) {
      getData()
    }else{
      // setNewCate({ name: '', price: 0 })
    }
  }, [getData, open])

  const onChangePrice = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => {
    const newRows = rows.map(i => {
      if (i.categoryId === id) {
        return { ...i, pricePerCategory: Number(e.target.value) }
      }
      return i
    })

    setRows(newRows)
  }

  const onSaveEdit = useCallback(async () => {
    try {
      const cateEdited = rows.filter(
        i =>
          !rawCate.some(
            raw =>
              raw.categoryId === i.categoryId &&
              raw.pricePerCategory === i.pricePerCategory
          )
      )
      const promises = cateEdited.map(cate => {
        const body = {
          categoryId: cate.categoryId,
          category: cate.category,
          pricePerCategory: cate.pricePerCategory,
          year: year,
          month: month,
        }
        return editCategory(body, cate.categoryId || '')
      })
      await Promise.all(promises)
      toast.success('Update categories successfully!')
      if (promises.length) {
        getData()
        onRefetchTable()
      }
    } catch (error) {
      toast.error('Error when update categories! ')
    }
  }, [getData, month, onRefetchTable, rawCate, rows, year])

  // const onChangeForm = (key: 'name' | 'price') => (event: ChangeEvent<HTMLInputElement>) => {
  //   let value = event.target.value
  //   if (key === 'price') {
  //     const reg = new RegExp(/^-?\d*\.?\d*$/);
  //     if (!reg.test(value)) {
  //       return;
  //     }
  //   } else {
  //     if (value.length > 1) {
  //       return;
  //     }
  //   }
  //   setNewCate(pre => ({ ...pre, [key]: event.target.value }))
  // }

  // const onCreateCategory = async () => {
  //   try {
  //     await createCategoryApi(newCate.name, newCate.price);
  //     toast.info('Create category successfully!')
  //     getData();
  //     setNewCate({ name: '', price: 0 })
  //   } catch (error: any) {
  //     toast.error('Create failed: ' + JSON.stringify(error?.response.data))
  //   }
  // }
  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg'>
      <DialogTitle>Studio categories</DialogTitle>
      <DialogContent className={classes.root}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
          maxHeight: '60vh',
          overflowY: 'auto', 
        }}>
          {/* <Box sx={{ marginBottom: 4, marginTop: 2 }}>
            <TextField label='Category' size='small' value={newCate.name} onChange={onChangeForm('name')} placeholder='Category name' />
            <TextField label='Price' size='small' placeholder='Price' value={newCate.price} onChange={onChangeForm('price')} />
            <Button variant="outlined" disabled={!newCate.name || !newCate.price} onClick={onCreateCategory}>Create</Button>
          </Box> */}
          <Paper className={`${classes.paper}`}>
            Month: {month} Year: {year}
            {isLoading && (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            )}
            <TableContainer>
              <Table
                id="table"
                stickyHeader={true}
                className="categoriesTable"
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  rowCount={rows.length}
                />
                <TableBody>
                  {(rows || []).map(row => {
                    return (
                      <TableRow hover key={row.category}>
                        <TableCell className="td" align="left" variant="body">
                          {row?.category ? row.category.toUpperCase() : ''}
                        </TableCell>
                        <TableCell className="td" align="left" variant="body">
                          <TextField
                            value={row.pricePerCategory || ''}
                            size="small"
                            onChange={e => onChangePrice(e, row.categoryId)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Kr
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSaveEdit}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default CompensationSummary
