import React, { useContext } from 'react'
import { Button, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { login } from '../apis/api'
import MyContext from '../context/context'
import { useNavigate } from 'react-router-dom'
import useToast from '../hooks/useToast'
import { setUser } from '../utils/local-storage'
import { userIsAuthenticated } from '../utils/local-storage'

// Component Style
import classes from './Login.module.css'
import Input from '../components/Common/Input'

const Login = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const context = useContext(MyContext)
  const initialValues = {
    email: '',
    password: '',
  }

  const isAuthenticated = userIsAuthenticated()
  if (isAuthenticated) {
    navigate('/')
  }

  const { handleSubmit, getValues, control } = useForm({
    defaultValues: initialValues,
  })

  const [error, showError] = React.useState(false)
  const onSubmit = async () => {
    try {
      const res = await login(getValues())
      if (res.status === 200) {
        toast('success', `You've logged in successfully`)
        setUser(res?.data)
        context.auth(res?.data.user)
        navigate('/studios')
      }
    } catch (error) {
      console.log(error);
      showError(true)
    }
  }

  return (
    <React.Fragment>
      <div className="loginContent">
        <div className="loginForm">
          <div className={classes.loginHead}>
            <img
              width="120"
              src="https://storage.googleapis.com/cdn.swiftr.se/logo/logo-red-3.svg"
              alt="Swifter"
            />
            <h3 className={classes.textTitle}>Admin Portal</h3>
          </div>
          {error && (
            <div className={classes.errorStyle}>
              Something Wrong with email or password
            </div>
          )}
          <form className={classes.root} noValidate>
            <Grid container gap={4} sx={{ marginBottom: 2 }}>
              <Grid item xs={12}>
                <Input
                  control={control}
                  name='email'
                  label="Email"
                  type='email'
                />

              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  type="password"
                  name="password"
                  label="Password"
                />
              </Grid>
            </Grid>

            {/* <TextField
              // inputRef={register({required: true})}
              id="username"
              name="email"
              label="Email"
              variant="outlined"
              className={errors?.email?.type === 'required' ? 'error' : ''}
            /> */}
          </form>
          <Button
            onClick={handleSubmit(onSubmit)}
            className="button button--full"
          >
            Login
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
