import React from 'react'
import {Button, CircularProgress} from '@mui/material'

export default function LoadingButton({
  isLoading,
  loadingText,
  children,
  ...props
}) {
  return (
    <Button disabled={isLoading} size='small' {...props}>
      {isLoading ? (
        <>
          <CircularProgress
            size={18}
            color="inherit"
            style={{marginRight: '12px'}}
          />
          {loadingText || children}
        </>
      ) : (
        children
      )}
    </Button>
  )
}
