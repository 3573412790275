import React from 'react'
import {Legend} from '@devexpress/dx-react-chart-material-ui'
import {Typography} from '@mui/material'

const legendRootStyle = {
  display: 'flex',
  margin: 'auto',
}
const LegendRoot = props => <Legend.Root {...props} style={legendRootStyle} />

const legendItemStyle = {
  marginLeft: '-2px',
  marginRight: '-2px',
}
const LegendItem = props => <Legend.Item {...props} style={legendItemStyle} />

const legendLabelStyle = {
  whiteSpace: 'nowrap',
  marginTop: '5px',
  marginLeft: '5px',
}
const LegendLabel = ({text}) => (
  <Typography
    variant="caption"
    display="block"
    style={legendLabelStyle}
    gutterBottom
  >
    {text}
  </Typography>
)

export {LegendItem, LegendRoot, LegendLabel}
