import React from 'react'
import KpisMemberships from '../components/MonthlyMembers/KpisMemberships'
import KpisCities from '../components/MonthlyMembers/KpisCities'
import KpisGenders from '../components/MonthlyMembers/KpisGenders'
import {Grid} from '@mui/material'

const MonthlyMembers = () => {
  const reformatData = array => {
    const formated = {
      total: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      converted: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      closingBalancePercent: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      closingBalance: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      active: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      churned: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
      SOB_closingBalance: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
      },
    };

    (array || []).forEach(month => {
      Object.keys(month).forEach(item => {
        if (formated[item]) {
          let value = Number.isInteger(month[item])
            ? month[item]
            : month[item].toFixed(2)
          switch (month.month) {
            case 1:
              formated[item].jan = value
              break
            case 2:
              formated[item].feb = value
              break
            case 3:
              formated[item].mar = value
              break
            case 4:
              formated[item].apr = value
              break
            case 5:
              formated[item].may = value
              break
            case 6:
              formated[item].jun = value
              break
            case 7:
              formated[item].jul = value
              break
            case 8:
              formated[item].aug = value
              break
            case 9:
              formated[item].sep = value
              break
            case 10:
              formated[item].oct = value
              break
            case 11:
              formated[item].nov = value
              break
            case 12:
              formated[item].dec = value
              break
            default:
          }
        }
      })
    })
    return formated
  };

  const labelsAndKeys = [
    {name: 'OB Members', key: 'total'},
    {
      name: 'Member Conversion',
      key: 'converted',
      className: 'conversion',
    },
    {name: 'Member Churn', key: 'churned', className: 'churn3'},
    {name: 'CB Member', key: 'closingBalance'},
    {name: 'CB SOB', key: 'SOB_closingBalance'},
    {name: 'Active Members', key: 'active'},
    {name: 'CB Member %', key: 'closingBalancePercent'},
  ]

  return (
    <>
      <h2>Monthly Members</h2>
      <Grid
        container
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <KpisMemberships
            reformatData={reformatData}
            labelsAndKeys={labelsAndKeys}
          />
          <KpisGenders
            reformatData={reformatData}
            labelsAndKeys={labelsAndKeys}
          />
          <KpisCities
            reformatData={reformatData}
            labelsAndKeys={labelsAndKeys}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default MonthlyMembers
