import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export interface IProps {
  title: string;
  children: React.ReactNode;
  handleClose(): void;
  open: boolean;
  showFooter?: boolean;
  size: 'lg' | 'md' | 'sm';
  handleSave?: () => void;
  saveTitle?: string;
  buttonLoading?: boolean;
  disableSave?: boolean;
}
export default function CustomizedDialog({
  title,
  children,
  handleClose,
  open,
  showFooter,
  size = 'md',
  handleSave,
  saveTitle,
  buttonLoading,
  disableSave,
}: IProps) {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open || false}
      maxWidth={size}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {showFooter && (
        <DialogActions>
          <LoadingButton
            loading={buttonLoading}
            autoFocus
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={buttonLoading}
            autoFocus
            onClick={handleSave}
            disabled={disableSave}
            variant="contained"
          >
            {saveTitle || 'Save changes'}
          </LoadingButton>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
