import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import NewsFeedForm from '../components/Forms/NewsFeedForm';
import ListFeedsTable from '../components/ListFeeds/ListFeeds';

// Component Style
import classes from './Newfeeds.module.css';
import { Select, Row, Col, Radio } from 'antd';
import { deleteFeed, getAllFeeds } from '../apis/api';

function NewFeed() {
  const [newFeeds, setNewFeeds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = React.useState([]);
  const [forMode, setForMode] = useState(1);
  const clickMenu = (type) => {
    setOpen(type);
  };
  const [selected, setSelected] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const [paginationData, setPaginationData] = React.useState({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 20,
    nextPage: 0,
    page: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0,
  });
  const handleCreate = () => {
    setSelected({});
    clickMenu(true);
  };

  const onOpenUpdate = (newFeed) => {
    setSelected(newFeed);
    setOpen(true);
  };
  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getAllFeeds(paginationData.page, paginationData.limit, filter, (forMode !== 3 && {
        forall: forMode === 1,
        forone: forMode === 2,
      }));
      setNewFeeds(res.data.data);
      const temp = res?.data.meta;
      setPaginationData((pre) => ({ ...pre, ...temp }));
    } catch (error) {
      toast.error('something went wrong while fetching');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData.page, paginationData.limit, JSON.stringify(filter), forMode]);

  const onDelete = async (id) => {
    try {
      const res = await deleteFeed(id);
      if (res.status === 200) {
        toast.success('Item has been deleted successfully');
        getData();
      }
    } catch (error) {
      toast.error('something went wrong while deleting, please try again');
    }
  };
  const notificationTypes = useMemo(() => {
    return [
      {
        label: 'General',
        value: null,
      },
      {
        label: 'FriendRequest',
        value: 'FriendRequest',
      },
      {
        label: 'FriendRequestAcceptance',
        value: 'FriendRequestAcceptance',
      },
      {
        label: 'ClassInvitation',
        value: 'ClassInvitation',
      },
      {
        label: 'WaitingListApproval',
        value: 'WaitingListApproval',
      },
      {
        label: 'StudioInvitation',
        value: 'StudioInvitation',
      },
      {
        label: 'StudioRate',
        value: 'StudioRate',
      },
      {
        label: 'StudioMessage',
        value: 'StudioMessage',
      },
      {
        label: 'Review',
        value: 'Review',
      },
      {
        label: 'ClassCancellation',
        value: 'ClassCancellation',
      },
    ];
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFilter = (value) => {
    setFilter(value);
  };

  const onChangePagination = (page, pageSize) => {
    setPaginationData((pre) => ({ ...pre, page, limit: pageSize }));
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.container}>
            <h2>News Feed</h2>
          </div>
        </Grid>
        <Grid className={classes.menuContainer} item xs={12} sm={6}>
          <Button
            className={classes.menuBtn}
            onClick={() => handleCreate()}
            variant="contained"
            size="small"
          >
            Create News Feed
          </Button>
        </Grid>
      </Grid>
      <Row gutter={24}>
        <Col span={12}>
          <Select
            mode="multiple"
            placeholder="Notification type filter"
            style={{ width: '100%' }}
            allowClear
            options={notificationTypes}
            onChange={onFilter}
            value={filter}
          />
        </Col>
        <Col span={6}>
          <Radio.Group
            options={[
              { label: 'No filter', value: 3 },
              { label: 'Notification to all', value: 1 },
              { label: 'Notification to one', value: 2 }
            ]}
            optionType="button"
            buttonStyle="solid"
            value={forMode}
            onChange={(v) => setForMode(v.target.value)}
          />
        </Col>
      </Row>
      <div style={{ height: 50 }}>
      </div>
      <ListFeedsTable
        newFeeds={newFeeds}
        onOpenUpdate={onOpenUpdate}
        onDelete={onDelete}
        isLoading={isLoading}
        paginationData={paginationData}
        onChangePagination={onChangePagination}
      />
      <NewsFeedForm
        setOpen={clickMenu}
        open={open}
        getData={getData}
        paginationData={paginationData}
        selected={selected}
      />
    </React.Fragment>
  );
}

export default NewFeed;
