import React from "react";
import { Provider } from 'react-redux'
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./layouts/Dashborad.Layouts";
import GlobalState from "./context/GlobalState";
import LoginLayout from "./layouts/Login.Layouts";
import "./App.scss";
import "./theme.scss";
import "./stylesheets/index.scss";
import 'antd/dist/antd.min.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { store } from "./store";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // type: "light",
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prefersDarkMode]
  );

  return (
    <Provider store={store}>
      <GlobalState>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
              <Route path='/login/*' element={<LoginLayout />} />
              <Route path='/*' element={<Dashboard />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </GlobalState>
    </Provider>
  );
}

export default App;
