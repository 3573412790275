import { Collapse, Table, TablePaginationConfig } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { getStudioWithZeroVisitsApi } from '../../apis/invoice';
import { toast } from 'react-toastify';

const columns = [
  {
    title: 'Studio name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Studio id',
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: 'Studio short id',
    dataIndex: 'shortId',
    key: 'shortId',
  },
];
interface IProps {
  month: number;
  year: number;
}
const { Panel } = Collapse;

const StudiosNoVisits: FC<IProps> = ({ month, year }) => {
  const [studios, setStudios] = useState<
    Array<{
      name: string;
      shortId: string;
      _id: string;
    }>
  >([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });
  const getStudioWithZeroVisits = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getStudioWithZeroVisitsApi(
        month,
        year,
        pagination.page - 1,
        pagination.limit,
      );
      setStudios(data.data);
      setPagination((pre) => ({ ...pre, page: data.page + 1, total: data.total }));
    } catch (error) {
      toast('Cannot fetch studios with zero visits');
    } finally {
      setLoading(false);
    }
  }, [month, year, pagination.page, pagination.limit]);

  useEffect(() => {
    getStudioWithZeroVisits();
  }, [getStudioWithZeroVisits]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination((pre) => ({
      ...pre,
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
    }));
  };
  return (
    <>
      <Collapse>
      <Panel header={<h3>Studios with zero visits</h3>} key="1">
      <Table
        dataSource={studios}
        columns={columns}
        pagination={{
          total: pagination.total,
          current: pagination.page,
          pageSize: pagination.limit,
          showTotal: total => `Total ${total} studios`
        }}
        onChange={handleTableChange}
        rowKey={'_id'}
        loading={loading}
      />
      </Panel>
    </Collapse>
      
    </>
  );
};

export default StudiosNoVisits;
