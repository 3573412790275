import React, {memo, useCallback, useEffect, useState} from 'react'
import SearchCustomers from '../components/Kpis/SearchCustomers'
import {Grid} from '@mui/material'
import classes from './Cutomerkpis.module.css'
import CustomerListTable from '../components/Kpis/CustomerListTable'
import {
  exportCustomersKPIS,
  getKpisCustomers,
  searchCustomers,
} from '../apis/api'
import useToast from '../hooks/useToast'
import LoadingButton from '../components/LoadingButton'
import {ICustomerKpi} from '../types/customers'
import {IPaginationMeta} from '../types/common'
import {exportExcelFormat} from '../utils/exportExcelFormJson'
import {CUSTOMER_KPI_KEYS} from '../constants/common'
import {LONG_DAY_TIME} from '../constants/time-format'
import moment from 'moment'
import {
  MEMBERSHIP_STATUS_OBJ,
  PAYMENT_STATUS_OBJ,
  USER_STATUS_OBJ,
} from '../constants/access-status'
import { syncKpiCustomersApi } from '../apis/customer'

const ListCustomers = memo(() => {

  const notify = useToast()
  const [customers, setCustomers] = useState<ICustomerKpi[]>([])

  const [isLoading, setLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof ICustomerKpi>();
  const [syncLoading, setSyncLoading] = useState<boolean>(false)

  const toast = useToast()
  const [pagination, setPagination] = useState<IPaginationMeta>({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0,
  })

  const [currentPage, setCurrentPage] = useState(1)

  const getData = useCallback(
    async () => {
      const params = {
        page: currentPage,
        limit: 100,
        ...(!!orderBy && {
          sortBy: orderBy,
          isAsc: order === 'asc',
        }),
      }
      try {
        let res
        setLoading(true)
        if (searchQuery)
          res = await searchCustomers({...params, q: searchQuery})
        else res = await getKpisCustomers(params)

        setCustomers(res?.data.data)
        setPagination(res?.data.meta)
      } catch (error) {
        notify('warning', `Can not get list customer!`)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, searchQuery, orderBy, order]
  )

  useEffect(() => {
    getData()
  }, [getData])

  const exportCustomers = async () => {
    try {
      setIsExporting(true)
      const data = await exportCustomersKPIS()
      const excelData = (data.data || ([] as ICustomerKpi[])).map(
        (cus: ICustomerKpi) => ({
          ...cus,
          membershipPeriod: cus.periodStart
            ? `${cus.periodStart} to ${cus.periodEnd}`
            : '',
          paymentSourceCard: cus.paymentSourceCard
            ? `***${cus.paymentSourceCard}`
            : '',
          paymentSourceType: cus.paymentSourceType,
          activeUntil: cus.activeUntil || '',
          membershipStatus: cus.membershipStatus
            ? MEMBERSHIP_STATUS_OBJ[cus.membershipStatus]?.value
            : '',
          userStatus: cus.userStatus
            ? USER_STATUS_OBJ[cus.userStatus]?.value
            : '',
          paymentStatus: cus.paymentStatus
            ? PAYMENT_STATUS_OBJ[cus.paymentStatus]?.value
            : 'No payment source',
        })
      )
      exportExcelFormat(
        CUSTOMER_KPI_KEYS,
        excelData,
        `customer-kpis-${moment().format(LONG_DAY_TIME)}.xlsx`
      )
    } catch (error) {
      toast('error', 'Error exporting customers')
    } finally {
      setIsExporting(false)
    }
  }

  const changeSearchQuery = useCallback((value: string) => {
    setSearchQuery(value)
  }, [])
  const handleChangePage = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  const onChangeOrder = useCallback((order: any, orderBy: any) => {
    setOrder(order)
    setOrderBy(orderBy)
  }, [])

  const manualSyncUserStatus = useCallback(
    async () => {
      try {
        setSyncLoading(true)
        await syncKpiCustomersApi()
      } catch (error) {
        toast('error', 'Error syncing customers')
      } finally{
        setSyncLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={6}>
          <h2>Customer KPIs</h2>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-v-end">
          <LoadingButton
            onClick={exportCustomers}
            className={classes.btn}
            isLoading={isExporting}
            loadingText="Exporting..."
            variant="contained"
            size='small'
          >
            Export to excel
          </LoadingButton>
          <LoadingButton
            isLoading={syncLoading}
            loadingText="sync..."
            disable={syncLoading || false}
            onClick={manualSyncUserStatus}
            className={'sync_now_btn'}
            variant="contained"
            size='small'
          >
            Sync
          </LoadingButton>
          </div>
         
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          <SearchCustomers changeSearchQuery={changeSearchQuery} />
        </Grid>
      </Grid>
      <CustomerListTable
        paginationData={pagination}
        handleChangePage={handleChangePage}
        customers={customers}
        {...{order, orderBy, onChangeOrder}}
      />
    </>
  )
})

export default ListCustomers
