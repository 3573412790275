import React from "react";
import LoginRoutes from "../Routes/LoginRoutes";

export default function LoginLayout() {
  const bgImage = { backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/image.jpg")`, backgroundSize: 'cover', }
  return (
    <React.Fragment>
      <div className="loginWrapper" style={bgImage}>
        <LoginRoutes />
      </div>
    </React.Fragment>
  );
}
