import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import React, {FC, memo, useMemo} from 'react'
import {
  MEMBERSHIP_STATUS_OBJ,
  PAYMENT_STATUS_OBJ,
  USER_STATUS_OBJ,
} from '../../constants/access-status'
import {CUSTOMER_KPI_KEYS} from '../../constants/common'
import {IPaginationMeta} from '../../types/common'
import {ICustomerKpi} from '../../types/customers'
import classes from './Style.module.css';
interface IHeadCell {
  id: keyof ICustomerKpi
  numeric: boolean
  disablePadding: boolean
  label: string
  sort?: boolean
}
interface ITableHeadProp {
  classes: any
  order: 'desc' | 'asc'
  orderBy?: keyof ICustomerKpi
  onRequestSort: (value: keyof ICustomerKpi) => void
}

const EnhancedTableHead: FC<ITableHeadProp> = memo(props => {
  const {classes, order, orderBy, onRequestSort} = props
  const headCells = useMemo<IHeadCell[]>(() => {
    return CUSTOMER_KPI_KEYS.map(i => ({
      id: i.key as keyof ICustomerKpi,
      numeric: false,
      disablePadding: true,
      label: i.label,
    }))
  }, [])
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.tableCell}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant="head"
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => {
                  !!headCell.sort && onRequestSort(headCell.id)
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
})

interface IProps {
  customers: ICustomerKpi[]
  paginationData: IPaginationMeta
  handleChangePage(page: number): void
  orderBy?: keyof ICustomerKpi
  order: 'desc' | 'asc'
  onChangeOrder(order: 'desc' | 'asc', orderBy: keyof ICustomerKpi): void
}

const CustomerListTable: FC<IProps> = memo(
  ({
    handleChangePage,
    customers,
    paginationData,
    order,
    orderBy,
    onChangeOrder,
  }) => {

    const handleRequestSort = (property: keyof ICustomerKpi) => {
      if (property) {
        const isAsc = orderBy === property && order === 'asc'
        onChangeOrder(isAsc ? 'desc' : 'asc', property)
      }
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={paginationData.totalDocs}
            rowsPerPage={100}
            page={paginationData.page - 1}
            onPageChange={(_, page) => handleChangePage(page + 1)}
          />
          <TableContainer className={classes.tableContainer}>
            <Table
              stickyHeader={true}
              className="customersTable"
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {customers.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_WEB_ADMIN}/user/month-summary/${row._id}`,
                          '_blank'
                        )
                      }}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        className={classes.tableBodyCell}
                        id={labelId}
                        scope="row"
                        variant="body"
                      >
                        {row?.firstName + ' ' + row?.lastName}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.memberId}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.socialSecurityNumber
                          ? row.socialSecurityNumber
                          : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.churnDate || ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.plan || ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.userStatus
                          ? USER_STATUS_OBJ[row.userStatus]?.value
                          : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.membershipStatus
                          ? MEMBERSHIP_STATUS_OBJ[row.membershipStatus]?.value
                          : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.paymentStatus
                          ? PAYMENT_STATUS_OBJ[row.paymentStatus]?.value
                          : 'No payment source'}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.periodStart
                          ? `${row.periodStart} to ${row.periodEnd}`
                          : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.paymentSourceCard
                          ? `***${row.paymentSourceCard}`
                          : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.paymentSourceType || ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.activeUntil ? row.activeUntil : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.currency ? row.currency : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.country ? row.country : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.city ? row.city : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.gender ? row.gender : ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="right"
                        padding="none"
                        variant="body"
                      >
                        {row.birthdate ? row.birthdate : ''}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    )
  }
)

export default CustomerListTable
