import { Button, Popconfirm, Table, message, Space } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import type { ColumnsType } from 'antd/es/table';
import { IUser } from '../types/user';
import { approvedUserMissingSSNApi, getUserMissingSSNApi, deniedUserMissingSSNApi } from '../apis/user.api';

const UserMissSSN = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserMissSSN = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getUserMissingSSNApi();
      setUsers(data);
    } catch (error) {
      toast.error('Fetch users is failed');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserMissSSN();
  }, [getUserMissSSN]);

  const approveReason = useCallback(
    async (id:string) => {
      try {
        await approvedUserMissingSSNApi(id)
        message.success(`Approved this user!`)
        await getUserMissSSN()
      } catch (error) {
        message.error(`Cannot approve`)
      }
    },
    [getUserMissSSN],
  )
  const denyReason = useCallback(
  async (id:string) => {
    try {
      await deniedUserMissingSSNApi(id);
      message.success(`Denied this user's reason.`);
      await getUserMissSSN();
    } catch (error) {
      message.error(`Cannot deny`);
    }
  },
  [getUserMissSSN],
);


  const columns: ColumnsType<IUser> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (value, record) => value + ' ' + record.lastName,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (value) => value,
      },
      {
        title: 'Member ID',
        dataIndex: 'memberId',
        key: 'memberId',
        render: (value) => value,
      },
      {
        title: 'Reason of Missing SSN',
        dataIndex: 'reasonOfMissingSSN',
        key: 'reasonOfMissingSSN',
        render: (value) => value,
      },
      {
        title: 'Actions',
        key: 'memberId',
        render: (_, record) => (
          <Space>
            <Popconfirm title="Approved reason of missing SSN" onConfirm={() => approveReason(record._id)}>
              <Button>Approved</Button>
            </Popconfirm>
            <Popconfirm title="Deny reason of missing SSN" onConfirm={() => denyReason(record._id)}>
              <Button>{record.deniedReasonOfMissingSSN ? "Denied" : "Deny"}</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [approveReason, denyReason],
  );

  return (
    <div>
      <h2>User Missing Social Security Number</h2>
      <Table
        dataSource={users}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
        pagination={false}
      />
    </div>
  );
};

export default UserMissSSN;
